<template>
  <!-- 折线图 -->
  <div :id="id" class="chartsBox"></div>
</template>
<script>
import * as echarts from "echarts";
import { mapGetters } from 'vuex'
export default {
  props: {
    id: {
      type: String,
      default: "chartLine",
    },
    option: {
      type: Object,
      default: () => { },
      // {
      //  id:"",
      //  title: '测试1',
      //  x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun5"],
      //  unit: '',
      //  yBorderColor:'rgba(34, 51, 83, 0.4)',//不是白色的地图建议设置一下
      //  series: [
      //    {
      //      name: '线1',
      //      data:[150, 230, 224, 218, 135, 147, 222],
      //    },
      //    {
      //      name: '线2',
      //      data: [120, 130, 114, 118,35, 47, 111],
      //    }
      //  ],
      // cover:{
      //grid  yAxis tooltip  
      // },
      //  setSeries:{
      //      smooth: true, //平滑折线图
      //      areaStyle: {}//下阴影
      //  }
      //};
    },
  },
  data() {
    return {
      chart: null,
      chartDom: null,
      defaultData: {
        title: {
          text: "图表",
        },
        color: ["#07AEFBFF", "#2D8BFF", "#3EB7FD"],
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisLabel: {
            textStyle: {
              color: '#7587A0',
              fontSize: 12
            }
          },

        },
        tooltip: {
          trigger: "axis",
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#07AEFBFF", // 0% 处的颜色
              },
              {
                offset: 0.5,
                color: "#fff", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        grid: {
          top: "20%", // 等价于 y: '16%'
          left: "3%",
          right: "7%",
          bottom: "3%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          scale: true,//脱离0值比例
          name: "",
          splitLine: {
            lineStyle: {
              color: 'rgba(34, 51, 83, 0.4)'//rgba(3, 16, 33, 0.9) rgba(34, 51, 83, 0.8)
            }
          },
          nameTextStyle: {
            padding: [0, 0, 0, 18],
          },
          color: "#fff",
          axisLabel: {
            textStyle: {
              color: '#7587A0',
              fontSize: 12
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              width: 0
            }
          },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        series: [],
      },
      timer: null,
      currentIndex: -1,
      isAnimation: false,//是否开启自动显示
    };
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
    option: {
        handler() {
            this.initOption();
        },
        deep: true
    },
  },
  mounted: function () {
    this.initOption();
  },
  methods: {
    handle() {
      this.defaultData.series = [];
      this.defaultData.title.text = this.option.title;
      if (this.option.color != undefined && this.option.color.length > 0) {
        this.defaultData.color = this.option.color;
      }
      if (this.option.legend != undefined && this.option.legend.data.length > 0) {
        this.defaultData.legend = this.option.legend;
      }
      if (this.option.cover != undefined) {
        this.defaultData = Object.assign(this.defaultData, this.option.cover);
      }

      if (this.option.isAnimation != undefined) {
        this.isAnimation = this.option.isAnimation;
      }

      this.defaultData.xAxis.axisLabel.textStyle.color = this.option.xColor != undefined ? this.option.xColor : "#7587A0";
      this.defaultData.yAxis.axisLabel.textStyle.color = this.option.yColor != undefined ? this.option.yColor : "#7587A0";

      this.defaultData.yAxis.splitLine.lineStyle.color = this.option.yBorderColor != undefined ? this.option.yBorderColor : 'rgba(34, 51, 83, 0.1)';

      this.defaultData.yAxis.name = this.option.unit == "" ? "" : this.showLang('com.op.unit') + ":" + this.option.unit;
      this.defaultData.yAxis.offset = 0;
      this.defaultData.xAxis.data = this.option.x;
      let getSeries = {
        type: "line",
        smooth: true, //平滑折线图
      };
      getSeries = Object.assign(getSeries, this.option.setSeries);
      this.option.series.forEach((el) => {
        el = Object.assign(el, getSeries);
        this.defaultData.series.push(el);
      });
      if (this.defaultData.xAxis.data.length == 0) {
        this.defaultData.yAxis.min = 0;
        this.defaultData.yAxis.max = 100;
      }
      return this.defaultData;
    },
    initOption() {
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        this.chartDom = document.getElementById(this.id);
        if( this.chartDom==null) return ;
        this.chart = echarts.init(this.chartDom);
        if (this.option) {
          this.chart.setOption(this.handle());
        }
        if (this.isAnimation) {
           this.chart.getZr().on('mousemove', ()=>{
            this.closeTooltip()
          })
          this.chart.getZr().on('mouseout', ()=>{
            this.startTooltipLoop()
          })
          this.startTooltipLoop();
        }
      });
    },
    switchTooltip() {
      this.chart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
      this.currentIndex = (this.currentIndex + 1) % this.defaultData.series[0].data.length;
      this.chart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
      this.chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
    },
    startTooltipLoop() {
      this.timer = setInterval(() => {
        this.switchTooltip();
      }, 2500);
    },
    closeTooltip() {
      clearInterval(this.timer);
      this.timer = undefined;
    },
  },
  beforeDestroy() {
    this.closeTooltip();
  },

};
</script>
<style scoped>
.chartsBox {
  width: 100%;
  height: 100%;
}
</style>

