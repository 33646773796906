<template>
  <Modal v-model="showModal" :mask-closable="false" :width="800">
    <div slot="header">{{ item.name }}</div>
    <div class="map-con-info">
      <div class="map-con-info-box">
        <p>{{ showLang('com.tab.title') }}：{{ item.name }}</p>
        <p>{{ showLang('com.import.cn.lng') }}：{{ item.lng }}</p>
      </div>
      <div class="map-con-info-box">
        <p>{{ showLang('gis.road') }}：{{ poleInfo.pathName }}</p>
        <p>{{ showLang('com.import.cn.lat') }}：{{ item.lat }}</p>
      </div>
    </div>
    <div class="gisButtom">
      <div>
        <template v-for="item in items">
          <a href="javascript:void(0);" v-if="funCodes(item.opcode)" class="control-item" :key="item.code" @click="doLightCmd(item)">
            <img :src="item.img" />
            {{ showLang(item.lang) }}
          </a>
        </template>
      </div>
      <!-- <butListPage :item="item" :refresh="refresh" :selected="selected" @savedShow="savedShow"></butListPage> -->
    </div>
    <!-- <div class="map-tool-container1">
      <div class="item-menu">灯控列表：</div>
      <template v-if="poleInfo.lights && poleInfo.lights.length > 0">
        <div v-for="(item, idx) in poleInfo.lights" :key="idx" :class="['item', item.id == selected.id ? 'active' : '']"
          @click="selectIt(item)">
          {{item.name}}
        </div>
      </template>
    </div> -->
    <div class="item-control-list" v-if="poleInfo.lights && poleInfo.lights.length > 0">
      <template v-for="(item, idx) in poleInfo.lights">
        <div :key="idx" v-if="idx < 4" :class="['control-list-box', item.id == selected.id ? 'control-list-active' : '']" @click="selectIt(item, 0)" :title="item.name">
          {{ item.name }}
        </div>
      </template>
      <template>
        <div v-if="poleInfo.lights.length > 4">
          <Dropdown placement="bottom-end">
            <a href="javascript:void(0)" class="control-list-box" :class="[listActive == 1 ? 'control-list-active' : '']">
              {{showLang('com.op.other')}}
              <Icon type="arrow-down-b"></Icon>
            </a>
            <Dropdown-menu slot="list">
              <template v-for="(item, idx) in poleInfo.lights">
                <Dropdown-item v-if="idx > 3" :key="idx + 3" @click.native="selectIt(item, 1)">{{ item.name }}</Dropdown-item>
              </template>
            </Dropdown-menu>
          </Dropdown>
        </div>
      </template>
    </div>
    <div :style="{ height: `400px`, overflow: 'auto' }">
      <div v-if="selected && selected.lastData">
        <table class="light-table">
          <tr>
            <td class="bold">{{ showLang('com.tab.parameters') }}</td>
            <td class="bold" v-if="selected.content && selected.content.en1">{{ showLang('com.ope.way.channel.1') }}</td>
            <td class="bold" v-if="selected.content && selected.content.en2">{{ showLang('com.ope.way.channel.2') }}</td>
            <td class="bold" v-if="selected.content && selected.content.en3">{{ showLang('com.ope.way.channel.3') }}</td>
            <td class="bold" v-if="selected.content && selected.content.en4">{{ showLang('com.ope.way.channel.4') }}</td>
          </tr>
          <tr>
            <td>{{ showLang('com.lighting.V') }}</td>
            <td :colspan="enNum" class="blue">{{ img.val(selected.lastData.u, 2) }} V</td>
          </tr>
          <tr>
            <td>{{ showLang('com.lighting.I') }}</td>
            <td class="blue" v-if="selected.content && selected.content.en1">{{ img.val(selected.lastData.c1, 2) }} A</td>
            <td class="blue" v-if="selected.content && selected.content.en2">{{ img.val(selected.lastData.c2, 2) }} A</td>
            <td class="blue" v-if="selected.content && selected.content.en3">{{ img.val(selected.lastData.c3, 2) }} A</td>
            <td class="blue" v-if="selected.content && selected.content.en4">{{ img.val(selected.lastData.c4, 2) }} A</td>
          </tr>
          <tr>
            <td>{{ showLang('com.data.active.power') }}</td>
            <td class="blue" v-if="selected.content && selected.content.en1">{{ img.val(selected.lastData.p1, 2) }} W</td>
            <td class="blue" v-if="selected.content && selected.content.en2">{{ img.val(selected.lastData.p2, 2) }} W</td>
            <td class="blue" v-if="selected.content && selected.content.en3">{{ img.val(selected.lastData.p3, 2) }} W</td>
            <td class="blue" v-if="selected.content && selected.content.en4">{{ img.val(selected.lastData.p4, 2) }} W</td>
          </tr>
          <tr>
            <td>{{ showLang('com.lighting.op') }}</td>
            <td class="blue" v-if="selected.content && selected.content.en1">{{ img.val(selected.lastData.op1, 2) }} %</td>
            <td class="blue" v-if="selected.content && selected.content.en2">{{ img.val(selected.lastData.op2, 2) }} %</td>
            <td class="blue" v-if="selected.content && selected.content.en3">{{ img.val(selected.lastData.op3, 2) }} %</td>
            <td class="blue" v-if="selected.content && selected.content.en4">{{ img.val(selected.lastData.op4, 2) }} %</td>
          </tr>
          <tr>
            <td>{{ showLang('com.lighting.oc') }}</td>
            <td class="blue" v-if="selected.content && selected.content.en1">{{ img.val(selected.lastData.oc1, 2) }}</td>
            <td class="blue" v-if="selected.content && selected.content.en2">{{ img.val(selected.lastData.oc2, 2) }}</td>
            <td class="blue" v-if="selected.content && selected.content.en3">{{ img.val(selected.lastData.oc3, 2) }}</td>
            <td class="blue" v-if="selected.content && selected.content.en4">{{ img.val(selected.lastData.oc4, 2) }}</td>
          </tr>
          <tr>
            <td>{{ showLang('com.lighting.cl') }}</td>
            <td class="blue" v-if="selected.content && selected.content.en1">{{ img.val(selected.lastData.cl1, 2) }} mA</td>
            <td class="blue" v-if="selected.content && selected.content.en2">{{ img.val(selected.lastData.cl2, 2) }} mA</td>
            <td class="blue" v-if="selected.content && selected.content.en3">{{ img.val(selected.lastData.cl3, 2) }} mA</td>
            <td class="blue" v-if="selected.content && selected.content.en4">{{ img.val(selected.lastData.cl4, 2) }} mA</td>
          </tr>
          <tr>
            <td>{{ showLang('com.lighting.T') }}</td>
            <td :colspan="enNum" class="blue">{{ img.val(selected.lastData.tm, 2) }} ℃</td>
          </tr>
          <tr>
            <td>{{ showLang('com.date.measure') }}</td>
            <td :colspan="enNum" class="blue">{{ isDateForm() }}</td>
          </tr>
        </table>
      </div>
    </div>
    <ModalControlOutput v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
    <!-- <ModalArgEdit v-model="showArgModal" :command="cmdItem" :areas="areas" @saved="argsSaved" /> -->
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{ showLang('com.op.close') }}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
// import ModalControlOutput from '@/views/light/road/monitor/station/base/control/ModalControlOutput'
//D:\project\company\in-operation-platform-2024\amdm-yhg\com.amdm.web.south\src\components\control\light\args\ModalArgEdit.vue
import ModalControlOutput from '@/components/control/light/args/ModalArgEdit'
// import butListPage from './butList'
export default {
  name: 'ModalMakerInfo',
  components: {
    // butListPage,
    ModalControlOutput
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      productId: '',
      poleInfo: {},
      selected: {},
      showInfoType: '',
      refresh: 0,
      listActive: 0,
      showArgModal: false,
      cmdItem: {},
      cmdIds: [],
      enNum: 0,
      en2Show: false,
      en1Show: false,
      powerTableActions: {
        0: '关闭',
        1: '开启',
        2: '无效'
      },
      cmdResults: {},
      items: [
        { needArgs: false, pswd: false, code: 'forceRead', opcode: 'dcs', lang: 'com.but.survey', name: '召测数据', img: this.img.light.map.button.survey },
        { needArgs: true, pswd: true, code: 'handSingle', opcode: 'dco', lang: 'com.but.dimming', name: '调光控制', img: this.img.light.map.button.control },
        { needArgs: true, pswd: true, code: 'openSingle', opcode: 'dco', lang: 'com.but.open.lamp', name: '开灯', img: this.img.light.map.button.onLight },
        { needArgs: true, pswd: true, code: 'closeSingle', opcode: 'dco', lang: 'com.but.close.lamp', name: '关灯', img: this.img.light.map.button.offLight },
        { needArgs: false, pswd: false, code: 'getclock', opcode: 'dcs', lang: 'com.but.get.QueryClock', name: '查询时钟', img: this.img.light.map.button.time },
        { needArgs: false, pswd: true, code: 'setclock', opcode: 'dcc', lang: 'com.but.get.Calibrate', name: '校准时钟', img: this.img.light.map.button.correct }
      ]
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal
      if (newVal) {
        this.getItemInfo()
      }
    },
    showModal(val) {
      this.$emit('showChanged', val)
    },
    cmdResultRefresh() {
      // this.cmdResultChanged()
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['commands', 'cmdResultRefresh']),
    ...mapState('common', ['weeks', 'productCodes', 'deviceTypes', 'monitorActionTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['showSpin', 'stationTabName']),
    currentStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode
      }
      return null
    }
  },
  mounted: function () {
    this.powerTableActions = {
      0: this.showLang('com.op.close'), // '关闭',
      1: this.showLang('com.state.open'), // '开启',
      2: this.showLang('com.state.invalid') // '无效'
    }
    window.eventBus.$on('paramCommandComing', params => {
      if (this.cmdResults.cmdId != params.commandId) return;
      this.cmdResults.status = params.status;
      this.cmdResults.message = params.message;
      this.cmdResults.content = params.content;
      let contents = [];
      contents.push(this.getCmdStatus(this.cmdResults));
      // if (this.stationTabName == 'light') {
      //   contents.push(this.getCmdStatus(this.cmdResults));
      // } else {
      //   this.$refs.lightList1.setCmdContent(this.cmdResults.cmdId, this.cmdResults, this.getCmdStatus(this.cmdResults));
      // }
      if (this.stationTabName == 'power') {
        let content = contents.join(' ');
        this.$store.commit('auth/hideSpin');
        setTimeout(() => {
          this.$Modal.success({
            title: this.showLang('com.ins.result'),
            content: content
          });
        }, 100);
      }
      if (params && params.status == 9) {
        this.getItemInfo()
      }
    })
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
  },
  methods: {
    selectIt: function (item, index) {
      this.listActive = index
      this.$set(this, 'selected', item)
    },
    doLightCmd: function (cmd) {
      let list = []
      list.push(this.selected)
      if (list.length == 0) {
        this.$Modal.error({
          title: this.showLang('com.ins.arrange.wrong'),
          content: this.showLang('com.ins.select.lamp.controller')
        })
        return
      }
      if (cmd.needArgs || cmd.pswd) {
        cmd.type = 'light'
        cmd.deviceId = list[0].deviceId;
        this.cmdItem = cmd;
        let enArr = [];
        if (cmd.code == 'handSingle' || cmd.code == 'closeSingle' || cmd.code == 'openSingle') {
          list.forEach(el => {
            if (el.content.en1 && !enArr.includes('1')) {
              enArr.push('1')
            }
            if (el.content.en2 && !enArr.includes('2')) {
              enArr.push('2')
            }
            if (el.content.en3 && !enArr.includes('3')) {
              enArr.push('3')
            }
            if (el.content.en4 && !enArr.includes('4')) {
              enArr.push('4')
            }
          });
          this.cmdItem.enArr = enArr;
        }
        this.showArgModal = true
        this.$emit('savedShow', { cmd: cmd, type: false })
      } else {
        let cmdIds = []
        cmdIds.push(list[0].id)
        // cmds.push({ id: list[0].id, code: cmd.code, name: cmd.name, deviceId: list[0].deviceId, args: {}, source: 'gis' })
        this.$set(this, 'cmdIds', cmdIds)
        this.$emit('savedShow', { cmd: cmdIds, type: true })
        let cmds = {
          "code": cmd.code,
          "list": [list[0].id],
          "checkUserId": 0,
          "args": {}
        }
        this.sendLampOld(cmds)
      }
    },
    isDateForm() {
      if (this.selected.lastData && this.selected.lastData.time) {
        return new Date(this.selected.lastData.time).format('yyyy-MM-dd HH:mm:ss')
      } else {
        return '--'
      }
    },
    savedShow(cmd) {
      if (cmd.type) {
        this.$set(this, 'cmdIds', cmd.cmd)
      } else {
        this.$set(this, 'cmdItem', cmd.cmd)
        this.showArgModal = true
      }
    },
    getCmdStatus: function (cmd) {
      let p = '<p>',
        p1 = '</p>'
      if (this.stationTabName == 'light') {
        p = ''
        p1 = ' '
      }
      let content = p + this.showLang('com.ins.create') + p1
      ////status: 0、等待发送，1、等待回复，2、指令超时，3、执行失败，4、执行中，5、失败重发，9、执行成功
      if (cmd.status == 1) {
        if (cmd.args.channel) {
          content = p + this.showLang('com.ins.ch.reply', cmd.args.channel) + p1
        } else {
          content = p + this.showLang('com.ins.dev.reply') + p1
        }
      } else if (cmd.status == 3) {
        if (cmd.args.channel) {
          content = p + this.showLang('com.ins.ch') + 'K' + cmd.args.channel + this.showLang('com.ins.fail') + ':' + cmd.result + p1
        } else {
          content = `${p}${this.showLang('com.ins.fail')}：${cmd.result}${p1}`
        }
      } else if (cmd.status == 4) {
        if (cmd.args.channel) {
          content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.execution')}：${cmd.content.pkg} / ${cmd.content.total}${p1}`
        } else {
          content = `${p}${this.showLang('com.ins.execution')}：${cmd.content.pkg} / ${cmd.content.total}${p1}`
        }
      } else if (cmd.status == 5) {
        if (cmd.args.channel) {
          content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.fail.retry')}：${cmd.result}${p1}`
        } else {
          content = `${p}${this.showLang('com.ins.fail.retry')}：${cmd.result}${p1}`
        }
      } else if (cmd.status == 9) {
        if (cmd.code == 'getTimeTable') {
          let day = 'day' + cmd.args.day
          let con = cmd.content[day]
          //{0: '关闭', 1: '开启', 2: '无效'},
          content = `${p} ${this.showLang('com.ins.timeTable')}：${cmd.args.month}${this.showLang('com.date.month')}${cmd.args.day}${this.showLang('com.date.sun')}<br> ${con.t1} - ${this.powerTableActions[con.a1]}<br>${con.t2} - ${this.powerTableActions[con.a2]}<br> ${con.t3} - ${this.powerTableActions[con.a3]
            }<br> ${con.t4} - ${this.powerTableActions[con.a4]}${p1}`
        } else if (cmd.code == 'getclock') {
          content = `${p}${this.showLang('com.ins.dev.currentTime')}: ${cmd.content.nowTime}${p1}`
        } else if (cmd.code == 'setclock') {
          let myDate = new Date()
          content = `${p}${this.showLang('com.but.get.Calibrate')}: ${myDate.format('yyyy-MM-dd HH:mm:ss')}${p1}`
        } else if (cmd.code == 'getYearPlan') {
          // console.log('get year plan', cmd)
          if (cmd.args.month != cmd.content.month) {
            content = `${p}${this.showLang('com.ins.success.WrongMonth', cmd.args.month)}:${cmd.content.month}${p1}`
          } else {
            let strs = []
            let obj = cmd.content[`day${cmd.args.day}`]
            if (cmd.content.mode == 4) {
              //北京模式
              for (let i = 1; i <= 4; i++) {
                let ao = this.powerTableActions[obj[`a${i}`]]
                let to = obj[`o${i}`]
                // let ac = this.powerTableActions[obj[`ac${i}`]];
                let tc = obj[`c${i}`]
                strs.push(`${p}${i}、${to}-${tc}:${ao}${p1}`)
              }
            } else if (cmd.content.mode == 5) {
              //集中器模式
              for (let i = 1; i <= 6; i++) {
                let a = obj[`a${i}`] //this.monitorActionTypes[];
                let t = obj[`t${i}`]
                let v = this.monitorActionTypes[a] //a == 1 ? '' : obj[`v${i}`];
                if (a != 1) {
                  v += ` - ${obj[`v${i}`]}`
                }
                strs.push(`${p}${i}、${t} - ${v}${p1}`)
              }
            } else {
              for (let i = 1; i <= 4; i++) {
                let a = this.powerTableActions[obj[`a${i}`]]
                let t = obj[`t${i}`]
                strs.push(`${p}${i}、${t} - ${a}${p1}`)
              }
            }
            content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.success')}：${cmd.args.month}${this.showLang('com.date.month')}${cmd.args.day}${this.showLang('com.date.sun')}${this.showLang('com.date.plan')}：${strs.join(' ')}${p1}`
          }
        } else if (cmd.code == 'getInfo') {
          content = `${p}${this.showLang('com.site.hw')}：${cmd.content.hardware}${p1}${p}${this.showLang('com.site.sw')}：${cmd.content.software}${p1}${p}${this.showLang('com.user.manufacturer')}：${cmd.content.manufacture}${p1}`
        } else if (cmd.code == 'getSimInfo') {
          content = `${p}ICCID: ${cmd.content.iccid}${p1}${p}IMEI: ${cmd.content.imei}${p1}${p}RSSI: ${cmd.content.rssi}${p1}`
        } else if (cmd.code == 'getNetwork') {
          content = `${p}IP:${cmd.content.host}${p1}${p}${this.showLang('com.ins.port')}:${cmd.content.port}${p1}${p}${this.showLang('com.ins.heartbeat')}:${cmd.content.heart}s${p1}`
        } else if (cmd.code == 'getDayPlan1') {
          content = ''
          for (let n = 1; n <= 6; n++) {
            content += `${p}${this.showLang('com.date.period')}${n}：${cmd.content[`enable1${n}`] ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；${p1}${p}${this.showLang('com.date.time')}：${cmd.content[`expire1${n}`]}min,1${this.showLang('com.ins.ch.light')}：${cmd.content[`bright1${n}`]
              }，1${this.showLang('com.ins.ch.color')}：${cmd.content[`color1${n}`]},2${this.showLang('com.ins.ch.light')}：${cmd.content[`bright2${n}`]}，2${this.showLang('com.ins.ch.color')}：${cmd.content[`color2${n}`]};${p1}`
          }
        } else if (cmd.code == 'getDayPlan2') {
          content = ''
          for (let n = 1; n <= 6; n++) {
            content += `${p}${this.showLang('com.date.period')}${n}：${cmd.content[`enable1${n}`] ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；${p1}${p}${this.showLang('com.date.time')}：${cmd.content[`start1${n}`]},1${this.showLang('com.ins.ch.light')}：${cmd.content[`bright1${n}`]
              }，1${this.showLang('com.ins.ch.color')}：${cmd.content[`color1${n}`]},2${this.showLang('com.ins.ch.light')}：${cmd.content[`bright2${n}`]}，2${this.showLang('com.ins.ch.color')}：${cmd.content[`color2${n}`]};${p1}`
          }
        } else if (cmd.code == 'getGroup') {
          content = `${p}${this.showLang('com.group.number')}: ${cmd.content.no}${p1}`
        } else if (cmd.code == 'getPlanType') {
          content = `${p}${this.showLang('com.but.tableType')}: ${cmd.content.type == 0 ? this.showLang('com.but.timing') : this.showLang('com.but.ontime')}`
        } else if (cmd.code == 'getAlarmRange') {
          content = `${this.showLang('com.lighting.V.min')}：${cmd.content.vLow}；
                ${this.showLang('com.lighting.V.max')}：${cmd.content.vHigh}；
                ${this.showLang('com.lighting.I.min')}：${cmd.content.cLow}；
                ${this.showLang('com.lighting.I.max')}：${cmd.content.cHigh}；
                ${this.showLang('com.lighting.T.min')}：${cmd.content.tLow}；
                ${this.showLang('com.lighting.T.max')}：${cmd.content.tHigh}；
                ${this.showLang('com.ins.escalation.heartbeat')}：${cmd.content.interval}分；`
        } else if (cmd.code == 'getUnionParam') {
          content = `${this.showLang('com.lighting.linkage.number')}：${cmd.content.rfsn}；
                433${this.showLang('com.lighting.channel')}：${cmd.content.channel}；
                433${this.showLang('com.lighting.send.power')}：${cmd.content.txpower}；
                ${this.showLang('com.lighting.is.signal')}：${cmd.content.txen}；
                ${this.showLang('com.lighting.repeat.num')}：${cmd.content.repeat}；
                ${this.showLang('com.lighting.send.cycle')}：${cmd.content.period}；
                ${this.showLang('com.lighting.response.range')}：${cmd.content.range}；
                ${this.showLang('com.lighting.is.put.signal')}：${cmd.content.rxen}；
                ${this.showLang('com.lighting.linkage.mode')}：${cmd.content.mode}；
                ${this.showLang('com.lighting.delay.lamp1')}：${cmd.content.keep1}；
                ${this.showLang('com.lighting.delay.lamp2')}：${cmd.content.keep2}；
                ${this.showLang('com.lighting.is.sensing')}：${cmd.content.sensor ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；`
        } else if (cmd.code == 'getAlarmStatus') {
          content = `${this.showLang('com.lighting.V.alarm')}：${cmd.content.voltage ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；
                ${this.showLang('com.lighting.I.alarm')}：${cmd.content.current ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；
                ${this.showLang('com.lighting.T.alarm')}：${cmd.content.temp ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；`
        } else if (cmd.code == 'getGyroLevel') {
          content = `${this.showLang('com.but.tiltThreshold')}：${cmd.content.level}；`
        } else if (cmd.args.channel) {
          content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.success')}${p1}`
        } else {
          content = `${p}${this.showLang('com.ins.success')}${p1}`
        }
      }
      return content
    },
    cmdResultChanged: function () {
      let cmds = this.commands.filter(p => this.cmdIds.indexOf(p.id) != -1)
      if (cmds.length == 0) return
      let contents = []
      for (let cmd of cmds) {
        contents.push(this.getCmdStatus(cmd))
      }
      let content = contents.join(' ')
      this.$store.commit('auth/hideSpin')
      setTimeout(() => {
        this.$Modal.success({
          title: this.showLang('com.ins.result'),
          content: content
        })
      }, 500)
    },
    getBoxDeviceIds: function () {
      if (!this.currentStation) return []
      return this.currentStation.deviceIds
    },
    argsSaved: function (params) {
      let cmdids = [];
      let chks = [this.selected]
      let cmds = []
      let ns = []
      for (let chk of chks) {
        ns.push(chk.name)
        chk.result = ''
        chk.status = 0
        let args = this.clone(params.args) // {channel: chk.channel}
        let code = params.code
        if (params.code == 'openSingle' || params.code == 'closeSingle') {
          code = 'handSingle'
        } else if (params.code == 'handControl') {
          args.point = false
        } else if (params.code == 'setYearPlan') {
          args.timeId = chk.timeId
        } else if (params.code == 'update') {
          args.flag = 1
        }
        cmdids.push(chk.id)
        cmds = { "code": code, "list": [chk.id], "checkUserId": 0, "args": args }
      }
      if (params.code == 'update') {
        this.$Modal.confirm({
          title: this.showLang('com.op.prompt'),
          content: this.showLang('com.ins.upgrade.device', ns),
          onOk: async () => {
            this.$set(this, 'cmdIds', cmdids)
            this.sendLampOld(cmds)
          }
        })
      } else {
        this.$set(this, 'cmdIds', cmdids)
        this.sendLampOld(cmds)
      }
    },
    sendLampOld(cmd) {
      this.$axios.post(`//${this.domains.trans}/station/command/SendLampOld`, cmd).then(res => {
        if (res.code == 0) {
          for (let item of res.data.list) {
            this.$set(this.cmdResults, 'code', cmd.code);
            this.$set(this.cmdResults, 'args', cmd.args);
            this.$set(this.cmdResults, 'cmdId', item.success ? item.message : '');
            this.$set(this.cmdResults, 'status', item.success ? 1 : 8);
            this.$set(this.cmdResults, 'content', {});
            this.$set(this.cmdResults, 'message', item.success ? '指令已进入发送队列' : item.message);
            if (!item.success) {
              this.$store.commit('auth/hideSpin');
              setTimeout(() => {
                this.$Modal.success({
                  title: this.showLang('com.ins.result'),
                  content: item.message
                });
              }, 500);
            }
          }
        }
      });
    },
    getItemInfo: function () {
      this.$axios.post(`//${this.domains.trans}/station/gis/PoleInfo`, { id: this.item.id }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'poleInfo', res.data)
          if (this.poleInfo.lights.length > 0) {
            this.$set(this, 'selected', this.poleInfo.lights[0])
          } else {
            this.$set(this, 'selected', {})
          }
          this.refresh = Math.ceil(Math.random() * 10000)
          if (this.selected.content && this.selected.content.en1) {
            this.enNum++;
          }
          if (this.selected.content && this.selected.content.en2) {
            this.enNum++;
          }
          if (this.selected.content && this.selected.content.en3) {
            this.enNum++;
          }
          if (this.selected.content && this.selected.content.en4) {
            this.enNum++;
          }
        }
      })
    },
    cancel() {
      this.showModal = false
    }
  }
}
</script>
<style scoped>
.item-empty {
  /* border: solid 1px #1b5fa8; */
  text-align: center;
  padding: 30px;
}
.item-menu {
  height: 40px;
  line-height: 35px;
}
.map-tool-container1 {
  display: flex;
  /* border-radius: 3px; */
  background-color: white;
  height: 40px;
  padding-left: 5px;
}
.map-tool-container1 .item {
  /* width: 100px; */
  height: 40px;
  line-height: 35px;
  background-color: white;
  color: black;
  cursor: pointer;
  border: solid 1px #1b5fa8;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding: 0 15px;
  margin-right: 5px;
}
.map-tool-container1 .active {
  background-color: #1b5fa8;
  color: white;
}
.control-item {
  height: 36px;
  background: #3880fc;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  margin: 10px 20px 10px 2px;
  padding: 0 18px;
}
.control-item img {
  display: inline-block;
  vertical-align: middle;
}
.light-table td {
  padding: 5px 0;
  height: 40px;
  white-space: nowrap;
  border-collapse: collapse;
  text-align: center;
}
.light-table tr {
  background: #f7f9fd;
}
.light-table tr:nth-child(odd) {
  background: #e5edf9;
}
.light-table td.blue {
  color: #3880fc;
}
.light-table td.bold {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.light-table {
  width: 100%;
}
.map-con-info {
  display: flex;
  justify-content: flex-start;
}
.map-con-info-box {
  width: 50%;
  margin: 15px 0 0;
}
.map-con-info-box p {
  margin-bottom: 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-left: 2px;
}
.item-control-list {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}
.control-list-box {
  width: 146px;
  height: 32px;
  background: #d3deee;
  border-radius: 6px 6px 0px 0px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  margin-left: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 4px;
  display: block;
}
.control-list-box.control-list-active {
  color: #ffffff;
  background: #3880fc;
}
</style>
