<template>
<div class="flow-container">
  <div class="flow-content" ref="table">
    <vxe-table  :empty-text="showLang('com.data.no')" border show-overflow show-header-overflow :data="organs" :height="tabHeight" :row-config="{isHover: true}">
      <vxe-column field="name" :title="showLang('com.tab.title')" width="250"></vxe-column>
      <vxe-column field="linkman" :title="showLang('work.contacts')" width="150"></vxe-column>
      <vxe-column field="mobile" :title="showLang('com.site.phone')" width="120"></vxe-column>
      <vxe-column field="groupNames" :title="showLang('work.Manage.scope')"></vxe-column>
      <!-- <vxe-column field="userNames" :title="showLang('work.personnel.scope')"></vxe-column> -->
      <vxe-column field="paramTypeNames" :title="showLang('work.support.dev.range')"></vxe-column>
      <!-- <vxe-column :title="showLang('work.support.dev.range')">
        <template #default="params">
          {{getParamTypes(params.row.paramTypes)}}
        </template>
      </vxe-column> -->
      <vxe-column field="mainUserName" :title="showLang('work.main.duty.personnel')" width="150"></vxe-column>
      <vxe-column field="bakUserName" :title="showLang('work.candidate.duty.personnel')" width="150"></vxe-column>
      <vxe-column width="250" :title="showLang('com.right.nav.operate')" fixed="right">
        <template #header>
          <Button v-if="funCodes('aoa')" size="small" type="primary" @click="handleAdd" style="margin-right: 5px">{{showLang('com.op.add')}}</Button>
          <Button v-if="funCodes('aov')" size="small" type="primary" @click="getList">{{showLang('com.op.refresh')}}</Button>
        </template>
        <template #default="params">
          <Button v-if="funCodes('aoe')" size="small" type="success" @click="handleDuty(params)" style="margin-right: 5px">{{showLang('work.set.duty.personnel')}}</Button>
          <Button v-if="funCodes('aoe')" size="small" type="info" @click="handleEdit(params)" style="margin-right: 5px">{{showLang('com.op.edit')}}</Button>
          <Button v-if="funCodes('aod')" size="small" type="error" @click="handleDelete(params)" style="margin-right: 5px">{{showLang('com.op.del')}}</Button>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
  <ModalOrganEdit v-model="showEditModel" :item="editItem" :types="types" @saved="getList"/>
  <ModalDutyEdit v-model="showDutyModel" :item="editItem" @saved="getList"/>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalOrganEdit from './ModalOrganEdit'
import ModalDutyEdit from './ModalDutyEdit'
export default {
  name: 'WorkFlowDefineIndex',
  components: {
    ModalOrganEdit,
    ModalDutyEdit,
  },
  props: {
    sys: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      showEditModel: false,
      showDutyModel: false,
      tabHeight:500,
      organs: [],
      flowId: 0,
      editItem: {},
      forms: [],
      types: [
        {id: 1, name: '总配电', lang:'dev.main',tps: ['road', 'pole', 'factory']},
        // {id: 10, name: '线缆', lang:'com.device.cable',tps: ['road', 'pole', 'factory']},
        {id: 14, name: '灯杆', lang:'com.device.pole',tps: ['road', 'pole', 'factory', 'light']},
        {id: 199, name: '灯控', lang:'com.device.lamp',tps: ['road', 'pole', 'factory', 'light']},
        {id: 299, name: '光控', lang:'com.device.lightControl',tps: ['road', 'pole', 'factory']},
        {id: 301, name: '视频监控', lang:'com.device.video.monitoring',tps: ['pole']},
        {id: 302, name: '广告屏LED', lang:'com.device.LED',tps: ['pole']},
        {id: 303, name: '环境监测', lang:'com.device.environment',tps: ['pole']},
        {id: 304, name: '广播', lang:'com.device.broadcast',tps: ['pole']},
        {id: 305, name: '可视对讲', lang:'com.device.video.intercom',tps: ['pole']},
        {id: 306, name: 'WIFI', lang:'com.device.wifi',tps: ['pole']},
        {id: 307, name: '充电桩', lang:'com.device.charging',tps: ['pole']},
        {id: 309, name: '电源网关', lang:'com.device.gateway',tps: ['pole']},
        {id: 310, name: '智能垃圾桶', lang:'com.device.trash',tps: ['pole']},
        {id: 311, name: '智能锁', lang:'com.device.lock',tps: ['pole']},
        {id: 312, name: '手机充电', lang:'com.device.phone',tps: ['pole']},
        {id: 313, name: '公共交通', lang:'com.device.traffic',tps: ['pole']},
        {id: 320, name: '智能井盖', lang:'com.device.cover',tps: ['pole']},
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 200);
    // this.getForms();
    this.getList();
  },
  methods: {
    getParamTypes: function(ts){
      return this.types.filter(p => ts.indexOf(p.id) != -1).map(p => p.name).join(', ');
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 6;
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModel = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModel = true;
    },
    handleDuty: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showDutyModel = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content:this.showLang('com.tips.ok.del.name',params.row.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/DeleteMaintanceOrgan`, {id: params.row.id}).then(res => {
            if(res.code == 0){
              this.$Message.info(this.showLang('com.tips.del.success'));
              this.getList();
            }
          });
        }
      });
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/Maintance/QueryMaintanceOrgan`, {}).then(res => {
        if(res.code == 0){
          this.$set(this, 'organs', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.flow-container{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flow-menu{
  height: 40px;
  margin-bottom: 5px;
  flex: none;
  border-radius: 6px;
  display: flex;
  padding: 3px;
}
.flow-content{
  height: 500px;
  flex: auto;
  padding: 3px;
  border-radius: 6px;
}
</style>
