<template>
  <Modal v-model="showModal" :mask-closable="false" :width="540">
    <div slot="header">{{item.name}}</div>
    <p style="margin: 5px 20px">{{showLang('com.tab.title')}}：{{item.name}}<span
        style="margin-left:20px">{{showLang('gis.road')}}：{{selected.pathName}}</span></p>
    <p style="margin: 5px 20px">{{showLang('com.import.cn.lng')}}：{{item.lng}}<span
        style="margin-left:20px">{{showLang('com.import.cn.lat')}}：{{item.lat}}</span></p>
    <div :style="{height: `230px`,overflow:'auto',border:'solid 0 red'}">
      <div v-if="selected && selected.lastData">
        <table class="light-table">
          <tr>
            <td>{{showLang('com.date.Collection')}}</td>
            <td colspan="4" class="blue">{{new Date(selected.lastData.time).format('yyyy-MM-dd HH:mm:ss')}}</td>
          </tr>
          <tr>
            <td>{{showLang('com.Collection.item')}}</td>
            <td>{{showLang('com.data1')}}</td>
            <td>{{showLang('com.data2')}}</td>
            <td>{{showLang('com.data3')}}</td>
            <td>{{showLang('com.data.mean.val')}}</td>
          </tr>
          <tr>
            <td>{{showLang('cmd.lux')}}</td>
            <td class="blue">{{img.val(selected.lastData.ls[0],2)}} lux</td>
            <td class="blue">{{img.val(selected.lastData.ls[1],2)}} lux</td>
            <td class="blue">{{img.val(selected.lastData.ls[2],2)}} lux</td>
            <td class="blue">{{img.val(selected.lastData.l,2)}} lux</td>
          </tr>
          <tr>
            <td>{{showLang('com.lighting.T')}}</td>
            <td class="blue">{{img.val(selected.lastData.ts[0],2)}} ℃</td>
            <td class="blue">{{img.val(selected.lastData.ts[1],2)}} ℃</td>
            <td class="blue">{{img.val(selected.lastData.ts[2],2)}} ℃</td>
            <td class="blue">{{img.val(selected.lastData.t,2)}} ℃</td>
          </tr>
          <tr>
            <td>{{showLang('lux.humidity')}}</td>
            <td class="blue">{{img.val(selected.lastData.hs[0],2)}} %</td>
            <td class="blue">{{img.val(selected.lastData.hs[1],2)}} %</td>
            <td class="blue">{{img.val(selected.lastData.hs[2],2)}} %</td>
            <td class="blue">{{img.val(selected.lastData.h,2)}} %</td>
          </tr>
        </table>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalMakerInfo',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      productId: '',
      selected: {},
      showInfoType: '',
      refresh: 0,
      showArgModal: false,
      cmdItem: {},
      cmdIds: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.getItemInfo();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
  },
  mounted: function () {
  },
  methods: {
    getItemInfo: function () {
      this.$axios.post(`//${this.domains.trans}/station/gis/MakerInfo`, { type: this.item.type, id: this.item.id }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'selected', res.data);
          this.refresh = Math.ceil(Math.random() * 10000);
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.light-table td {
  padding: 5px 0;
  height: 40px;
  white-space: nowrap;
  border-collapse: collapse;
  text-align: center;
}
.light-table tr {
  background: #f7f9fd;
}
.light-table tr:nth-child(odd) {
  background: #e5edf9;
}
.light-table td.blue {
  color: #3880fc;
}
.light-table td.bold {
  color: #021629;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.light-table {
  width: 100%;
}
</style>