<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">选择灯杆</div>
    <div class="search-data-content" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" :data="list" :height="500" :row-config="{isHover: true}"
        :seq-config="{startIndex: (filter.index-1)*filter.size}" >
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" width="280" header-align="center" fixed="left">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="stationName" :title="showLang('com.site.associated')" sortable width="250"
          header-align="center">
          <template #default="{ row }">
            {{row.stationName}}
          </template>
        </vxe-column>
        <vxe-column field="type" :title="showLang('com.import.cn.pole.type')" sortable width="150"
          header-align="center">
          <template #default="{ row }">
            {{row.poleType}}
          </template>
        </vxe-column>
        <vxe-column field="armType" :title="showLang('com.import.cn.arm.type')" sortable width="150"
          header-align="center">
          <template #default="{ row }">
            {{row.armType}}
          </template>
        </vxe-column>
        <vxe-column field="hight" :title="showLang('com.import.cn.pole.height')" sortable width="120"
          header-align="center">
          <template #default="{ row }">
            {{row.hight}}
          </template>
        </vxe-column>
        <vxe-column field="lng" :title="showLang('com.import.cn.pole.lng')" sortable width="120" header-align="center">
          <template #default="{ row }">
            {{row.lng}}
          </template>
        </vxe-column>
        <vxe-column field="lat" :title="showLang('com.import.cn.pole.lat')" sortable width="120" header-align="center">
          <template #default="{ row }">
            {{row.lat}}
          </template>
        </vxe-column>
        <vxe-column field="code" :title="showLang('com.device.pole.code')" width="280" header-align="center">
          <template #default="{ row }">
            {{row.code}}
          </template>
        </vxe-column>
        <vxe-column width="80" fixed="right">
          <template #default="{ row }">
            <Button type="info" size="small" style="margin-right: 5px"
              @click="handleEdit(row)">{{showLang('com.op.select')}}</Button>
            <!-- <AuthButton opCode='lpd2' type="error" @click="handleDelete(row)">{{showLang('com.op.del')}}</AuthButton> -->
          </template>
        </vxe-column>
      </vxe-table>
      <p>
        <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
          :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes'
          @page-change="handlePageChange">
        </vxe-pager>
      </p>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <Button style="margin-right: 8px" type="warning" @click="notSelect">{{showLang('com.op.select.not')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalPoleImage',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    }
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      time: 0,
      max: 0,
      devs: [],
      flags: ['A相', 'B相', 'C相'],
      pageSizes: [20, 100, 500, 1000, 5000],
      total: 0,
      list: [],
      filter: {
        groupId: 0,
        stationId: 0,
        name: "",
        poleType: "",
        armType: "",
        heightMin: 0,
        heightMax: 100,
        latMin: 0,
        latMax: 90,
        lngMin: 0,
        lngMax: 180,
        size: 20,
        index: 1,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['needCheckPswd', 'showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['groups', 'selectedNode', 'selectedGroups', 'rootTitle']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('show wait modal',this.item)
        this.getPoles();
        this.filter.index = 1;
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getPoles()
    },
    getPoles: function () {
      this.filter.groupId = 0, this.filter.stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        this.filter.stationId = this.selectedNode.id;
      } else {
        this.filter.groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPoleByFilter`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'total', res.data.count);
        }
      });
    },
    handleEdit: function (params) {
      // console.log("handle select", params);
      let result = this.clone(this.item);
      // console.log("handle select", params, this.item, result, this.item == result);
      result.poleId = params.id;
      result.poleName = params.name;
      this.$emit('selected', result)
    },
    notSelect: function () {
      let result = this.clone(this.item);
      // console.log("handle select", params, this.item, result, this.item == result);
      result.poleId = 0;
      result.poleName = '';
      this.$emit('selected', result)
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.dev-code-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.demo-spin-col .circular {
  width: 25px;
  height: 25px;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
.search-data-content {
  position: relative;
  height: 540px;
  /* border: solid 1px red; */
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>