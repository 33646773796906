<template>
  <div class="light-control-container">
    <!-- ['dco'] -->
    <MenuCommon v-if="args.type == 'common' && funCodes('dco')" class="light-control-menu" @cmd="cmdGot" />
    <MenuStation v-else-if="args.type == 'station'" class="light-control-menu" @cmd="cmdGot" />
    <ListCommon ref="lightList1" class="light-control-list" :type="args.type" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import MenuCommon from './menus/Common'
import MenuStation from './menus/Station'
import ListCommon from './list/Common'
export default {
  name: 'CommandLightIndex',
  components: {
    MenuCommon,
    ListCommon,
    MenuStation,
  },
  props: {
    args: {
      type: Object,
      default(){
        return {
          type: 'common',
        }
      }
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    cmdGot: function(params){
      // var list = this.$refs.lightList1.getCheckedItems();
      this.$refs.lightList1.dealCmd(params);
    },
  }
}
</script>
<style scoped>
.light-control-container{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  height: 100%;
}
.light-control-menu{
  /* border: solid 1px red; */
  height: 50px;
  flex: none;
}
.light-control-list{
  /* border: solid 1px red; */
  height: 300px;
  flex: auto;
}
</style>