<template>
  <Modal v-model="showModal" :mask-closable="false" width="1450px">
    <div slot="header">{{showLang('com.edit.lamp.info.batch')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="130">
      <FormItem v-if="['updateLightOutput'].indexOf(item.code) != -1" prop="name" :label="showLang('com.contact.outout.need')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.content.oid" style="width: 200px; margin: 0 10px;">
          <template v-for="(s, idx) in outputs">
            <Option :value="s.id" :key="idx">{{s.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem v-if="['updateLightType'].indexOf(item.code) != -1" prop="name" :label="showLang('com.lamp.type')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.content.type" style="width: 200px; margin: 0 10px;">
          <template v-for="(v, k) in newLightTypes">
            <Option :value="parseInt(k)" :key="k">{{v}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem v-if="['updateLightGroup'].indexOf(item.code) != -1" prop="name" :label="showLang('com.light.group')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.content.area" style="width: 123px; margin: 0 10px;">
          <template v-for="(s, idx) in areas">
            <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
          </template>
        </Select>
      </FormItem>
      <template v-for="n in 4">
        <FormItem :prop="`en${n}`" :label="`${n}路控制方式`" style="margin-right: 15px" :key="`en${n}`" v-if="['updateLightTimeTable'].indexOf(item.code) != -1">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form.content[`mode${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in controlMode" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联计时日表：
            <Select :placeholder="showLang('save.select')" v-model="form.content[`timeId1${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in timeId1Data" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联准时日表：
            <Select :placeholder="showLang('save.select')" v-model="form.content[`timeId2${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in timeId7Data" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联115B准时日表：
            <Select :placeholder="showLang('save.select')" v-model="form.content[`timeId2${n}b`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in timeId8Data" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
          </div>
        </FormItem>
      </template>
      <!-- <FormItem  prop="stationId" :label="showLang('com.ins.timeTable')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.content.timeId" style="width: 400px; margin: 0 10px;">
          <Option :value="0">{{showLang('com.state.not')}}</Option>
          <template v-for="(s, idx) in timeTables">
            <Option :value="s.id" :key="idx">[{{s.mode}}][{{s.type}}]{{s.name}}</Option>
          </template>
        </Select>
      </FormItem> -->
      <FormItem v-if="['updateTempRate'].indexOf(item.code) != -1" prop="ent" :label="showLang('com.state.is.t.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.ent" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.content.tl" style="width: 250px; margin: 0 5px;"><span slot="prepend">上限阈值</span><span slot="append">℃</span></Input>
          <Input type="number" v-model="form.content.th" style="width: 250px; margin: 0 5px;"><span slot="prepend">降功率阈值</span><span slot="append">℃</span></Input>
          是否联动：
          <i-switch v-model="toutEnu" @on-change="toutEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px" :key="'ulb'+uhbEnu">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-if="toutEnu" v-model="form.content.tout" style="width: 260px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">%</span></Input>
        </div>
      </FormItem>
      <FormItem v-if="['updateVoltageRate'].indexOf(item.code) != -1" prop="env" :label="showLang('com.state.is.v.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.enu" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.content.ul" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">V</span></Input>
          <Input type="number" v-model="form.content.uh" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">V</span></Input>
        </div>
      </FormItem>
      <FormItem prop="env1" v-if="['updateVoltageRate'].indexOf(item.code) != -1" label="115B降功率电压" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.content.ulr" style="width: 160px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">V</span></Input>
          是否联动：
          <i-switch v-model="uhbEnu" @on-change="uhbEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input v-if="uhbEnu" type="number" v-model="form.content.uhb" style="width: 200px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">0-100(%)</span></Input>
          <Input type="number" v-model="form.content.uhr" style="width: 160px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">V</span></Input>
          是否联动：
          <i-switch v-model="ulbEnu" @on-change="ulbEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input v-if="ulbEnu" type="number" v-model="form.content.ulb" style="width: 200px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">0-100(%)</span></Input>
        </div>
      </FormItem>

      <FormItem v-if="['updateLightEnableAlarm'].indexOf(item.code) != -1" prop="enc" :label="showLang('com.state.is.i.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.enc" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.content.cl" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">A</span></Input>
          <Input type="number" v-model="form.content.ch" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">A</span></Input>
        </div>
      </FormItem>
      <template v-for="n in 4">
        <FormItem prop="enp" :label="showLang('com.ope.way.channel.1')" style="margin-right: 15px" :key="n" v-if="['updateControlChannel'].indexOf(item.code) != -1">
          <div class="flex-row">
            <i-switch v-model="form.content[`en${n}`]" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            <Select :placeholder="showLang('save.select')" v-model="form.content[`nm${n}`]" style="margin-right:10px;width:70px">
              <Option :value="showLang('com.lamp.host')">{{showLang('com.lamp.host')}}</Option>
              <Option :value="showLang('com.lamp.assist')">{{showLang('com.lamp.assist')}}</Option>
            </Select>
            <Input type="number" v-model="form.content[`pr${n}`]" style="width: 170px; margin-right:10px"><span slot="prepend">{{showLang('com.lighting.pr1')}}</span><span slot="append">W</span></Input>
            <Input type="number" v-model="form.content[`prl${n}`]" style="width:150px; margin: 0 5px;"><span slot="prepend">下限值</span><span slot="append">%</span></Input>
            <Input type="number" v-model="form.content[`prh${n}`]" style="width: 150px; margin: 0 5px;"><span slot="prepend">上限值</span><span slot="append">%</span></Input>
            <Input type="number" v-model="form.content[`lp${n}`]" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span><span slot="append">W</span></Input>
            <Input type="number" v-model="form.content[`lc${n}`]" style="width: 120px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
            <Input v-model="form.content[`tp${n}`]" style="width: 160px; margin-right:10px;"><span slot="prepend">{{showLang('com.import.cn.lamp.type')}}</span></Input>
            <Input type="number" v-model="form.content[`pc${n}`]" style="width: 160px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.pc')}}</span></Input>
          </div>
        </FormItem>
      </template>
      <FormItem v-if="['updateLeakageRate'].indexOf(item.code) != -1" prop="leac" :label="showLang('com.state.is.leac.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.enleac" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.content.leac" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.cl')}}</span><span slot="append">mA</span></Input>
          <Input type="number" v-model="form.content.leah" style="width: 250px; margin: 0 5px;"><span slot="prepend">降功率漏电上限</span><span slot="append">mA</span></Input>
          是否联动：
          <i-switch v-model="loutEnu" @on-change="loutEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px" :key="'ulb'+uhbEnu">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-if="loutEnu" v-model="form.content.lout" style="width: 220px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">%</span></Input>

        </div>
      </FormItem>
      <FormItem v-if="['updateLeakageRate'].indexOf(item.code) != -1" prop="leav" :label="showLang('com.state.is.leav.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.enleav" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.content.leav" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.lu')}}</span><span slot="append">V</span></Input>
        </div>
      </FormItem>
      <FormItem v-if="['updateWaterRate'].indexOf(item.code) != -1" prop="enp" label="水浸监测" style="margin-right: 15px">
        <div class="flex-row">
          {{showLang('com.stat.level1')}}{{showLang('com.ins.ch.order')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.l1" style="margin: 0 10px;width:100px">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <Option v-for="n in 16" :value="n" :key="n">S{{n}}</Option>
          </Select>
          {{showLang('com.stat.level2')}}{{showLang('com.ins.ch.order')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.l2" style="margin: 0 10px;width:100px">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <Option v-for="n in 16" :value="n" :key="n">S{{n}}</Option>
          </Select>
          {{showLang('com.stat.level3')}}{{showLang('com.ins.ch.order')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.l3" style="margin: 0 10px;width:100px">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <Option v-for="n in 16" :value="n" :key="n">S{{n}}</Option>
          </Select>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalEditArgs',
  components: {

  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    areas: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      accounts: [],
      poles: [],
      devices: [],
      timeTables: [],
      timeId1Data: [],
      timeId7Data: [],
      timeId8Data: [],
      controlMode: [{ name: '计时日表', id: 0 }, { name: '准时日表', id: 1 }],
      outputs: [],
      ulbEnu: false,
      uhbEnu: false,
      toutEnu: false,
      loutEnu: false,
      form: {
        id: 0,
        stationId: 0,
        connectType: 0, //设备接入方式，0为设备直连，其他都是从三方平台或其他SDK接入
        deviceId: 0,
        code: '',
        name: '',
        type: 199,
        content: {
          //基本配置相关
          oid: 0, //关联控制输出
          type: 101, //单灯类型
          timeId: 0, //时间表ID
          area: 0, //单独所属控制区域
          pole: 0, //关联灯杆
          enu: false, //是否启用电压告警
          uh: 280, //电压阈值上限
          ul: 80, //电压阈值下限
          enc: false,//是否启用电流告警
          cl: 0.5, //电流阈值下限
          ch: 10, //电流阈值上限
          ent: false, //是否启用温度告警
          tl: 50,//温度阈值下限
          th: 80,//温度阈值上限
          tout: 0,////降功率调光值
          enleac: false, //是否启用漏电电流告警
          leac: 50,//漏电电流阈值
          leah: 100,//降功率漏电上限
          lout: 0,
          enleav: false,//是否启用漏电电压告警
          leav: 100,//漏电电压阈值
          engyro: false, //是否启用灯杆倾斜告警
          uhr: 277,//115B:降功率电压上限(严重报警，联动调光)，默认277V
          uhb: 255,//115B:降功率过压联动调光值(0-100，其它值功能无效，默认255)
          ulr: 100,//降功率电压下限(严重报警，联动调光)，默认100V
          ulb: 255,//降功率欠压联动调光值(0-100，其它值功能无效，默认255)
          //1路
          en1: true, //控制使能
          nm1: '主灯', //名称
          pr1: 100, //额定功率
          tp1: '默认', //灯具类型
          lp1: 100, //灯具功率
          prl1: 50, //1路功率下限值
          prh1: 250, //1路功率上限值
          lc1: 1, //灯具数量
          pc1: 1,
          mode1: 0,//控制模式
          timeId11: 0,//计时日表ID
          timeId21: 0,//准时日表ID
          timeId21b: 0,//准时日表ID
          //2路
          en2: false, //控制使能
          nm2: '辅灯', //名称
          pr2: 100, //额定功率
          tp2: '默认', //灯具类型
          lp2: 100, //灯具功率
          prl2: 50, //1路功率下限值
          prh2: 250, //1路功率上限值
          lc2: 1, //灯具数量
          pc2: 1,
          mode2: 0,//控制模式
          timeId12: 0,//计时日表ID
          timeId22: 0,//准时日表ID
          timeId22b: 0,//准时日表ID
          //3路
          en3: false, //控制使能
          nm3: '辅灯', //名称
          pr3: 100, //额定功率
          tp3: '默认', //灯具类型
          lp3: 100, //灯具功率
          prl3: 50, //1路功率下限值
          prh3: 250, //1路功率上限值
          lc3: 1, //灯具数量
          pc3: 1,
          mode3: 0,//控制模式
          timeId13: 0,//计时日表ID
          timeId23: 0,//准时日表ID
          timeId23b: 0,//准时日表ID
          //4路
          en4: false, //控制使能
          nm4: '辅灯', //名称
          pr4: 100, //额定功率
          tp4: '默认', //灯具类型
          lp4: 100, //灯具功率
          prl4: 50, //1路功率下限值
          prh4: 250, //1路功率上限值
          lc4: 1, //灯具数量
          pc4: 1,
          mode4: 0,//控制模式
          timeId14: 0,//计时日表ID
          timeId24: 0,//准时日表ID
          timeId24b: 0,//准时日表ID
        }
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        if (this.item.code == 'updateLightTimeTable') {
          this.getLightList();
          // this.getUseablePlan();
        }
        if (this.item.code == 'updateLightOutput') {
          this.getStationOutputs();
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function () {
    this.form.content.nm1 = this.showLang('com.lamp.host');
    this.form.content.nm2 = this.showLang('com.lamp.assist');
    this.form.content.nm3 = this.showLang('com.lamp.assist');
    this.form.content.nm4 = this.showLang('com.lamp.assist');
    this.form.content.tp1 = this.showLang('com.state.default');
    this.form.content.tp2 = this.showLang('com.state.default');
    this.form.content.tp3 = this.showLang('com.state.default');
    this.form.content.tp4 = this.showLang('com.state.default');
  },
  methods: {
    toutEnuChanged() {
      if (this.toutEnu) {
        this.form.content.tout = 0
      } else {
        this.form.content.tout = 255
      }
    },
    loutEnuChanged() {
      if (this.loutEnu) {
        this.form.content.lout = 0
      } else {
        this.form.content.lout = 255
      }
    },
    ulbEnuChanged() {
      if (this.ulbEnu) {
        this.form.content.ulb = 0
      } else {
        this.form.content.ulb = 255
      }
    },
    uhbEnuChanged() {
      if (this.uhbEnu) {
        this.form.content.uhb = 0
      } else {
        this.form.content.uhb = 255
      }
    },
    getStationOutputs: function () {
      this.form.content.oid = 0;
      this.$axios.post(`//${this.domains.trans}/station/config/QueryOutput`, { groupId: 0, stationId: this.item.stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'outputs', res.data);
        }
      });
    },
    getLightList() {//计时日表 准时日表
      this.timeId1Data = [];
      this.timeId7Data = [];
      this.timeId8Data = [];
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLight6List`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeId1Data', res.data);
        }
      });
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLight7List`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeId7Data', res.data);
        }
      });
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLight8List`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeId8Data', res.data);

        }
      });
    },
    getUseablePlan: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'timeTables', res.data);
          // if(callback && `${typeof callback}` == 'function')callback();
        }
      });
    },
    ok: async function () {
      switch (this.item.code) {
        case 'updateControlChannel': {
          if (!this.form.content.en1 && !this.form.content.en2) {
            this.$Message.warning('至少选择一个通道');
            return;
          }
          let args = {
            // ats: this.form.content.ats, //报警时段开始时间
            // ate: this.form.content.ate, //报警时段结束时间
            //1路
            en1: this.form.content.en1, //控制使能
            nm1: this.form.content.nm1, //名称
            // gp1: this.form.content.gp1, //分组号
            pr1: this.form.content.pr1, //额定功率
            tp1: this.form.content.tp1, //灯具类型
            lp1: this.form.content.lp1, //灯具功率
            lc1: this.form.content.lc1, //灯具数量
            pc1: this.form.content.pc1, //灯具数量
            prl1: this.form.content.prl1, //1路功率下限值
            prh1: this.form.content.prh1, //1路功率上限值
            // pa1:this.form.content.pa1,//是否启用功率报警
            // pl1:this.form.content.pl1,//功率下限值
            // ph1:this.form.content.ph1,//功率上限值
            //2路
            en2: this.form.content.en2, //控制使能
            nm2: this.form.content.nm2, //名称
            // gp2: this.form.content.gp2, //分组号
            pr2: this.form.content.pr2, //额定功率
            tp2: this.form.content.tp2, //灯具类型
            lp2: this.form.content.lp2, //灯具功率
            lc2: this.form.content.lc2, //灯具数量
            pc2: this.form.content.pc2, //灯具数量
            prl2: this.form.content.prl2, //2路功率下限值
            prh2: this.form.content.prh2, //2路功率上限值
            // pa2:this.form.content.pa2,//是否启用功率报警
            // pl2:this.form.content.pl2,//功率下限值
            // ph2:this.form.content.ph2,//功率上限值
            // //3路
            en3: this.form.content.en3, //控制使能
            nm3: this.form.content.nm3, //名称
            // gp3: this.form.content.gp3, //分组号
            pr3: this.form.content.pr3, //额定功率
            tp3: this.form.content.tp3, //灯具类型
            lp3: this.form.content.lp3, //灯具功率
            lc3: this.form.content.lc3, //灯具数量
            pc3: this.form.content.pc3, //灯具数量
            prl3: this.form.content.prl3, //3路功率下限值
            prh3: this.form.content.prh3, //3路功率上限值
            // pa3:this.form.content.pa3,//是否启用功率报警
            // pl3:this.form.content.pl3,//功率下限值
            // ph3:this.form.content.ph3,//功率上限值
            // //4路
            en4: this.form.content.en4, //控制使能
            nm4: this.form.content.nm4, //名称
            // gp4: this.form.content.gp4, //分组号
            pr4: this.form.content.pr4, //额定功率
            tp4: this.form.content.tp4, //灯具类型
            lp4: this.form.content.lp4, //灯具功率
            lc4: this.form.content.lc4, //灯具数量
            pc4: this.form.content.pc4, //灯具数量
            prl4: this.form.content.prl4, //4路功率下限值
            prh4: this.form.content.prh4, //4路功率上限值
            // pa4:this.form.content.pa4,//是否启用功率报警
            // pl4:this.form.content.pl4,//功率下限值
            // ph4:this.form.content.ph4,//功率上限值
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateLightGroup': {
          let args = {
            area: this.form.content.area, //单独所属控制区域
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateLightType': {
          let args = {
            type: this.form.content.type, //单灯类型
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateLightTimeTable': {
          let args = {
            mode1: this.form.content.mode1,//控制模式
            timeId11: this.form.content.timeId11,//计时日表ID
            timeId21: this.form.content.timeId21,//准时日表ID
            timeId21b: this.form.content.timeId21b,//准时日表ID
            mode2: this.form.content.mode2,//控制模式
            timeId12: this.form.content.timeId12,//计时日表ID
            timeId22: this.form.content.timeId22,//准时日表ID
            timeId22b: this.form.content.timeId22b,//准时日表ID
            mode3: this.form.content.mode3,//控制模式
            timeId13: this.form.content.timeId13,//计时日表ID
            timeId23: this.form.content.timeId23,//准时日表ID
            timeId23b: this.form.content.timeId23b,//准时日表ID
            mode4: this.form.content.mode4,//控制模式
            timeId14: this.form.content.timeId14,//计时日表ID
            timeId24: this.form.content.timeId24,//准时日表ID
            timeId24b: this.form.content.timeId24b,//准时日表ID
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateLeakageRate': {
          let args = {
            enleac: this.form.content.enleac,
            leac: this.form.content.leac*1,
            enleav: this.form.content.enleav,
            leah: this.form.content.leah*1,//降功率漏电上限
            lout: this.form.content.lout,
            leav: this.form.content.leav*1,
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateWaterRate': {
          let args = {
            l1: this.form.content.l1, //一级水浸通道号
            l2: this.form.content.l2, //一级水浸通道号
            l3: this.form.content.l3, //一级水浸通道号
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateTempRate': {
          let args = {
            ent: this.form.content.ent, //温度阈值
            tl: this.form.content.tl, //温度阈值
            th: this.form.content.th, //温度阈值
            tout: this.form.content.tout, //温度阈值
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateVoltageRate': {
          let args = {
            enu: this.form.content.enu, //额定电压
            uh: this.form.content.uh, //电压阈值上限
            ul: this.form.content.ul, //电压阈值下限
            uhr: this.form.content.uhr,//115B:降功率电压上限(严重报警，联动调光)，默认277V
            uhb: this.form.content.uhb,//115B:降功率过压联动调光值(0-100，其它值功能无效，默认255)
            ulr: this.form.content.ulr,//降功率电压下限(严重报警，联动调光)，默认100V
            ulb: this.form.content.ulb,//降功率欠压联动调光值(0-100，其它值功能无效，默认255)
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateLightEnableAlarm': {
          let args = {
            enc: this.form.content.enc,
            cl: this.form.content.cl, //温度阈值
            ch: this.form.content.ch, //温度阈值
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        case 'updateLightOutput': {
          if (!this.form.content.oid || this.form.content.oid <= 0) {
            this.$Message.warning(this.showLang('save.output.ctrl.channel'));
            return;
          }
          let args = {
            oid: this.form.content.oid,
          }
          this.$emit('saved', { code: this.item.code, args });
          break;
        }
        default: {
          this.$Message.warning(this.showLang('com.tips.no.batch.edit.type'));
          return;
        }
      }
      this.showModal = false;
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>