<template>
  <div class="station-alarm-container">
    <div class="alarm-data-query">
      {{showLang('cmd.phase')}}：
      <Select :placeholder="showLang('save.select')" v-model="filter.flag" style="width: 100px; margin-right: 10px;">
        <Option value="all">{{showLang('cmd.phase.all')}}</Option>
        <Option value="A">{{showLang('cmd.project.A')}}</Option>
        <Option value="B">{{showLang('cmd.project.B')}}</Option>
        <Option value="C">{{showLang('cmd.project.C')}}</Option>
      </Select>
      <AuthButton v-for="(cmd, idx) in canDoCmds" :key="idx" :opCode="cmd.opcode" size="default" style="margin-right: 5px" @click="clickCmd(cmd)">
        {{showLang(cmd.lang)}}</AuthButton>
      <Button opCode='sc' type="success" size="default" style="margin-right: 5px" @click="sendFailed">{{showLang('com.but.failed.resend')}}</Button>
      <Button opCode='sc' type="success" size="default" style="margin-right: 5px" @click="exportResult">{{showLang('com.op.export.results')}}</Button>
      <Checkbox v-model="clearOldLog">{{showLang('com.lamp.is.ins')}}</Checkbox>
      <Checkbox v-model="isScreenSelected">{{showLang('com.lamp.is.screen')}}</Checkbox>
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh"
        @checkbox-change="selectChangeEvent" :data="devices" :height="tabHeight" :row-config="{isHover: true}"
        :checkbox-config="{checkField: 'checked',highlight: true}">
        <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="pname" :title="showLang('com.ins.ch.name')" sortable width="150" header-align="center">
        </vxe-column>
        <vxe-column field="sname" :title="showLang('com.site.name')" sortable width="150" header-align="center">
        </vxe-column>

        <vxe-column field="channel" :title="showLang('com.lighting.I')+showLang('com.ins.ch.order')" sortable width="120" header-align="center">
          <template #default="{ row }">
            C{{row.channel}}
          </template>
        </vxe-column>
        <vxe-column field="op" :title="showLang('com.associated.output')" sortable width="110" header-align="center">
          <template #default="{ row }">
            {{row.op > 0 ? `K${row.op}` : '-'}}
          </template>
        </vxe-column>
        <vxe-column field="ct" :title="showLang('com.magnification')" sortable width="110" header-align="center">
        </vxe-column>
        <vxe-column field="cr" :title="showLang('com.lighting.rated.v.val')+'(A)'" sortable width="110" header-align="center">
        </vxe-column>
        <vxe-column field="cv" :title="showLang('com.data.current')+'(A)'" sortable width="110" header-align="center">
        </vxe-column>
        <vxe-column field="diff" :title="showLang('com.data.diff')" sortable width="110" header-align="center">
        </vxe-column>
        <vxe-column field="cl" :title="showLang('com.data.lower.val')+'(A)'" sortable width="110" header-align="center">
        </vxe-column>
        <vxe-column field="ch" :title="showLang('com.data.upper.val')+'(A)'" sortable width="110" header-align="center">
        </vxe-column>
        <vxe-column field="message" :title="showLang('com.ins.result')" header-align="center" sortable></vxe-column>
      </vxe-table>
    </div>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
    <ModalCheckAuth v-model="showCheckAuthModal" :users="checkAuthModalUsers" @checked="cmdAuthUserChecked" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
import ModalArgEdit from './ModalArgEdit'
import ModalCheckAuth from './ModalCheckAuth'
export default {
  name: 'CommandCurrentIndex',
  components: {
    ModalArgEdit,
    ModalCheckAuth,
  },
  props: {
  },
  data() {
    return {
      filter: {
        stationId: 0,
        channel: 0,
        flag: 'all',
      },
      tabHeight: 600,
      clearOldLog: this.clearOldLogs,
      cmdType: 'current',
      showCheckAuthModal: false,
      checkAuthModalSuccessFunction: null,
      checkAuthModalUsers: [],
      showArgModal: false,
      showPlanModal: false,
      showCreateModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      items: [
        { needArgs: false, needPswd: false, code: 'getCurrentRate', opcode: 'dcs', name: '查询倍率',lang:'com.but.get.magnification', img: this.img.light.road.single.ctrl.writeTime },
        { needArgs: false, needPswd: true, code: 'setCurrentRate', opcode: 'dcc', name: '设置倍率',lang:'com.but.set.magnification', img: this.img.light.road.single.ctrl.readClock },
        { needArgs: false, needPswd: false, code: 'getCurrentRange', opcode: 'dcs', name: '查询报警上下限',lang:'com.but.get.alarm.limit', img: this.img.light.road.single.ctrl.readClock },
        { needArgs: false, needPswd: true, code: 'setCurrentRange', opcode: 'dcc', name: '设置报警上下限',lang:'com.but.set.alarm.limit', img: this.img.light.road.single.ctrl.readClock },
      ],
      devices: [],
      dataRefresh: 0,
      arrCmds: [],
      cmdResults: {},
      originalDevices: [],
      isScreenSelected: false,
      timer: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'powerTableActions']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    canDoCmds: function () {
      let cmds = [];
      for (let cmd of this.items) {
        if(this.config.currentShowCommands.indexOf(cmd.code) == -1)continue;
        if (this.funCodes(cmd.opcode)) cmds.push(cmd);
      }
      return cmds;
    }
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
    isScreenSelected() {
      this.screenSelected();
    },
    windowOnResize() {
      this.setTableHeight();
    },
    'filter.stationId'() {
      this.initList();
    },
    'filter.channel'() {
      this.initList();
    },
    'filter.flag'() {
      this.initList();
    },
    selectedNode() {
      this.initList();
    },

    // currentPower(){
    //   this.tabName = 'power'
    //   this.lightStyle = 'config'
    // },
  },
  mounted: function () {
    this.clearOldLog = this.clearOldLogs;
    setTimeout(this.setTableHeight, 100);
    this.initList();
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      // this.cmdResults[params.cmdId] = params;
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    })
    // window.eventBus.$on('paramCommandComing', params => {
    //   setTimeout(() => {
    //     let cmds = this.arrCmds.filter(p => p.cmdId == params.cmdId);
    //     if (cmds.length == 0) return;
    //     let its = this.devices.filter(p => p.id == cmds[0].itemId)
    //     if (its.length == 0) return;
    //     this.$set(its[0], 'status', params.status)
    //     this.$set(its[0], 'result', params.message)
    //     this.$set(its[0], 'cmd', params.cmdCode)
    //     this.$set(its[0], 'content', params.content)
    //     this.$set(its[0], 'args', params.args)
    //   }, 1000);
    // })
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    screenSelected() {
      if (this.isScreenSelected) {
        this.devices = this.devices.filter(p => p.checked);
      } else {
        this.devices = this.originalDevices;
      }
    },
    setCmdResult: function(){
      for(let cmdId in this.cmdResults){
        let ds = this.devices.filter(p => p.cmdId == cmdId);
        if(ds.length == 0){
          if(new Date().getTime() - this.cmdResults[cmdId].time > 100000){
            delete this.cmdResults[cmdId];
          }
          continue;
        }else{
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], 'status', item.status);
          this.$set(ds[0], 'result', item.message);
          this.$set(ds[0], 'content', item.content);
          this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
      this.calcSuccessRate();
    },
    calcSuccessRate: function(){
      let total = this.devices.length;
      let success = this.devices.filter(p => p.status == 9).length;
      let rate = 0;
      if(total <= 0){
        rate = 0;
      }else{
        rate = (success * 100 / total).toFixed(2);
      }
      window.eventBus.$emit('commandSuccessRateChanged', {total, success, rate });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    exportResult: function () {
      let cmdData = [];
      this.devices.map(item => {
        cmdData.push({
          pname: item.pname,
          sname: item.sname,
          channel: `C${item.channel}`,
          op: `${item.op > 0 ? `K${item.op}` : '-'}`,
          ct: item.ct,
          cr: item.cr,
          cv: item.cv,
          cl: item.cl,
          ch: item.ch,
          diff: item.cv - item.cr,
          result: this.getCmdStatus(item, false)
        })
      });

      let tabHead = {
        pname:this.showLang('com.ins.ch.name'),
        sname:this.showLang('com.site.name'),
        channel:this.showLang('com.ins.ch.order'),
        op: this.showLang('com.associated.output'),
        ct:this.showLang('com.magnification'),
        cr:this.showLang('com.lighting.rated.v.val'),
        cv:this.showLang('com.data.current'),
        diff:this.showLang('com.data.diff'),
        cl: this.showLang('com.data.lower.val'),
        ch:this.showLang('com.data.upper.val'),
        result:this.showLang('com.ins.result')
      }
      exportXlsx(cmdData, tabHead, this.showLang('com.ins.result'));
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 5;
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let cmds = [];
      let code = '';
      let args = {};
      for (let d of this.devices) {
        if ((d._status == 3 || d._status == 7 || d._status == 8) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, 'status', 0)
          // this.$set(d, 'result', this.showLang('com.lighting.repeat.preparation'))
          this.$set(d, 'message', '准备重发')
          // cmds.push({id: d.id, code: d.cmd, name: d.name, deviceId: d.devId, args: d.args});
          // cmds.push(d.id);
          cmds.push({id:d.pid, flag: d.flag});
          code = d.cmd;
          args = d.args;
        } else if (this.clearOldLog) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '')
        }
      }
      let cmd = {
        code: code,
        list: cmds,
        checkUserId: 0,
        args: args
      };
      setTimeout(() => {
        this.sendCommand(cmd);
      }, 500)
    },
    initList: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryCurrent`, { groupId, stationId, flag: this.filter.flag }).then(res => {
        if (res.code == 0) {
          let devs = [];
          for (let pp of res.data) {
            devs.push({ id: pp.id, pid: pp.pid, flag: pp.flag, pname: pp.name, devId: pp.deviceId, channel: pp.channel, cr: pp.rate, cl: pp.low, ch: pp.high, cv: pp.val, diff:(pp.val - pp.rate).toFixed(2), ct: pp.rated, op: pp.output, sname: pp.stationName, cmdId: 0, cmd: '', name: '', args: {}, content: {}, status: 0, result: '', message: '' });
          }
          this.$set(this, 'originalDevices', devs);
          this.$set(this, 'devices', devs);
          this.calcSuccessRate();
          // this.dataRefresh++
          // this.$refs.chkTable.toggleAllSelection()
        }
      });
    },
    cmdAuthUserChecked: function(params){
      // console.log('auth user check result', params, this.checkAuthModalSuccessFunction);
      if(this.checkAuthModalSuccessFunction){
        this.checkAuthModalSuccessFunction(params);
      }
    },
    checkCmdAuth: function(type, cmdCode, checkOkFun){
      this.$axios.post(`//${this.domains.trans}/sys/setting/CheckCmdAuth`, {type, code: cmdCode}).then(res => {
        this.showArgModal = false;
        if(res.code != 0)return;
        if(!res.data.need){
          checkOkFun({userId: 0});
          return;
        }
        this.checkAuthModalSuccessFunction = checkOkFun;
        this.checkAuthModalUsers = res.data.users;
        this.showCheckAuthModal = true;
      });
    },
    clickCmd: function (params) {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要控制的通道');
        return;
      }
      if (params.code == 'setCurrentRate') { //设置倍率
        this.cmdItem = { paramsCode: params.code, code: 'setCurrentRate', name: params.name, pswd: params.needPswd, args: { currentRate: 1000 } }
        this.showArgModal = true;
        return;
      } else if (params.code == 'setCurrentRange') { //设置报警上下限
        this.cmdItem = { paramsCode: params.code, code: 'setCurrentRange', name: params.name, pswd: params.needPswd, args: { currentRate: 1000 } }
        this.showArgModal = true;
        return;
      } else {
        this.checkCmdAuth(this.cmdType, params.code, (checkResult) => {
          if (this.clearOldLog) {
            for (let item of this.devices) {
              item.result = '';
              item.status = 0;
            }
          }
          let cmds = []
          let ds = {};
          for (let chk of chks) {
            if (chk.topId == 0) {
              ds[chk.sname] = chk.devId;
            } else {
              ds[chk.sname] = chk.topId;
            }
            // let args = { channel: chk.channel };
            // chk.result = this.showLang('com.lighting.repeat.preparation');
            // chk.status = 0;
            // chk.cmd = params.code;
            // chk.name = params.name;
            // chk.args = args;
            // chk.message = this.getCmdStatus(chk);
            // cmds.push({ id: chk.id, code: params.code, name: params.name, deviceId: chk.devId, checkUserId: checkResult.userId, args: args });
            cmds.push({id:chk.pid, flag: chk.flag});
          }
          let cmd = {
            code: params.code,
            list: cmds,
            checkUserId: checkResult.userId,
            args: params.args
          };
          this.sendCommand(cmd);
        });
      }
    },
    argsSaved: function (params) {
      this.checkCmdAuth(this.cmdType, params.paramsCode, (checkResult) => {
        if (this.clearOldLog) {
          for (let item of this.devices) {
            item.result = '';
            item.status = 0;
          }
        }
        let chks = this.$refs.chkTable.getCheckboxRecords();
        let cmds = []
        let ds = {};
        for (let chk of chks) {
          ds[chk.sname] = chk.topId;
          // chk.result = '';
          // chk.status = 0;
          // let args = {};
          // if (params.code == 'setCurrentRate') {
          //   args = { channel: chk.channel, k: chk.ct, output: chk.op };
          // } else if (params.code == 'setCurrentRange') {
          //   args = { channel: chk.channel, low: chk.cl, high: chk.ch };
          // }
          // chk.result = this.showLang('com.lighting.repeat.preparation');
          // chk.status = 0;
          // chk.cmd = params.code;
          // chk.name = params.name;
          // chk.args = args;
          // chk.message = this.getCmdStatus(chk);
          // // console.log('get chk args', chk);
          // cmds.push({ id: chk.id, code: params.code, name: params.name, deviceId: chk.devId, checkUserId: checkResult.userId, args: args }); // params.args.k
          // cmds.push(chk.id);
          cmds.push({id:chk.pid, flag: chk.flag});
        }
        let cmd = {
          code: params.code,
          list: cmds,
          checkUserId: checkResult.userId,
          args: params.args
        };
        this.sendCommand(cmd);
      });
    },
    getCmdStatus: function (cmd) {
      // if(cmd.status == 9 || cmd.status == 4) 
      console.log('cmd status changed', cmd)
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 2: return this.showLang('cmd.has.sent')+this.showLang('com.ins.dev.reply');
        case 3: return cmd.result;
        case 4: return this.showLang('com.ins.execution')+`：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return this.showLang('com.ins.fail.retry')+`：${cmd.result}`;
        case 7: return this.showLang('cmd.has.timeout');
        case 8: return this.showLang('com.ins.fail')+`：${cmd.result}`;
        case 9: {
          switch (cmd.cmd) {
            case 'getCurrentRate': {
              let re = this.showLang('com.ins.dev.ch.k',cmd.channel,cmd.content.k);
              if (cmd.content.output) {
                re += this.showLang('com.ins.associate.ch',cmd.content.output) ;
              }
              return re;
            }
            case 'setCurrentRate': {
              // if (!isMove) return false;
              // console.log('set cur rate', cmd)
              let re =this.showLang('com.ins.dev.ch.set.k',cmd.channel,cmd.ct);
              if (cmd.args.output > 0) {
                re += this.showLang('com.ins.associate.ch',cmd.args.output) ;
              }
              return re;
            }
            case 'getCurrentRange': {
              return this.showLang('com.ins.dev.ch.limits',cmd.channel,cmd.content.low,cmd.content.high);
            }
            case 'setCurrentRange': {
              return this.showLang('com.ins.dev.ch.set.limits',cmd.channel,cmd.cl,cmd.ch);
            }
            case 'getYearPlan': {
              if (cmd.args.month != cmd.content.month) {
                return this.showLang('com.ins.success.WrongMonth',cmd.args.month) + cmd.content.month;
              } else {
                let strs = [];
                let obj = cmd.content[`day${cmd.args.day}`];
                for (let i = 1; i <= 4; i++) {
                  let a = this.powerTableActions[obj[`a${i}`]];
                  let t = obj[`t${i}`];
                  strs.push(`${t} - ${a}`);
                }
                return this.showLang('com.ins.success')+`：${strs.join(', ')}`
              }
            }
            default: return this.showLang('com.ins.success');
          }
        }
      }
    },
    sendCommand: function(cmd){
      if(cmd.list.length == 0)return;
      this.$set(this, 'cmdResults', {});
      this.$axios.post(`//${this.domains.trans}/station/command/SendCurrentOld`, cmd).then(res => {
        if (res.code == 0) {
          // console.log('send cmd result', res.data, this.list);
          for (let item of res.data.list) {
            let ds = this.devices.filter(p => p.pid == item.id && p.flag == item.flag);
            // console.log('send cmd result', ds)
            if (ds.length == 0) continue;
            this.$set(ds[0], 'cmd', cmd.code);
            this.$set(ds[0], 'args', cmd.args);
            this.$set(ds[0], 'cmdId', item.success ? item.message : '');
            this.$set(ds[0], 'status', item.success ? 1 : 8);
            this.$set(ds[0], 'content', {});
            this.$set(ds[0], 'message', item.success ? '指令已进入发送队列' : item.message);
            // this.spanData();
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px red; */
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>