<template>
  <div class="station-alarm-container">
    <div class="alarm-data-query">
      <Input type="text" v-model="filter" search enter-button :placeholder="showLang('save.name.id')"
        @on-search="initList" style="width: 200px;margin-right: 5px;"></Input>
      <template v-for="(cmd, idx) in canDoCmds">
        <AuthButton v-if="idx < cmdcnt" :key="idx" :opCode="cmd.opcode" size="default" style="margin-right: 5px"
          @click="clickCmd(cmd)">{{showLang(cmd.lang)}}
        </AuthButton>
      </template>
      <Dropdown style="margin-right: 10px" v-if="canDoCmds.length > cmdcnt">
        <Button type="primary">
          {{showLang('com.op.other')}}<Icon type="ios-arrow-down"></Icon>
        </Button>
        <div slot="list" class="btns-flex" style="width: 500px">
          <template v-for="(cmd, idx) in canDoCmds">
            <AuthButton v-if="idx >= cmdcnt" :key="idx" :opCode="cmd.opcode" size="default" style="margin: 5px"
              @click="clickCmd(cmd)">{{showLang(cmd.lang)}}
            </AuthButton>
          </template>
        </div>
      </Dropdown>
      <Button type="primary" @click="exportStateData" style="margin-right: 5px"> {{showLang('com.op.export')}}</Button>
      <Button type="success" size="default" style="margin-right: 5px"
        @click="sendFailed">{{showLang('com.but.failed.resend')}}</Button>
      <Button type="success" size="default" style="margin-right: 5px"
        @click="exportResult">{{showLang('com.op.export.results')}}</Button>
      <Button type="success" size="default" style="margin-right: 5px"
        @click="viewForceReadResult">{{showLang('cmd.force.read.result.view')}}</Button>
      <Checkbox v-model="clearOldLog">{{showLang('com.lamp.is.ins')}}</Checkbox>
      <Checkbox v-model="isScreenSelected">{{showLang('com.lamp.is.screen')}}</Checkbox>
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" @checkbox-change="selectChangeEvent" :data="devices" :height="tabHeight"
        :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}">
        <vxe-column type="checkbox" field="checkbox" width="60" sortable></vxe-column>
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="dname" :title="showLang('com.export.cn.type')" width="250" sortable header-align="center">
        </vxe-column>
        <vxe-column field="dcode" :title="showLang('com.ins.last.id')" width="250" sortable header-align="center">
        </vxe-column>
        <vxe-column field="sname" :title="showLang('com.site.name')" :filters="[{ data: '' }]"
          :filter-method="filterStationMethod" width="250" sortable header-align="center">
          <template #filter="{ $panel, column }">
            <input type="type" v-for="(option, index) in column.filters" :key="index" v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)">
          </template>
        </vxe-column>
        <vxe-column field="message" :title="showLang('com.ins.result')" header-align="center" sortable></vxe-column>
      </vxe-table>
    </div>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
    <ModalForceRead v-model="showForceReadDrawer" />
    <ModalCheckAuth v-model="showCheckAuthModal" :users="checkAuthModalUsers" @checked="cmdAuthUserChecked" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalCheckAuth from './ModalCheckAuth'
import ModalArgEdit from './ModalArgEdit'
import ModalForceRead from './ModalForceRead'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'CommandDms8088Index',
  components: {
    ModalArgEdit,
    ModalForceRead,
    ModalCheckAuth,
  },
  props: {
  },
  data() {
    return {
      showArgModal: false,
      tabHeight: 200,
      clearOldLog: this.clearOldLogs,
      cmdType: 'powerbox',
      showCheckAuthModal: false,
      checkAuthModalSuccessFunction: null,
      checkAuthModalUsers: [],
      cmdcnt: 5,
      items: [
        { needArgs: false, needPswd: false, code: 'forceRead', opcode: 'dcs', name: '召测数据', lang: 'com.but.survey', img: this.img.light.road.single.ctrl.forceRead },
        { needArgs: false, needPswd: false, code: 'getclock', opcode: 'dcs', name: '读取时钟', lang: 'com.but.get.read.clock', img: this.img.light.road.single.ctrl.readClock },
        { needArgs: false, needPswd: true, code: 'setclock', opcode: 'dcc', name: '校准时钟', lang: 'com.but.get.Calibrate', img: this.img.light.road.single.ctrl.writeClock },
        { needArgs: false, needPswd: false, code: 'getInfo', opcode: 'dcs', name: '设备信息', lang: 'com.but.get.deviceInfo', img: this.img.light.road.single.ctrl.info },
        { needArgs: false, needPswd: false, code: 'getSimInfo', opcode: 'dcs', name: '卡信息', lang: 'com.but.card.info', img: this.img.light.road.single.ctrl.info },
        { needArgs: false, needPswd: false, code: 'getVoltageRange', opcode: 'dcs', name: '查询电压报警上下限', lang: 'com.but.get.v.upper.lower', img: this.img.light.road.single.ctrl.info },
        { needArgs: true, needPswd: true, code: 'setVoltageRange', opcode: 'dcc', name: '设置电压报警上下限', lang: 'com.but.set.v.upper.lower', img: this.img.light.road.single.ctrl.info },
        { needArgs: true, needPswd: false, code: 'getComArgs', opcode: 'dcs', name: '查询串口参数', lang: 'com.but.get.serial.port', img: this.img.light.road.single.ctrl.info },
        { needArgs: true, needPswd: true, code: 'setComArgs', opcode: 'dcc', name: '设置串口参数', lang: 'com.but.set.serial.port', img: this.img.light.road.single.ctrl.info },
        { needArgs: false, needPswd: false, code: 'getNetwork', opcode: 'dcs', name: '查询工作网络', lang: 'com.but.get.work.network', img: this.img.light.road.single.ctrl.info },
        { needArgs: true, needPswd: true, code: 'setNetwork', opcode: 'dck', name: '设置工作网络', lang: 'com.but.set.work.network', img: this.img.light.road.single.ctrl.info },
        { needArgs: false, needPswd: false, code: 'getDefaultNetwork', opcode: 'dcs', name: '查询默认网络', lang: 'com.but.get.default.network', img: this.img.light.road.single.ctrl.info },
        { needArgs: true, needPswd: true, code: 'setDefaultNetwork', opcode: 'dck', name: '设置默认网络', lang: 'com.but.set.default.network', img: this.img.light.road.single.ctrl.info },
        { needArgs: false, needPswd: true, code: 'reset', opcode: 'dco', name: '重启设备', lang: 'com.but.set.reboot', img: this.img.light.road.single.ctrl.restart },
        { needArgs: false, needPswd: true, code: 'init', opcode: 'dck', name: '初始化', lang: 'com.but.initialization', img: this.img.light.road.single.ctrl.init },
        // {needArgs: false, needPswd: false, code: 'capture', name: '抓拍照片',lang:'', img: this.img.light.road.single.ctrl.capture},
        // {needArgs: false, needPswd: false, code: 'viewImage', name: '查看照片',lang:'', img: this.img.light.road.single.ctrl.viewImage},
        // {needArgs: false, needPswd: false, code: 'viewVideo', name: '视频预览',lang:'', img: this.img.light.road.single.ctrl.viewVideo},
        // {needArgs: false, needPswd: false, code: 'playVideo', name: '视频回放',lang:'', img: this.img.light.road.single.ctrl.playVideo},
        { needArgs: true, needPswd: true, code: 'update', opcode: 'dck', name: 'OTA升级', lang: 'com.but.ota', img: this.img.light.road.single.ctrl.update },
        { needArgs: true, needPswd: true, code: 'ftp', opcode: 'dck', name: '设置FTP升级参数', lang: 'com.but.ftp', img: this.img.light.road.single.ctrl.update },
        { needArgs: true, needPswd: false, code: 'getCmdCount', opcode: 'dcs', name: '查询指令数量', flag: 0, lang: 'com.but.get.ins.num', img: this.img.light.factory.power.devOps.devOps14 },
        { needArgs: true, needPswd: false, code: 'clearCmds', opcode: 'dcs', name: '清除指令队列', flag: 0, lang: 'com.but.set.ins.clean', img: this.img.light.factory.power.devOps.devOps15 },
        // { needArgs: true, needPswd: true, code: 'setEventEnable', name: '设置终端事件记录配置',lang:'', img: this.img.light.road.single.ctrl.info },
        // { needArgs: false, needPswd: false, code: 'getEventEnable', name: '查询终端事件记录配置',lang:'', img: this.img.light.road.single.ctrl.info },
      ],
      filter: '',
      devices: [],
      originalDevices: [],
      isScreenSelected: false,
      cmdItem: { item: {}, other: {}, args: {} },
      arrCmds: [],
      cmdResults: {},
      timer: null,
      selectedCount: 0,
      showForceReadDrawer: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'coms8088names', 'com8088types', 'com8088buads', 'com8088datas', 'com8088paritys', 'com8088stops']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    canDoCmds: function () {
      let cmds = [];
      for (let cmd of this.items) {
        if (this.config.powerBoxShowCommands.indexOf(cmd.code) == -1) continue;
        if (this.funCodes(cmd.opcode)) cmds.push(cmd);
      }
      return cmds;
    }
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
    isScreenSelected() {
      this.screenSelected();
    },
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.initList();
    },
  },
  mounted: function () {
    this.clearOldLog = this.clearOldLogs;
    setTimeout(this.setTableHeight, 100);
    this.initList();
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      // this.cmdResults[params.cmdId] = params;
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    });
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    exportStateData: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.control.terminal'));
        return;
      }
      let lids = [];
      chks.map(p => {
        if(lids.indexOf(p.id) == -1)lids.push(p.id);
      });
      console.log('select rows', chks, lids)
      this.exportLoading = true;
      this.$store.dispatch('auth/reqFile', {
        title: '站点状态数据.xlsx',
        url: `//${this.domains.trans}/station/config/ExportSelectedMainState`,
        args: {ids: lids},
      }).then(() => {
        // this.exportLoading = false;
      });
    },
    screenSelected() {
      if (this.isScreenSelected) {
        this.devices = this.devices.filter(p => p.checked);
      } else {
        this.devices = this.originalDevices;
      }
    },
    viewForceReadResult: function () {
      this.showForceReadDrawer = true;
    },
    filterStationMethod({ option, row }) {
      return row.sname.indexOf(option.data) != -1;
    },
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let ds = this.devices.filter(p => p.cmdId == cmdId);
        if (ds.length == 0) {
          if(new Date().getTime() - this.cmdResults[cmdId].time > 100000){
            delete this.cmdResults[cmdId];
          }
          continue;
        } else {
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], 'status', item.status);
          this.$set(ds[0], 'result', item.message);
          this.$set(ds[0], 'content', item.content);
          this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
      this.calcSuccessRate();
    },
    calcSuccessRate: function () {
      let total = this.devices.length;
      let success = this.devices.filter(p => p.status == 9).length;
      let rate = 0;
      if (total <= 0) {
        rate = 0;
      } else {
        rate = (success * 100 / total).toFixed(2);
      }
      window.eventBus.$emit('commandSuccessRateChanged', { total, success, rate });
      // console.log('cmd result rate changed', total, success, rate)
    },
    getCmdCount: function (ids) {
      this.$axios.post(`//${this.domains.trans}/station/command/QueryPowerboxCountOld`, { ids }).then(res => {
        if (res.code == 0) {
          console.log('get cmd cnt', this.devices, res.data)
          for (let devId in res.data) {
            let ds = this.devices.filter(p => p.id == devId);
            if (ds.length == 0) continue;
            this.$set(ds[0], 'cmd', 'getCmdCount');
            this.$set(ds[0], 'status', 9);
            this.$set(ds[0], 'result', res.data[devId]);
            this.$set(ds[0], 'message', this.showLang('com.ins.get.result') + `：${res.data[devId]}`);
          }
        }
      })
    },
    clearCmds: async function (ids) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.ins.clean.ins.dev'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/command/ClearPowerboxQueueOld`, { ids }).then(res => {
            if (res.code == 0) {
              for (let devId in res.data) {
                let ds = this.devices.filter(p => p.id == devId);
                if (ds.length == 0) continue;
                this.$set(ds[0], 'cmd', 'clearCmds');
                this.$set(ds[0], 'status', 9);
                this.$set(ds[0], 'result', res.data[devId]);
                this.$set(ds[0], 'message', `${res.data[devId]}`);
              }
            }
          })
        },
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
      let chks = this.$refs.chkTable.getCheckboxRecords();
      this.selectedCount = chks.length;
    },
    exportResult: function () {
      let cmdData = [];
      this.devices.map(item => {
        cmdData.push({
          dname: item.dname,
          dcode: item.dcode,
          sname: item.sname,
          result: this.getCmdStatus(item)
        })
      });
      let tabHead = {
        dname: this.showLang('com.export.cn.type'),
        dcode: this.showLang('com.export.cn.connid'),
        sname: this.showLang('com.site.name'),
        result: this.showLang('com.ins.result')
      }
      exportXlsx(cmdData, tabHead, this.showLang('com.ins.result'));
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 5;
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let cmds = [];
      let code = '';
      let args = {};
      for (let d of this.devices) {
        if ((d.status == 7 || d.status == 3 || d.status == 8) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', this.showLang('com.lighting.repeat.preparation'))
          this.$set(d, 'message', this.getCmdStatus(d))
          // cmds.push({ id: d.id, code: d.cmd, name: d.name, deviceId: d.devId, args: d.args });
          cmds.push(d.id);
          code = d.cmd;
          args = d.args;
        } else if (this.clearOldLog) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '')
        }
      }
      let cmd = {
        code: code,
        list: cmds,
        checkUserId: 0,
        args: args
      };
      setTimeout(() => {
        this.sendCommand(cmd);
      }, 500)
    },
    initList: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryMain`, { groupId, stationId, name: this.filter }).then(res => {
        if (res.code == 0) {
          let devs = [];
          for (let p of res.data) {
            devs.push({ id: p.id, dname: p.deviceTypeName, dcode: p.deviceCode, devId: p.deviceId, sname: p.stationName, cmdId: 0, cmd: '', name: '', args: {}, content: {}, time: 0, status: 0, result: '', message: '' })
          }
          this.$set(this, 'originalDevices', devs);
          this.$set(this, 'devices', devs);
          this.calcSuccessRate();
        }
      });
    },
    cmdAuthUserChecked: function(params){
      // console.log('auth user check result', params, this.checkAuthModalSuccessFunction);
      if(this.checkAuthModalSuccessFunction){
        this.checkAuthModalSuccessFunction(params);
      }
    },
    checkCmdAuth: function(type, cmdCode, checkOkFun){
      this.$axios.post(`//${this.domains.trans}/sys/setting/CheckCmdAuth`, {type, code: cmdCode}).then(res => {
        if(res.code != 0)return;
        if(!res.data.need){
          checkOkFun({userId: 0});
          return;
        }
        this.checkAuthModalSuccessFunction = checkOkFun;
        this.checkAuthModalUsers = res.data.users;
        this.showCheckAuthModal = true;
      });
    },
    clickCmd: function (params) {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.control.terminal'));
        return;
      }
      if (params.code == 'getCmdCount') {
        let ids = chks.map(p => p.id);
        this.getCmdCount(ids);
        return;
      } else if (params.code == 'clearCmds') {
        let ids = chks.map(p => p.id);
        this.clearCmds(ids);
        return;
      } else if (params.code == 'setclock') {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
        return;
      } else if (params.code == 'setNetwork' || params.code == 'setDefaultNetwork') {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
      } else if (params.code == 'setVoltageRange') {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
      } else if (params.code == 'update') {
        this.cmdItem = { paramsCode: params.code, code: 'update', name: params.name, pswd: params.needPswd, args: {} }
        this.showArgModal = true;
        return;
      } else if (params.code == 'ftp') {
        this.cmdItem = { paramsCode: params.code, code: 'ftp', name: params.name, pswd: params.needPswd, args: {} }
        this.showArgModal = true;
        return;
      } else if (params.code == 'reset') {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
        return;
      } else if (params.code == 'init') {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
        return;
      } else if (params.code == 'setComArgs') {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
        return;
      } else if (params.code == 'getComArgs') {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
        return;
      } else {
        this.checkCmdAuth(this.cmdType, params.code, (checkResult) => {
          // console.log('check result ok', checkResult);
          if (this.clearOldLog) {
            for (let item of this.devices) {
              item.result = '';
              item.status = 0;
            }
          }
          let cmds = []
          let ds = {};
          for (let chk of chks) {
            if (chk.topId == 0) {
              ds[chk.sname] = chk.devId;
            } else {
              ds[chk.sname] = chk.topId;
            }
            // chk.result = this.showLang('com.lighting.ready.sending');
            // chk.status = 0;
            // chk.cmd = params.code;
            // chk.name = params.name;
            // chk.args = {};
            // chk.message = this.getCmdStatus(chk);
            // cmds.push({ id: chk.id, code: params.code, name: params.name, deviceId: chk.devId, checkUserId: checkResult.userId, args: {} });
            cmds.push(chk.id);
          }
          let cmd = {
            code: params.code,
            list: cmds,
            checkUserId: checkResult.userId,
            args: params.args
          };
          this.sendCommand(cmd);
        });
      }
    },
    argsSaved: function (params) {
      this.checkCmdAuth(this.cmdType, params.paramsCode, (checkResult) => {
        this.showArgModal = false;
        if (this.clearOldLog) {
          for (let item of this.devices) {
            item.result = '';
            item.status = 0;
          }
        }
        let chks = this.$refs.chkTable.getCheckboxRecords();
        let cmds = []
        let ds = {};
        let ns = [];
        for (let chk of chks) {
          ns.push(chk.dcode);
          ds[chk.sname] = chk.topId;
          // chk.result = '';
          // chk.status = 0;
          // let args = this.clone(params.args);
          // if (params.code == 'handControl') {
          //   args.point = false;
          // } else if (params.code == 'setYearPlan') {
          //   args.timeId = chk.timeId;
          // }
          // chk.result = this.showLang('com.lighting.ready.sending');
          // chk.status = 0;
          // chk.cmd = params.code;
          // chk.name = params.name;
          // chk.args = args;
          // chk.message = this.getCmdStatus(chk);
          // cmds.push({ id: chk.id, code: params.code, name: params.name, deviceId: chk.devId, checkUserId: checkResult.userId, args: args });
          cmds.push(chk.id);
        }
        let cmd = {
          code: params.code,
          list: cmds,
          checkUserId: checkResult.userId,
          args: params.args
        };
        if (params.code == 'update') {
          this.$Modal.confirm({
            title: this.showLang('com.op.prompt'),
            content: this.showLang('com.ins.upgrade.device', ns),
            onOk: async () => {
              this.sendCommand(cmd);
            },
          });
        } else {
          this.sendCommand(cmd);
        }
      });
    },
    getCmdStatus: function (cmd) {
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 2: return this.showLang('cmd.has.sent') + this.showLang('com.ins.dev.reply');
        case 3: return cmd.result;
        case 4: return this.showLang('com.ins.execution') + `：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return this.showLang('com.ins.fail.retry') + `：${cmd.result}`;
        case 7: return this.showLang('cmd.has.timeout');
        case 8: return this.showLang('com.ins.fail') + `：${cmd.result}`;
        case 9:
          // console.log('cmd status', cmd)
          switch (cmd.cmd) {
            case 'clearCmds': return this.showLang('com.but.set.ins.clean') + `：${cmd.result}`;
            case 'getCmdCount': return this.showLang('com.ins.queues.num') + `：${cmd.result}`;
            case 'getclock': return this.showLang('com.ins.success') + `：${cmd.content.nowTime}`;
            case 'setclock': return this.showLang('com.ins.success') + `：${cmd.content.nowTime}`;
            case 'getInfo': return `${this.showLang('com.site.hw')}：${cmd.content.hardware}，${this.showLang('com.site.sw')}：${cmd.content.software}，${this.showLang('com.user.manufacturer')}：${cmd.content.manufacture}`;
            case 'getSimInfo': return `ICCID：${cmd.content.iccid}，IMEI：${cmd.content.imei}，RSSI：${cmd.content.rssi}`;
            case 'getNetwork': return this.showLang('cmd.result.get.net.work', cmd.content.host, cmd.content.port, cmd.content.heart || '-', cmd.content.host1 || '-', cmd.content.port1 || '-', cmd.content.apn || '-', cmd.content.user || '-', cmd.content.pswd || '-');// `IP:${cmd.content.host}, ${this.showLang('com.ins.port')}:${cmd.content.port}, ${this.showLang('com.ins.heartbeat')}:${cmd.content.heart}s`;
            case 'getComArgs': return `${this.showLang('com.ins.success')}：${this.coms8088names[cmd.content.com]}${this.showLang('com.comm.method')}：${cmd.content.type}，${this.showLang('com.BAUD')}：${cmd.content.buad}，${this.showLang('com.data.bits')}：${cmd.content.data}，${this.showLang('com.check.bit')}：${cmd.content.parity}，${this.showLang('com.stop.bit')}：${cmd.content.stop}`;
            case 'getVoltageRange': return `${this.showLang('cmd.project.A')}：${this.showLang('com.data.upper.val')}${cmd.content.high1}V，${this.showLang('com.data.lower.val')}${cmd.content.low1}V；${this.showLang('cmd.project.B')}：${this.showLang('com.data.upper.val')}${cmd.content.high2}V，${this.showLang('com.data.lower.val')}${cmd.content.low2}V；${this.showLang('cmd.project.C')}：${this.showLang('com.data.upper.val')}${cmd.content.high3}V，${this.showLang('com.data.lower.val')}${cmd.content.low3}V；`;
            default: return this.showLang('com.ins.success');
          }
      }
    },
    sendCommand: function(cmd){
      if(cmd.list.length == 0)return;
      this.$set(this, 'cmdResults', {});
      this.$axios.post(`//${this.domains.trans}/station/command/SendPowerboxOld`, cmd).then(res => {
        if (res.code == 0) {
          // console.log('send cmd result', res.data, this.list);
          for (let item of res.data.list) {
            let ds = this.devices.filter(p => p.id == item.id);
            // console.log('send cmd result', ds)
            if (ds.length == 0) continue;
            this.$set(ds[0], 'cmd', cmd.code);
            this.$set(ds[0], 'args', cmd.args);
            this.$set(ds[0], 'cmdId', item.success ? item.message : '');
            this.$set(ds[0], 'status', item.success ? 1 : 8);
            this.$set(ds[0], 'content', {});
            this.$set(ds[0], 'message', item.success ? '指令已进入发送队列' : item.message);
            // this.spanData();
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>