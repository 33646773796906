<template>
  <Modal v-model="showModal" :mask-closable="false" width="1320px">
    <div slot="header">{{showLang('com.edit.box.schedule')}}</div>
    <div class="total-container">
      <Form :model="form" :label-width="40" inline style="margin-left: 20px">
        <FormItem :label="showLang('com.tab.title')">
          <Input ref="name" type="text" v-model="form.name" maxlength="20" :placeholder="showLang('save.name')"></Input>
        </FormItem>
        <FormItem :label-width="0">
          <Button type="primary" v-if="form.type !== '0'" @click="editDayAction(1)">{{showLang('timetable.batch.modify')}}</Button>
        </FormItem>
      </Form>
      <div>
        <Button v-for="(item, idx) in months" :key="idx" :type="midx == idx ? 'primary':'default'" style="margin: 3px 4px; width: 71px"
          @click="switchMonth(idx)">{{showLang(item.lang)}}</Button>
      </div>
      <div class="time-container">
        <div class="time-item" v-for="m in 31" :key="m" @click="editDayAction(0, m, midx)">
          <div class="time-title">{{m}}</div>
          <p v-for="n in 6" :key="n">{{n}}、{{getAction(midx+1, m, n)}}</p>
        </div>
      </div>
      <ModalDayAction v-model="showDayModal" :item="dayEditItem" :actions="actionTypes" @dayActionSaved="dayActionSaved" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="saveTimeTable">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalDayAction from './ModalDayAction'
export default {
  name: 'ModalTimeTable',
  components: {
    ModalDayAction,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showDayModal: false,
      gpsEdit: true,
      months: [
        { name: '一月', lang: 'com.date.Jan' },
        { name: '二月', lang: 'com.date.Feb' },
        { name: '三月', lang: 'com.date.Mar' },
        { name: '四月', lang: 'com.date.Apr' },
        { name: '五月', lang: 'com.date.May' },
        { name: '六月', lang: 'com.date.Jun' },
        { name: '七月', lang: 'com.date.Jul' },
        { name: '八月', lang: 'com.date.Aug' },
        { name: '九月', lang: 'com.date.Sep' },
        { name: '十月', lang: 'com.date.Oct' },
        { name: '十一月', lang: 'com.date.Nov' },
        { name: '十二月', lang: 'com.date.Dec' }],
      weeks: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      actionTypes: {
        0: '定时模式', //'恒功率',
        1: '传感器', //'联动',
        2: '混合模式', //'恒照度'
      },
      midx: 0,
      days: 31,
      week: 0,
      showModal: this.value,
      isAdd: false,
      index: -1,
      loading: false,
      form: {
        id: 0,
        name: '',
        type: 1,
        content: {
          1: {
            1: { t1: '00:00', a1: 2, v1: 100, t2: '00:00', a2: 2, v2: 100, t3: '00:00', a3: 2, v3: 100, t4: '00:00', a4: 2, v4: 100, t5: '00:00', a5: 2, v5: 100, t6: '00:00', a6: 2, v6: 100, },
            2: { t1: '00:00', a1: 2, v1: 100, t2: '00:00', a2: 2, v2: 100, t3: '00:00', a3: 2, v3: 100, t4: '00:00', a4: 2, v4: 100, t5: '00:00', a5: 2, v5: 100, t6: '00:00', a6: 2, v6: 100, },
            //...
          },
          2: {},
          //...
        },
      },
      dayEditItem: {},
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.isAdd = this.item.isAdd;
        if (this.isAdd) {
          this.form.id = 0;
          this.form.name = '';
          this.form.type = 1;
          this.form.contentDay = {};
          for (let i = 1; i <= 12; i++) {
            this.form.content[i] = {};
            for (let j = 1; j <= 31; j++) {
              this.form.content[i][j] = {
                a1: 2,
                t1: '00:00',
                v1: 100,
                a2: 2,
                t2: '00:00',
                v2: 100,
                a3: 2,
                t3: '00:00',
                v3: 100,
                a4: 2,
                t4: '00:00',
                v4: 100,
                a5: 2,
                t5: '00:00',
                v5: 100,
                a6: 2,
                t6: '00:00',
                v6: 100,
              }
            }
          }
        } else {
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.type = this.item.data.type;
          this.getTableContent();
        }
        let now = new Date();
        let month = now.getMonth();
        this.switchMonth(month);
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
    // this.getTables();
    this.weeks = [this.showLang('com.date.mon'), this.showLang('com.date.tues'), this.showLang('com.date.wed'), this.showLang('com.date.thurs'), this.showLang('com.date.fri'), this.showLang('com.date.sat'), this.showLang('com.date.sunday')];
    this.actionTypes = {
      0: this.showLang('timetable.constant.power'),
      1: this.showLang('timetable.linkage'),
      2: this.showLang('timetable.constant.illuminance')
    }
  },
  computed: {
    ...mapState('common', ['mapCenter']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  methods: {
    saveTimeTable: function () {
      if (!this.form.name || this.form.name.length > 20) {
        this.$Message.warning(this.showLang('com.save.err.len.name', 20));
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/plan/SaveMonitorDetail`, this.form).then(res => {
        if (res.code == 0) {
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    dayActionSaved: function (params) {
      // // 0、单日年表，1、批量年表，2、单日周表
      if (params.type == 0) {
        for (let i = 1; i <= 6; i++) {
          this.form.content[params.month + 1][params.day][`t${i}`] = params.action[`t${i}`];
          this.form.content[params.month + 1][params.day][`a${i}`] = params.action[`a${i}`];
          this.form.content[params.month + 1][params.day][`v${i}`] = params.action[`v${i}`];
        }
      } else {
        let start = new Date(params.action.start);
        let end = new Date(params.action.end);
        let startMonth = start.getMonth(), startDay = start.getDate();
        let endMonth = end.getMonth(), endDay = end.getDate();
        // let weekEnables = params.action.weekEnables;
        // let year = new Date().getFullYear();
        let i = startMonth, j = startDay;
        while (params.type == 1) {
          // let day = new Date(`${year}-${i+1}-${j}`);
          // let week = day.getDay();
          // let wn = this.weeks[week];
          // if(weekEnables.indexOf(wn) == -1){
          //   j++;
          //   if(j > 31){
          //     j = 1;
          //     i++;
          //   }
          //   continue;
          // }
          let action = this.form.content[i + 1][j];
          for (let k = 1; k <= 6; k++) {
            if (!params.action[`c${k}`]) continue;
            action[`t${k}`] = params.action[`t${k}`];
            action[`a${k}`] = params.action[`a${k}`];
            action[`v${k}`] = params.action[`v${k}`];
          }
          j++;
          if (i * 31 + j > endMonth * 31 + endDay) break;
          if (j > 31) {
            j = 1;
            i++;
          }
        }
      }
    },
    delayTime: function (time, delay) {//time: 05:34, delay: 5 | -5
      let ts = time.split(':');
      let h = parseInt(ts[0]), m = parseInt(ts[1]);
      let t = h * 60 + m + delay;
      let h1 = parseInt(t / 60), m1 = t - h1 * 60;
      let hs = `${h1}`.padStart(2, '0');
      let ms = `${m1}`.padStart(2, '0');
      return `${hs}:${ms}`;
    },
    editDayAction: function (type, day, month) {
      // type: 0、单日年表，1、批量年表，
      // console.log('edit day action', type, day, month, this.form.content)
      let action;
      if (type == 0) {
        let act = this.form.content[month + 1][day];
        action = {
          a1: act.a1,
          t1: act.t1,
          v1: act.v1,
          c1: true,
          a2: act.a2,
          t2: act.t2,
          v2: act.v2,
          c2: true,
          a3: act.a3,
          t3: act.t3,
          v3: act.v3,
          c3: true,
          a4: act.a4,
          t4: act.t4,
          v4: act.v4,
          c4: true,
          a5: act.a5,
          t5: act.t5,
          v5: act.v5,
          c5: true,
          a6: act.a6,
          t6: act.t6,
          v6: act.v6,
          c6: true,
        };
      } else {
        action = {
          start: '01-01',
          end: '12-31',
          weekEnables: [this.showLang('com.date.mon'), this.showLang('com.date.tues'), this.showLang('com.date.wed'), this.showLang('com.date.thurs'), this.showLang('com.date.fri'), this.showLang('com.date.sat'), this.showLang('com.date.sunday')],
          a1: 0,
          t1: '00:00',
          v1: 100,
          c1: true,
          a2: 0,
          t2: '00:00',
          v2: 100,
          c2: true,
          a3: 0,
          t3: '00:00',
          v3: 100,
          c3: false,
          a4: 0,
          t4: '00:00',
          v4: 100,
          c4: false,
          a5: 0,
          t5: '00:00',
          v5: 100,
          c5: false,
          a6: 0,
          t6: '00:00',
          v6: 100,
          c6: false,
        };
      }
      this.dayEditItem = { type: type, day: day, month: month, action: action };
      this.showDayModal = true;
    },
    getAction: function (month, day, idx) {
      let m = this.form.content[month];
      if (!m) return '00:00 ' + this.showLang('com.state.invalid');
      let d = m[day];
      if (!d) return '00:00 ' + this.showLang('com.state.invalid');
      let a = d['a' + idx];
      if (a == 1) return `${d['t' + idx]} ${this.actionTypes[a]}`;
      return `${d['t' + idx]} ${this.actionTypes[a]} ${d['v' + idx]} ${a == 0 ? '%' : 'lux'}`;
    },
    switchMonth: function (idx) {
      this.midx = idx;
      let now = new Date();
      let year = now.getFullYear();
      let firstDate = new Date(`${year}-${idx + 1}-1`);
      this.week = firstDate.getDay();
      if (this.week == 0) {
        this.week = 7;
      }
      firstDate.setMonth(firstDate.getMonth() + 1);
      firstDate.setDate(firstDate.getDate() - 1);
      this.days = firstDate.getDate();
      // this.getTableContent();
    },
    getTableContent: function () {
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryMonitorDetail`, { id: this.form.id }).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          return;
        }
        this.form.type = res.data.type;
        this.form.name = res.data.name;
        if (!res.data.content[12]) {
          res.data.content = {};
          for (let i = 1; i <= 12; i++) {
            res.data.content[i] = {};
            for (let j = 1; j <= 31; j++) {
              res.data.content[i][j] = {
                a1: 2,
                t1: '00:00',
                v1: 100,
                a2: 2,
                t2: '00:00',
                v2: 100,
                a3: 2,
                t3: '00:00',
                v3: 100,
                a4: 2,
                t4: '00:00',
                v4: 100,
                a5: 2,
                t5: '00:00',
                v5: 100,
                a6: 2,
                t6: '00:00',
                v6: 100,
              }
            }
          }
        }
        this.$set(this.form, 'content', res.data.content);
      })
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.total-container {
  height: 600px;
  display: flex;
  flex-direction: column;
}
.time-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  border: solid 1px #4767aa;
  border-radius: 6px;
  overflow-y: auto;
}
.time-item {
  border: solid 1px #4767aa;
  border-radius: 3px;
  width: 200px;
  height: 180px;
  background: linear-gradient(to top, #cccccc, #ffffff);
  font-family: 微软雅黑, 宋体;
  margin: 5px;
  text-align: center;
  /* padding: 10px; */
  cursor: pointer;
  text-align: left;
  padding: 10px 20px;
}
.time-title {
  font-size: 22px;
  color: #4767aa;
  font-style: italic;
  font-weight: bold;
  text-align: center;
}
</style>