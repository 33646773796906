<template>
  <Modal v-model="showModal" :mask-closable="false" width="1350px" :styles="{top: '10px'}">
    <div slot="header">{{showLang('com.edit.lamp.info')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="130">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" :placeholder="showLang('com.device.pole.name')" style="width: 425px; margin-right: 38px;"></Input>
          {{showLang('com.state.type')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.type" style="width: 400px; margin: 0 10px;">
            <template v-for="(v, k) in newLightTypes">
              <Option :value="parseInt(k)" :key="k">{{v}}</Option>
            </template>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="stationId" :label="showLang('com.site')" style="margin-right: 15px">
        <div class="flex-row">
          <Select :placeholder="showLang('save.select')" v-model="form.stationId" @on-change="stationChanged" :disabled="!isAdd" style="width: 200px; margin-right: 10px;">
            <Option v-for="(s, idx) in stations" :key="idx" :value="s.id">{{s.name}}</Option>
          </Select>
          {{showLang('com.contact.outout.need')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.oid" style="width: 123px; margin: 0 10px;">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <template v-for="(s, idx) in outputs">
              <Option :value="s.id" :key="idx">{{s.name}}</Option>
            </template>
          </Select>
          {{showLang('com.export.cn.group.relate')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.area" style="width: 123px; margin: 0 10px;">
            <template v-for="(s, idx) in areas">
              <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
            </template>
          </Select>
          {{showLang('com.device.pole.association')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.pole" style="width: 200px; margin: 0 10px;">
            <template v-for="(s, idx) in poles">
              <Option :value="s.id" :key="idx">{{s.name}}</Option>
            </template>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="connectType" :label="showLang('com.export.cn.association')" style="margin-right: 15px">
        <div class="flex-row">
          <Select :placeholder="showLang('save.select')" v-model="form.deviceId" style="width: 425px; margin-right: 25px;" @on-change="getUseablePlan">
            <Option v-for="(dev, didx) in devices" :key="didx" :value="dev.id">{{getDeviceName(dev)}}</Option>
          </Select>
          <!-- {{showLang('com.ins.timeTable')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.timeId" style="width: 400px; margin: 0 10px;">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <template v-for="(s, idx) in timeTables">
              <Option :value="s.id" :key="idx">[{{s.mode}}][{{s.type}}]{{s.name}}</Option>
            </template>
          </Select> -->
        </div>
      </FormItem>
      <FormItem prop="env" :label="showLang('com.state.is.v.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.enu" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.content.ul" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">V</span></Input>
          <Input type="number" v-model="form.content.uh" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">V</span></Input>
        </div>
      </FormItem>
      <FormItem prop="env1" label="115B降功率电压" style="margin-right: 15px" v-if="smallTypeShow">
        <div class="flex-row">
          <Input type="number" v-model="form.content.ulr" style="width: 160px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">V</span></Input>
          是否联动：
          <i-switch v-model="uhbEnu" @on-change="uhbEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px" :key="'uhb'+uhbEnu">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input v-if="uhbEnu" type="number" v-model="form.content.uhb" style="width: 200px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">0-100(%)</span></Input>
          <Input type="number" v-model="form.content.uhr" style="width: 160px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">V</span></Input>
          <!-- uhbEnu:false, ulbEnu:false, ulbEnuChanged uhbEnuChanged-->
          是否联动：
          <i-switch v-model="ulbEnu" @on-change="ulbEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px" :key="'ulb'+uhbEnu">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input v-if="ulbEnu" type="number" v-model="form.content.ulb" style="width: 200px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">0-100(%)</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enc" :label="showLang('com.state.is.i.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.enc" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.content.cl" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">A</span></Input>
          <Input type="number" v-model="form.content.ch" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">A</span></Input>
        </div>
      </FormItem>
      <FormItem prop="ent" :label="showLang('com.state.is.t.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.ent" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.content.tl" style="width: 250px; margin: 0 5px;"><span slot="prepend">上限阈值</span><span slot="append">℃</span></Input>
          <Input type="number" v-model="form.content.th" style="width: 250px; margin: 0 5px;"><span slot="prepend">降功率阈值</span><span slot="append">℃</span></Input>
          是否联动：
          <i-switch v-model="toutEnu" @on-change="toutEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px" :key="'ulb'+uhbEnu">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-if="toutEnu" v-model="form.content.tout" style="width: 220px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">%</span></Input>
        </div>
      </FormItem>
      <FormItem prop="leac" :label="showLang('com.state.is.leac.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.enleac" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.content.leac" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.cl')}}</span><span slot="append">mA</span></Input>
          <Input type="number" v-model="form.content.leah" style="width: 250px; margin: 0 5px;"><span slot="prepend">降功率漏电上限</span><span slot="append">mA</span></Input>
          是否联动：
          <i-switch v-model="loutEnu" @on-change="loutEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px" :key="'ulb'+uhbEnu">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-if="loutEnu" v-model="form.content.lout" style="width: 220px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">%</span></Input>

        </div>
      </FormItem>
      <FormItem prop="leav" :label="showLang('com.state.is.leav.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.enleav" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.content.leav" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.lu')}}</span><span slot="append">V</span></Input>
        </div>
      </FormItem>
      <FormItem prop="engyro" :label="showLang('com.state.is.gyro.police')" style="margin-right: 15px">
        <i-switch v-model="form.content.engyro" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
          <span slot="open">{{showLang('com.state.enable')}}</span>
          <span slot="close">{{showLang('com.state.disable')}}</span>
        </i-switch>
      </FormItem>
      <template v-for="n in 4">
        <FormItem :prop="`en${n}`" :label="showLang( `com.ope.way.channel.${n}`)" style="margin-right: 15px" :key="n">
          <div class="flex-row">
            <i-switch v-model="form.content[`en${n}`]" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            <template v-if="form.content[`en${n}`]">
              <Select :placeholder="showLang('save.select')" v-model="form.content[`nm${n}`]" style="margin-right:10px;width:70px">
                <Option :value="showLang('com.lamp.host')">{{showLang('com.lamp.host')}}</Option>
                <Option :value="showLang('com.lamp.assist')">{{showLang('com.lamp.assist')}}</Option>
              </Select>
              <Input type="number" v-model="form.content[`pr${n}`]" style="width: 160px; margin-right:10px"><span slot="prepend">{{showLang('com.lighting.pr1')}}</span><span slot="append">W</span></Input>
              <Input type="number" v-model="form.content[`prl${n}`]" style="width:150px; margin: 0 5px;">
              <span slot="prepend">下限值</span>
              <span slot="append">%</span>
              </Input>
              <Input type="number" v-model="form.content[`prh${n}`]" style="width: 150px; margin: 0 5px;">
              <span slot="prepend">上限值</span>
              <span slot="append">%</span>
              </Input>
              <Input type="number" v-model="form.content[`lp${n}`]" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span><span slot="append">W</span></Input>
              <Input type="number" v-model="form.content[`lc${n}`]" style="width: 140px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
              <Input v-model="form.content[`tp${n}`]" style="width: 140px; margin-right:10px;"><span slot="prepend">{{showLang('com.import.cn.lamp.type')}}</span></Input>
            </template>
          </div>
        </FormItem>
        <FormItem :prop="`en${n}`" :label="`${n}路控制方式`" style="margin-right: 15px" :key="`en${n}`" v-if="form.content[`en${n}`]">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form.content[`mode${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in controlMode" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联计时日表：
            <Select :placeholder="showLang('save.select')" v-model="form.content[`timeId1${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in timeId1Data" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联准时日表：
            <Select :placeholder="showLang('save.select')" v-model="form.content[`timeId2${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in timeId2Data" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            <Input type="number" v-model="form.content[`pc${n}`]" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.pc')}}</span></Input>
          </div>
        </FormItem>
      </template>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalLampEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    stations: {
      type: Array,
      default() { return []; }
    },
    areas: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      accounts: [],
      poles: [],
      devices: [],
      timeTables: [],
      outputs: [],
      timeId1Data: [],
      timeId2Data: [],
      timeId7Data: [],
      timeId8Data: [],
      controlMode: [{ name: '计时日表', id: 0 }, { name: '准时日表', id: 1 }],
      smallTypeShow: false,//83=115B
      uhbEnu: false,
      ulbEnu: false,
      toutEnu: false,
      loutEnu: false,
      form: {
        id: 0,
        stationId: 0,
        // connectType: 0, //设备接入方式，0为设备直连，其他都是从三方平台或其他SDK接入
        deviceId: 0,
        code: '',
        name: '',
        // type: 199,
        content: {
          //基本配置相关
          oid: 0, //关联控制输出
          type: 101, //单灯类型
          timeId: 0, //时间表ID
          mode: 0,//控制方式
          area: 0, //单独所属控制区域
          pole: 0, //关联灯杆
          enu: false, //是否启用电压告警
          uh: 280, //电压阈值上限
          ul: 80, //电压阈值下限
          enc: false,//是否启用电流告警
          cl: 0.5, //电流阈值下限
          ch: 10, //电流阈值上限
          ent: false, //是否启用温度告警
          tl: 50,//温度阈值下限
          th: 80,//温度阈值上限
          tout: 100,////降功率调光值
          leah: 100,
          lout: 255,
          enleac: false, //是否启用漏电电流告警
          leac: 50,//漏电电流阈值
          enleav: false,//是否启用漏电电压告警
          leav: 100,//漏电电压阈值
          engyro: false, //是否启用灯杆倾斜告警
          uhr: 277,//115B:降功率电压上限(严重报警，联动调光)，默认277V
          uhb: 255,//115B:降功率过压联动调光值(0-100，其它值功能无效，默认255)
          ulr: 100,//降功率电压下限(严重报警，联动调光)，默认100V
          ulb: 255,//降功率欠压联动调光值(0-100，其它值功能无效，默认255)
          //1路
          en1: true, //控制使能
          nm1: '主灯', //名称
          pr1: 100, //额定功率
          tp1: '默认', //灯具类型
          lp1: 100, //灯具功率
          prl1: 50, //1路功率下限值
          prh1: 250, //1路功率上限值
          lc1: 1, //灯具数量
          pc1: 1, //测量校准系数
          mode1: 0,//控制模式
          timeId11: 0,//一路计时日表ID
          timeId21: 0,//一路准时日表ID
          //2路
          en2: false, //控制使能
          nm2: '辅灯', //名称
          pr2: 100, //额定功率
          tp2: '默认', //灯具类型
          lp2: 100, //灯具功率
          prl2: 50, //1路功率下限值
          prh2: 250, //1路功率上限值
          lc2: 1, //灯具数量
          pc2: 1, //测量校准系数
          mode2: 0,//控制模式
          timeId12: 0,//计时日表ID
          timeId22: 0,//准时日表ID
          //3路
          en3: false, //控制使能
          nm3: '辅灯', //名称
          pr3: 100, //额定功率
          tp3: '默认', //灯具类型
          lp3: 100, //灯具功率
          prl3: 50, //1路功率下限值
          prh3: 250, //1路功率上限值
          lc3: 1, //灯具数量
          pc3: 1, //测量校准系数
          mode3: 0,//控制模式
          timeId13: 0,//计时日表ID
          timeId23: 0,//准时日表ID
          //4路
          en4: false, //控制使能
          nm4: '辅灯', //名称
          pr4: 100, //额定功率
          tp4: '默认', //灯具类型
          lp4: 100, //灯具功率
          prl4: 50, //1路功率下限值
          prh4: 250, //1路功率上限值
          lc4: 1, //灯具数量
          pc4: 1, //测量校准系数
          mode4: 0,//控制模式
          timeId14: 0,//计时日表ID
          timeId24: 0,//准时日表ID
        }
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          this.form.id = this.item.data.id;
          this.form.stationId = this.item.data.stationId;
          this.form.name = this.item.data.name;
          this.form.code = this.item.data.code;
          this.form.connectType = 0;
          this.stationChanged(() => {
            this.form.deviceId = this.item.data.deviceId;
            for (let k in this.form.content) {
              this.form.content[k] = this.item.data.content[k];
            }
            if (!this.form.content.pc1) this.form.content.pc1 = 1;
            if (!this.form.content.pc2) this.form.content.pc2 = 1;
            if (!this.form.content.pc3) this.form.content.pc3 = 1;
            if (!this.form.content.pc4) this.form.content.pc4 = 1;
            if (this.form.content.lout > -1 && this.form.content.lout < 101) {
              this.loutEnu = true;
            } else {
              this.loutEnu = false;
            }
            if (this.form.content.tout > -1 && this.form.content.tout < 101) {
              this.toutEnu = true;
            } else {
              this.toutEnu = false;
            }
          });
          setTimeout(() => {
            this.getUseablePlan();
          }, 500);
        } else {
          this.form.id = 0;
          this.form.stationId = this.stations.length > 0 ? this.stations[0].id : 0;
          this.form.deviceId = 0;
          this.form.name = '';
          this.form.code = '';
          this.form.connectType = 0;
          this.stationChanged(() => {
            this.form.content = {
              //基本配置相关
              oid: 0, //关联控制输出
              type: 101, //单灯类型
              timeId: 0, //时间表ID
              area: 0, //单独所属控制区域
              pole: 0, //关联灯杆
              enu: false, //是否启用电压告警
              uh: 280, //电压阈值上限
              ul: 80, //电压阈值下限
              enc: false,//是否启用电流告警
              cl: 0.5, //电流阈值下限
              ch: 10, //电流阈值上限
              ent: false, //是否启用温度告警
              tl: 50,//温度阈值下限
              th: 80,//温度阈值上限
              leah: 100,//降功率漏电上限mA
              lout: 255,//漏电降功率联动调光值
              tout: 100,////降功率调光值
              enleac: false, //是否启用漏电电流告警
              leac: 50,//漏电电流阈值
              enleav: false,//是否启用漏电电压告警
              leav: 100,//漏电电压阈值
              engyro: false, //是否启用灯杆倾斜告警
              uhr: 277,//115B:降功率电压上限(严重报警，联动调光)，默认277V
              uhb: 255,//115B:降功率过压联动调光值(0-100，其它值功能无效，默认255)
              ulr: 100,//降功率电压下限(严重报警，联动调光)，默认100V
              ulb: 255,//降功率欠压联动调光值(0-100，其它值功能无效，默认255)
              //1路
              en1: true, //控制使能
              nm1: '主灯', //名称
              pr1: 100, //额定功率
              tp1: '默认', //灯具类型
              lp1: 100, //灯具功率
              prl1: 50, //1路功率下限值
              prh1: 250, //1路功率上限值
              lc1: 1, //灯具数量
              pc1: 1,
              mode1: 0,//控制模式
              timeId11: 0,//计时日表ID
              timeId21: 0,//准时日表ID
              //2路
              en2: false, //控制使能
              nm2: '辅灯', //名称
              pr2: 100, //额定功率
              tp2: '默认', //灯具类型
              lp2: 100, //灯具功率
              prl2: 50, //1路功率下限值
              prh2: 250, //1路功率上限值
              lc2: 1, //灯具数量
              pc2: 1,
              mode2: 0,//控制模式
              timeId12: 0,//计时日表ID
              timeId22: 0,//准时日表ID
              //3路
              en3: false, //控制使能
              nm3: '辅灯', //名称
              pr3: 100, //额定功率
              tp3: '默认', //灯具类型
              lp3: 100, //灯具功率
              prl3: 50, //1路功率下限值
              prh3: 250, //1路功率上限值
              lc3: 1, //灯具数量
              pc3: 1,
              mode3: 0,//控制模式
              timeId13: 0,//计时日表ID
              timeId23: 0,//准时日表ID
              //4路
              en4: false, //控制使能
              nm4: '辅灯', //名称
              pr4: 100, //额定功率
              tp4: '默认', //灯具类型
              lp4: 100, //灯具功率
              prl4: 50, //1路功率下限值
              prh4: 250, //1路功率上限值
              lc4: 1, //灯具数量
              pc4: 1,
              mode4: 0,//控制模式
              timeId14: 0,//计时日表ID
              timeId24: 0,//准时日表ID
            }
          });
        }

        this.getLightList();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    'form.deviceId'() {
      this.getUseablePlan();
    }
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes', 'smallTypeArr']),
    ...mapGetters('auth', ['showLang']),
    boxType: function () {
      return function (deviceId) {
        if (deviceId <= 0) return 'none';
        let devs = this.devices.filter(p => p.id == deviceId);
        if (devs.length == 0) return 'none';
        return devs[0].type;
      }
    },
  },
  mounted: function () {
    this.form.content.nm1 = this.showLang('com.lamp.host');
    this.form.content.nm2 = this.showLang('com.lamp.assist');
    this.form.content.nm3 = this.showLang('com.lamp.assist');
    this.form.content.nm4 = this.showLang('com.lamp.assist');
    this.form.content.tp1 = this.showLang('com.state.default');
    this.form.content.tp2 = this.showLang('com.state.default');
    this.form.content.tp3 = this.showLang('com.state.default');
    this.form.content.tp4 = this.showLang('com.state.default');
  },
  methods: {
    toutEnuChanged() {
      if (this.toutEnu) {
        this.form.content.tout = 0
      } else {
        this.form.content.tout = 255
      }
    },
    loutEnuChanged() {
      if (this.loutEnu) {
        this.form.content.lout = 0
      } else {
        this.form.content.lout = 255
      }
    },
    ulbEnuChanged() {
      if (this.ulbEnu) {
        this.form.content.ulb = 0
      } else {
        this.form.content.ulb = 255
      }
    },
    uhbEnuChanged() {
      if (this.uhbEnu) {
        this.form.content.uhb = 0
      } else {
        this.form.content.uhb = 255
      }
    },
    getLightList() {//计时日表 准时日表
      this.timeId1Data = [];
      this.timeId2Data = [];
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLight6List`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeId1Data', res.data);
        }
      });
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLight7List`, {}).then(res => {
        if (res.code == 0) {
          // this.$set(this, 'timeId2Data', res.data);
          this.$set(this, 'timeId7Data', res.data);
        }
      });
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLight8List`, {}).then(res => {
        if (res.code == 0) {
          // this.$set(this, 'timeId2Data', res.data);
          this.$set(this, 'timeId8Data', res.data);
        }
      });
    },
    getUseablePlan: function () {
      this.timeId2Data = [];
      let devs = this.devices.filter(p => (this.smallTypeArr.indexOf(p.smallType) > -1) && p.id == this.form.deviceId);
      if (devs.length == 0 && this.form.deviceId != 0 && this.form.deviceId) {
        this.smallTypeShow = false;
        this.$set(this, 'timeId2Data', this.timeId7Data);
      } else if (devs.length > 0 && this.form.deviceId != 0 && this.form.deviceId) {
        this.smallTypeShow = true;
        this.$set(this, 'timeId2Data', this.timeId8Data);
      } else {
        this.smallTypeShow = false;
        this.timeId2Data = [];
      }
      if (this.form.content.uhb != 255 && this.form.content.uhb != undefined) {
        this.uhbEnu = true;
      } else {
        this.uhbEnu = false;
      }
      if (this.form.content.ulb != 255 && this.form.content.ulb != undefined) {
        this.ulbEnu = true;
      } else {
        this.ulbEnu = false;
      }
    },
    getDeviceName: function (item) {
      if (this.form.connectType == 0) {
        return `${item.name}[${this.deviceTypes[item.type]}][ID:${item.code}]`
      } else {
        return item.name;
      }
    },
    getAccounts: async function () {
      this.$set(this, "devices", []);
      this.form.deviceId = 0;
    },
    stationChanged: function (callback) {
      if (this.form.stationId <= 0) {
        this.$set(this, 'devices', []);
        this.$set(this, 'poles', []);
        this.$set(this, 'outputs', []);
        this.form.content.area = 0;
        this.form.content.oid = 0;
        this.form.deviceId = 0;
      } else {
        let ps = [];
        ps.push(this.$axios.post(`//${this.domains.trans}/station/config/GetStationDevices`, { groupId: 0, stationId: this.form.stationId, type: 3 }));
        ps.push(this.$axios.post(`//${this.domains.trans}/station/config/QueryLampPole`, { groupId: 0, stationId: this.form.stationId }));
        ps.push(this.$axios.post(`//${this.domains.trans}/station/config/QueryOutput`, { groupId: 0, stationId: this.form.stationId }));
        Promise.all(ps).then(res => {
          this.$set(this, "devices", res[0].code == 0 ? res[0].data : []);
          this.$set(this, 'poles', res[1].code == 0 ? res[1].data : []);
          this.$set(this, 'outputs', res[2].code == 0 ? res[2].data : []);
          if (callback && `${typeof callback}` == 'function') callback();
        })
      }
    },
    ok: async function () {
      if (this.form.name.trim().length == 0) {
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if (this.form.stationId <= 0) {
        this.$Message.warning(this.showLang('com.save.err.station'));
        return;
      }
      if (this.form.content.area <= 0) {
        this.$Message.warning(this.showLang('select.group'));
        return;
      }
      if (this.form.deviceId <= 0) {
        this.$Message.warning(this.showLang('save.light.device.need'));
        return;
      }
      // if (this.form.content.timeId <= 0) {
      //   this.$Message.warning(this.showLang('save.light.plan.need'));
      //   return;
      // }
      if (!this.form.content.en1 && !this.form.content.en2) {
        this.$Message.warning('至少选择一个通道');
        return;
      }
      // if (this.form.content.tr < 30 || this.form.tr > 200) {
      //   this.$Message.warning(this.showLang('save.light.range.temp',30,70));
      //   return;
      // }
      // if (this.form.content.ur < 0 || this.form.ur > 500) {
      //   this.$Message.warning(this.showLang('save.light.range.voltage',0,500));
      //   return;
      // }
      this.$axios.post(`//${this.domains.trans}/station/config/SaveLight`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>