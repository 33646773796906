<template>
  <div class="alarm-rule-container">
    <div class="alarm-data-query">
      {{ showLang('com.attribute.name') }}：
      <Input v-model="form.name" type="text" clearable style="width: 100px;height:32px;margin-right: 10px;"></Input>
      {{showLang('is.eliminate')}}：
      <Select :placeholder="showLang('save.select')" v-model="form.type" number style="margin-right: 10px; width: 100px;">
        <Option :value="0">{{ showLang('com.state.all') }}</Option>
        <Option :value="1">{{showLang('is.ok.eliminate')}}</Option>
        <Option :value="2">{{showLang('com.state.no.eliminated')}}</Option>
      </Select>
      {{ showLang('com.date.start') }}：
      <DatePicker v-model="form.start" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      {{ showLang('com.date.end') }}：
      <DatePicker v-model="form.end" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      <Button type="primary" size="default" style="margin-right: 3px" @click="getList">{{showLang('com.op.query') }}</Button>
      <Button type="primary" size="default" style="margin-right: 3px" @click="getHours24">24{{showLang('hour.within') }}</Button>
      <Button type="primary" size="default" style="margin-right: 3px" @click="getHours48">24-48{{showLang('com.date.hour') }}</Button>
      <Button type="primary" size="default" style="margin-right: 3px" @click="getHoursOther">48{{showLang('hour.outside') }}</Button>
      <Button type="error" v-if="funCodes('ard1')" style="margin-right: 3px" @click="handleMutiDelete">{{ showLang('com.op.batch.del') }}</Button>
      <Button type="primary" @click="exportData()">{{ showLang('com.op.export') }}</Button>
    </div>
    <div class="alarm-data-area" ref="table1">
     <vxe-table
        :empty-text="showLang('com.data.no')"
        border
        resizable
        show-overflow
        show-header-overflow
        keep-source
        ref="chkTable"
        :key="dataRefresh"
        @checkbox-change="selectChangeEvent"
        :data="list"
        :height="tabHeight"
        :row-config="{ isHover: true }"
        :checkbox-config="{ checkField: 'checked' }"
        :seq-config="{ startIndex: (form.index - 1) * form.size }"
        
      >
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="stationName" :title="showLang('com.state.police.station')" width="200" header-align="center" sortable>
          <template #default="{ row }">
            {{ row.stationName }}
          </template>
        </vxe-column>
        <vxe-column field="paramName" :title="showLang('com.attribute.name')" width="200" header-align="center" sortable>
          <template #default="{ row }">
            {{ row.paramName }}
          </template>
        </vxe-column>
        <vxe-column field="name" :title="showLang('com.state.police.type')" width="120" header-align="center" sortable>
          <template #default="{ row }">
            {{ row.name }}
          </template>
        </vxe-column>
        <vxe-column field="reportName" :title="showLang('Reporting.person')" width="120" header-align="center" sortable>
          <template #default="{ row }">
            {{ row.reportName }}
          </template>
        </vxe-column>
        <vxe-column field="mobile"  :title="showLang('com.site.phone')"  header-align="center" sortable width="160">
          <template #default="{ row }">
            {{ row.mobile }}
          </template>
        </vxe-column>
        <vxe-column field="startTime" :title="showLang('com.date.occurrence')" header-align="center" sortable width="160">
          <template #default="{ row }">
            {{ row.startTime }}
          </template>
        </vxe-column>
        <vxe-column field="isDone" :title="showLang('is.eliminate')" header-align="center" sortable width="160">
          <template #default="{ row }">
            {{ row.isDone ? row.doneTime : showLang('com.state.no.eliminated') }}
          </template>
        </vxe-column>
        <vxe-column field="extra" :title="showLang('com.state.police.info')" header-align="center" sortable width="320">
          <template #default="{ row }">
            {{ row.extra }}
          </template>
        </vxe-column>
        <vxe-column field="confirmName" :title="showLang('is.sure')" header-align="center" sortable width="200">
          <template #default="{ row }">
            {{ row.isConfirm ? `${row.confirmName}[${row.confirmTime}]` : showLang('com.state.unconfirmed') }}
          </template>
        </vxe-column>
        <vxe-column field="messageUser" :title="showLang('SMS.notification')" header-align="center" sortable width="150">
          <template #default="{ row }">
            {{ row.messageUser }}
          </template>
        </vxe-column>
        <vxe-column width="120" fixed="right" :title="showLang('com.right.nav.operate')" header-align="center">
          <template #default="{ row }">
            <AuthButton opCode="ars" style="margin-right: 5px" @click="showAlarmHisData(row)">{{ showLang('com.date.view') }}</AuthButton>
            <AuthButton opCode="ard1" type="error" @click="handleDelete(row)">{{ showLang('com.op.del') }}</AuthButton>
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager :layouts="pageLayouts" :page-sizes='pageSizes' :current-page.sync="form.index" :page-size.sync="form.size" :total="count" @page-change="handlePageChange"> </vxe-pager>
      <ModalAlarmData v-model="showAlarmDataModal" :item="editItem" @alarmFixed="alarmFixed" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalAlarmData from './ModalAlarmData'
export default {
  name: 'AlarmPowerboxCurrent',
  components: {
    ModalAlarmData
  },
  props: {},
  data() {
    return {
      list: [],
      tabHeight: 200,
      dataRefresh: 0,
      editItem: { isAdd: true, data: {} },
      showAlarmDataModal: false,
      form: {
        groupId: 0,
        stationId: 0,
        name: '',
        type: 0,
        start: '2023-09-20 15:54:00',
        end: '2023-09-20 15:54:00',
        size: 20,
        index: 1,
      },
      levels: [],
      types: [],
      count: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'dealAlarmTypes', 'pageSizes', 'pageLayouts']),
    ...mapGetters('common', ['getDealAlarmMethod']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight()
      // console.log('windowOnResize', this.windowOnResize, this.$refs.table1.clientHeight)
    },
    selectedNode() {
      this.getList()
    }
  },
  mounted: function() {
    let now = new Date();
    // now.setDate(now.getDate() );
    this.form.end = now.todayEnd();
    now.setDate(now.getDate() - 7);
    this.form.start = now.todayStart();

    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function() {},
  methods: {
    exportData() {
      let stationId = 0, groupId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.form.groupId = groupId;
      this.form.stationId = stationId;
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      
      this.$store.dispatch('auth/reqFile', {
        title: '导出人工报障详细信息.xlsx',
        url: `//${this.domains.trans}/station/alarm/ExportReportDetail`,
        args: this.form,
      }).then(() => {
        // this.exportLoading = false;
      });
    },
    getHours24: function(){
      let now = new Date();
      this.form.end = now.todayEnd();
      now.setHours(now.getHours() - 24);
      this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
      this.getList();
    },
    getHours48: function(){
      let now = new Date();
      now.setHours(now.getHours() - 24);
      this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setHours(now.getHours() - 24);
      this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
      this.getList();
    },
    getHoursOther: function(){
      let now = new Date();
      now.setHours(now.getHours() - 48);
      this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setHours(now.getHours() - 24000);
      this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
      this.getList();
    },
    handlePageChange({ currentPage, pageSize }) {
      this.form.index = currentPage
      this.form.size = pageSize
      this.getList()
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    alarmFixed: function(){
      this.getList();
    },
    showAlarmHisData: function(item) {
      this.editItem = item
      this.showAlarmDataModal = true
    },
    tableRowClassName({ row }) {
      if (row) {
        return ''
      }
      return ''
    },
    setTableHeight: function() {
      this.tabHeight = this.$refs.table1.clientHeight - 50
    },
    handleMutiDelete: function() {
      let chks = this.$refs.chkTable.getCheckboxRecords()
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'))
        return
      }
      let list = chks.map(p => p.id)
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/alarm/DeleteReportAlarms`, { list }).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.tips.del.success'))
              this.getList()
            }
          })
        }
      })
    },
    handleDelete: function(row) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', row.paramName),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/alarm/DeleteReportAlarms`, { list: [row.id] }).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.tips.del.success'))
              this.getList()
            }
          })
        }
      })
    },
    getList: function() {
      let stationId = 0, groupId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      this.form.groupId = groupId;
      this.form.stationId = stationId;
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryReportDetail`, this.form).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list)
          this.count = res.data.count;
        }
      })
    }
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
.alarm-data-area >>> .el-table tr.warning-row {
  background-color: #ffa8a8 !important;
}
</style>
