<template>
  <div class="main-table">
    <table v-if="leakages != null && leakages != undefined" class="table">
      <tr>
        <td colspan="8">
          <div class="table-header">
            <img :src="img.light.road.single.header.leakage" />
            {{showLang('com.lighting.leakage')}} [{{new Date().long2str(lastTime)}}]
          </div>
        </td>
      </tr>
      <tr>
        <td class="column-header tc">#</td>
        <td class="column-header tc">{{showLang('com.tab.title')}}</td>
        <td class="column-header tc">{{showLang('com.lighting.cl')}}</td>
        <td class="column-header tc">{{showLang('alarm.type.leakage.1.threshold')}}</td>
        <td class="column-header tc">{{showLang('alarm.type.leakage.2.threshold')}}</td>
        <td class="column-header tc">{{showLang('alarm.type.leakage.3.threshold')}}</td>
        <td class="column-header tc">{{showLang('com.right.nav.alarm')}}</td>
        <td class="column-header tc">{{showLang('com.right.nav.operate')}}</td>
      </tr>
      <tr v-for="(el,index) in leakages" :key="el.id">
        <td class="column-header tc">{{index+1}}</td>
        <td class="column-header tc">{{el.name}}</td>
        <td class="column-header tc">{{img.val(el.lastData.cl, 1)}}mA</td>
        <td class="column-header tc">{{el.content.ll}}mA</td>
        <td class="column-header tc">{{el.content.lh}}mA</td>
        <td class="column-header tc">{{el.content.lx}}mA</td>
        <td class="column-header tc" v-html="cln(el)"></td>
        <td class="column-header tc"><a href="#" @click="showHistoryData(el)">{{showLang('history.data.look')}}</a></td>
      </tr>
      <ModalLeakageData v-model="showDataModal" :item="editItem" />
    </table>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalLeakageData from './ModalLeakageData'
export default {
  name: 'PowerVoltageTable',
  components: {
    ModalLeakageData
  },
  props: {
    leakages: {
      type: Array,
      default(){
        return [];
      }
    },
  },
  data() {
    return {
      editItem: {},
      showDataModal: false,
    }
  },
  watch: {
  },
  computed: {
    ...mapState('cmd', ['singleCmdResult']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    lastTime: function(){
      if(this.leakages.length == 0)return 0;
      let t = 0;
      for(let l of this.leakages){
        if(l.fireTime > t)t = l.fireTime;
      }
      return t;
    }
  },
  mounted: function () {
    window.eventBus.$on('paramRealDataComing', params => {
      let ls = this.leakages.filter(p => p.id == params.paramId);
      for(let l of ls){
        this.$set(l, 'lastData', params.data);
        this.$set(l, 'extraData', params.extra);
      }
    })
  },
  destroyed: function(){
    window.eventBus.$off('paramRealDataComing');
  },
  methods: {
    showHistoryData: function (el) {
      this.editItem = el;
      this.showDataModal = true;
    },
    cln(el){ //漏电电流
      //source.main.content.e
      if(!el.lastData)return '-';
      let cl = el.lastData.cl;
      let ll = el.content.ll;
      let lh = el.content.lh;
      let lx = el.content.lx;
      if(cl < 0) return '-';
      else if (cl > lx) return `<span class="red">${this.showLang('alarm.type.leakage.3')}</span>`;
      else if (cl > lh) return `<span class="red">${this.showLang('alarm.type.leakage.2')}</span>`;
      else if (cl > ll) return `<span class="red">${this.showLang('alarm.type.leakage.1')}</span>`;
      else return `${this.showLang('com.state.normal')}`;
    },
    isPolice(el) {
      if (!el.lastData || el.lastData.cl == "--") {
        return "--";
      }
      if(el.lastData.cl > el.content.lx)return this.showLang("alarm.type.leakage.3");
      else if(el.lastData.cl > el.content.lh)return this.showLang("alarm.type.leakage.2");
      else if(el.lastData.cl > el.content.ll)return this.showLang("alarm.type.leakage.1");
      else return this.showLang("com.state.normal");
    },
    panelClick: function () {
      return false;
    }
  }
}
</script>
<style scoped>
.main-table {
  width: 1110px;
  background: #f0f4fa;
  border-radius: 12px;
  padding: 10px;
}
.table-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 1090px;
  height: 50px;
  line-height: 47px;
  text-align: center;
  /* background: rgba(10, 26, 68, 0.8); */
  background: linear-gradient(270deg, #3682ff, #4c9eff);
  /* box-shadow: inset 0px 0px 24px 0px #04559D; */
  /* border-top: solid 1px #0a60b0; */
  /* border-bottom: solid 1px #0a60b0; */
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  position: relative;
  /* color: #52FBFE; */
  /* border: solid 1px black; */
}
.header-button {
  position: absolute;
  top: 10px;
  right: 10px;
  /* border: 1px solid #FFFFFF; */
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3880fc;
  width: 103px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  cursor: pointer;
}
.table {
  border-collapse: separate;
  /* border-radius: 12px; */
  border-spacing: 0;
}
.table tr:first-child td:first-child {
  border-top-left-radius: 12px;
}
.table tr:first-child td:last-child {
  border-top-right-radius: 12px;
}
.table tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}
.table tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}
.table-header img {
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #FFFFFF; */
  color: #0d3a6a;
  opacity: 0.8;
  border: solid 1px white;
  /* text-align: left; */
  /* padding-left: 20px; */
  height: 39px;
  width: 130px;
  /* border-collapse: collapse; */
  /* background: #183992; */
  background: #c8daef;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #52FBFE; */
  color: #0d3a6a;
  background: #e8eff7;
  /* background: #2c9ef55e; */
  border: solid 1px white;
  text-align: left;
  padding-left: 40px;
  height: 32px;
  width: 130px;
}
.active {
  color: #f00;
}
</style>