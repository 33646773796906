<template>
  <div class="list-common-container">
    <div class="list-common-query">
      <!-- {{showLang('com.site')}}：
      <Select :placeholder="showLang('save.select')" v-model="filter.stationId" style="width: 150px; margin-right: 10px;">
         <Option :value="0">所有配电柜</Option>
        <template v-for="(item, idx) in selectedStations">
          <Option :value="item.id" :key="idx">{{item.name}}</Option>
        </template>
      </Select> -->
      {{showLang('com.tab.title')}}：
      <Input v-model="filter.name" type="text" clearable style="width: 100px; margin-right: 10px;"></Input>
      {{showLang('com.ins.last.id')}}：
      <Input v-model="filter.code" type="text" clearable style="width: 100px; margin-right: 10px;"></Input>
      <!-- {{showLang('com.lamp.type')}}：
      <Select :placeholder="showLang('save.select')" v-model="filter.type" style="width: 150px; margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(v, k) in newLightTypes">
          <Option :key="k" :value="parseInt(k)">{{v}}</Option>
        </template>
      </Select> -->
      {{showLang('com.ins.timeTable')}}：
      <Select :placeholder="showLang('save.select')" v-model="filter.timeId" style="width: 150px; margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="item in timeTables">
          <Option :key="item.id" :value="item.id">[{{item.type}}]{{item.name}}</Option>
        </template>
      </Select>
      {{showLang('com.group')}}：
      <Select :placeholder="showLang('save.select')" v-model="filter.group" style="width: 150px; margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(item, idx) in groups">
          <Option :key="idx" :value="parseInt(item.id)">{{item.name}}</Option>
        </template>
      </Select>
      <Button type="primary" @click="initList(filter.index=1)" style="margin-right: 5px"> {{showLang('com.op.query')}}</Button>
      <Button type="primary" @click="exportStateData"> {{showLang('com.op.export')}}</Button>
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow ref="chkTable" :export-config="{}" :data="list" :scroll-y="{enabled: true}" :key="dataRefresh" @checkbox-change="selectChangeEvent" :height="tabHeight" :row-config="{isHover: true,height: rowHeight}" :checkbox-config="{checkField: 'checked',highlight: true }" @checkbox-all='selectChangeEventAll'>
        <vxe-column field="seq" type="seq" width="60"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column v-if="colCanShow('nm')" field="name" :title="showLang('com.tab.title')" min-width="150" show-overflow="tooltip" show-header-overflow="tooltip" :show-overflow-tooltip="true">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('pole')" field="poleName" :title="showLang('com.device.pole.name')" min-width="150" show-overflow="tooltip" show-header-overflow="tooltip" :show-overflow-tooltip="true">
          <template #default="{ row }">
            {{row.poleName}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('id')" field="code" :title="showLang('com.ins.last.id')" width="120" show-overflow="tooltip" show-header-overflow="tooltip" :show-overflow-tooltip="true">
          <template #default="{ row }">
            {{row.code}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('ch')" field="ch" :title="showLang('com.ins.ch')" width="80">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.content.en1" class="split-row-item">K1{{row.lastData.ch}}:{{row.content.nm1}}</div>
              <div v-if="row.content.en2" class="split-row-item">K2{{row.lastData.ch}}:{{row.content.nm2}}</div>
              <div v-if="row.content.en3" class="split-row-item">K3{{row.lastData.ch}}:{{row.content.nm3}}</div>
              <div v-if="row.content.en4" class="split-row-item">K4{{row.lastData.ch}}:{{row.content.nm4}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('ol')" field="online" :title="showLang('com.state')" width="50">
          <template #default="params">
            <img :src="getStateImage(params)" />
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('u')" field="lastData.u" :title="showLang('com.lighting.V')+'/V'" width="90" sortable>
          <template #default="{ row }">
            {{img.val2(row.lastData.u, 'u')}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('c')" field="c" :title="showLang('com.lighting.I')+'/A'" width="80">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.content.en1" class="split-row-item">{{img.val2(row.lastData.c1, 'c')}}</div>
              <div v-if="row.content.en2" class="split-row-item">{{img.val2(row.lastData.c2, 'c')}}</div>
              <div v-if="row.content.en3" class="split-row-item">{{img.val2(row.lastData.c3, 'c')}}</div>
              <div v-if="row.content.en4" class="split-row-item">{{img.val2(row.lastData.c4, 'c')}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('p')" field="p" :title="showLang('com.lighting.P')+'/W'" width="80">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.content.en1" class="split-row-item">{{img.val2(row.lastData.p1, 'p')}}</div>
              <div v-if="row.content.en2" class="split-row-item">{{img.val2(row.lastData.p2, 'p')}}</div>
              <div v-if="row.content.en3" class="split-row-item">{{img.val2(row.lastData.p3, 'p')}}</div>
              <div v-if="row.content.en4" class="split-row-item">{{img.val2(row.lastData.p4, 'p')}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('op')" field="op" :title="showLang('com.lighting.op')+'/%'" width="80">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.content.en1" class="split-row-item">{{img.val(row.lastData.op1, 0)}}</div>
              <div v-if="row.content.en2" class="split-row-item">{{img.val(row.lastData.op2, 0)}}</div>
              <div v-if="row.content.en3" class="split-row-item">{{img.val(row.lastData.op3, 0)}}</div>
              <div v-if="row.content.en4" class="split-row-item">{{img.val(row.lastData.op4, 0)}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('q')" field="q" :title="showLang('com.lighting.q')+'/KWh'" width="100">
          <template #default="{ row }">
            {{img.val2(row.lastData.q1, 'q')}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('dv')" field="dv" :title="showLang('com.lighting.dv')+'(/V)'" width="110">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.content.en1" class="split-row-item">{{img.val2(row.lastData.dv1, 'u')}}</div>
              <div v-if="row.content.en2" class="split-row-item">{{img.val2(row.lastData.dv2, 'u')}}</div>
              <div v-if="row.content.en3" class="split-row-item">{{img.val2(row.lastData.dv3, 'u')}}</div>
              <div v-if="row.content.en4" class="split-row-item">{{img.val2(row.lastData.dv4, 'u')}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('dc')" field="dc" :title="showLang('com.lighting.dc')+'(/A)'" width="110">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.content.en1" class="split-row-item">{{img.val2(row.lastData.dc1, 'c')}}</div>
              <div v-if="row.content.en2" class="split-row-item">{{img.val2(row.lastData.dc2, 'c')}}</div>
              <div v-if="row.content.en3" class="split-row-item">{{img.val2(row.lastData.dc3, 'c')}}</div>
              <div v-if="row.content.en4" class="split-row-item">{{img.val2(row.lastData.dc4, 'c')}}</div>
            </div>
          </template>
        </vxe-column>

        <vxe-column v-if="colCanShow('lo')" field="lo" :title="showLang('com.lighting.lo')" width="120">
          <template #default="{ row }">
            {{img.val(row.lastData.lo, 0)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('rssi')" field="rssi" :title="showLang('com.lighting.rssi')+'(dBm)'" width="120">
          <template #default="{ row }">
            {{row.lastData.rssi}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('sun')" field="sun" :title="showLang('com.lighting.sun')+'(V)'" width="100">
          <template #default="{ row }">
            {{img.val(row.lastData.sun, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('oc')" field="oc" :title="showLang('com.lighting.oc')" width="100">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.content.en1" class="split-row-item">{{img.val(row.lastData.oc1, 0)}}</div>
              <div v-if="row.content.en2" class="split-row-item">{{img.val(row.lastData.oc2, 0)}}</div>
              <div v-if="row.content.en3" class="split-row-item">{{img.val(row.lastData.oc3, 0)}}</div>
              <div v-if="row.content.en4" class="split-row-item">{{img.val(row.lastData.oc4, 0)}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('lu')" field="lu" :title="showLang('com.lighting.lu')+'/V'" width="120">
          <template #default="{ row }">
            {{img.val(row.lastData.lu, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('cl')" field="cl" :title="showLang('com.lighting.cl')+'/mA'" width="120">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.content.en1" class="split-row-item">{{img.val(row.lastData.cl1, 2)}}</div>
              <div v-if="row.content.en2" class="split-row-item">{{img.val(row.lastData.cl2, 2)}}</div>
              <div v-if="row.content.en3" class="split-row-item">{{img.val(row.lastData.cl3, 2)}}</div>
              <div v-if="row.content.en4" class="split-row-item">{{img.val(row.lastData.cl4, 2)}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('tc')" field="tc" :title="showLang('com.lighting.T')+'/℃'" width="80">
          <template #default="{ row }">
            {{img.val2(row.lastData.tc, 't')}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('lt')" field="lampType" :title="showLang('com.state.type')" width="120">
          <template #default="{ row }">
            {{newLightTypes[row.type]}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('an')" field="areaName" :title="showLang('com.group')" width="120">
          <template #default="{ row }">
            {{row.areaName}}
          </template>
        </vxe-column>
        <vxe-column field="fireTime" :title="showLang('com.ins.time.last')" sortable width="180">
          <template #default="{ row }">
            {{new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss')}}
          </template>
        </vxe-column>
        <vxe-column field="result" :title="showLang('com.ins.result')" min-width="300" sortable show-overflow="tooltip" show-header-overflow="tooltip" :show-overflow-tooltip="true">
          <template #default="{ row }">
            <div :ref="'name'+row.id">{{row._message}}</div>
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('gx')" field="gx" :title="showLang('com.data.x')" width="80">
          <template #default="{ row }">
            {{row.lastData.gx}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('gy')" field="gy" :title="showLang('com.data.y')" width="80">
          <template #default="{ row }">
            {{row.lastData.gy}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('gz')" field="gz" :title="showLang('com.data.z')" width="80">
          <template #default="{ row }">
            {{row.lastData.gz}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('hv')" field="hv" :title="showLang('com.data.hvn')" width="120">
          <template #default="{ row }">
            {{row.hardware}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('sv')" field="sv" :title="showLang('com.data.svn')" width="120">
          <template #default="{ row }">
            {{row.software}}
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <p>
      <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange">
      </vxe-pager>
    </p>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" :areas="areas" @saved="argsSaved" />
    <ModalCheckAuth v-model="showCheckAuthModal" :users="checkAuthModalUsers" @checked="cmdAuthUserChecked" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalArgEdit from '../args/ModalArgEdit'
import ModalCheckAuth from '@/views/light/road/monitor/station/control/ModalCheckAuth'
export default {
  name: 'LightControlListCommon',
  components: {
    ModalArgEdit,
    ModalCheckAuth,
  },
  props: {
    type: {
      type: String,
      default: 'common'
    },
  },
  data() {
    return {
      timeTables: [],
      filter: {
        stationId: 0, //当前站点ID
        group: 0, //所选分组ID
        type: 0, //单灯类型
        timeType: -1, //时间表类型
        timeId: 0, //时间表ID
        code: '', //单灯通信ID
        name: '', //单灯名称
        "index": 1,
        "size": 20,
      },
      tabHeight: 600,
      clearOldLog: this.clearOldLogs,
      cmdType: 'light',
      showCheckAuthModal: false,
      checkAuthModalSuccessFunction: null,
      checkAuthModalUsers: [],
      showArgModal: false,
      showPlanModal: false,
      showCreateModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      groups: [],
      list: [],
      showTransModal: false,
      lights: [],
      showExtra: false,
      dataRefresh: 0,
      mergeData: [],
      items: [],
      areas: [],
      cmdResults: {},
      timer: null,
      spanFields: ['seq', 'checkbox', 'name', 'pole', 'code', 'online', 'q', 'sun', 'tc', 'lampType', 'areaName', 'fireTime', 'lu', 'result', 'gx', 'gy', 'gz', 'hv', 'sv'],
      secondaryStationId: 0,
      pageSizes: [20, 100, 500, 1000, 5000],
      total: 0,
      cmdData: {},
      rowHeight: 48,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapGetters('common', ['getEnumName']),
    ...mapState('auth', ['appType', 'config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs', 'lightRealDataRefresh']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'newLightTypes', 'leakagAlarmeStatus', 'smallTypeArr', 'pwmPolar', 'pwmFrequence']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    canDoCmds: function () {
      let cmds = [];
      for (let cmd of this.items) {
        if (this.funCodes(cmd.opcode)) cmds.push(cmd);
      }
      return cmds;
    },
    colCanShow: function () {
      return function (code) {
        return this.config.lightShowColumns.indexOf(code) != -1;
      };
    },
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
    lightRealDataRefresh() {
      this.refreshLightData();
    },
    windowOnResize() {
      this.setTableHeight();
    },
    'filter.stationId'() {
      this.getLightGroup();
      this.initList();
    },
    selectedNode() {
      this.setDefaultStation();
    },
  },
  mounted: function () {
    this.clearOldLog = this.clearOldLogs;
    setTimeout(this.setTableHeight, 100);
    this.queryAreas();
    this.getUseablePlan();
    this.setDefaultStation();
    this.initList();
    this.$set(this, 'secondaryStationId', this.filter.stationId);
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    });
    window.eventBus.$on('paramStateComing', params => {
      let ls = this.list.filter(p => p.lid == params.id);
      for (let l of ls) {
        this.$set(l, 'online', params.online);
        this.$set(l, 'alarm', params.alarm);
        this.$set(l, 'running', params.running);
      }
    });
    window.eventBus.$on('paramRealDataComing', params => {
      let ls = this.list.filter(p => p.lid == params.paramId);
      for (let l of ls) {
        this.$set(l.lastData, 'tc', params.data[`tc`]);
        this.$set(l.lastData, 'tm', params.data[`tm`]);
        this.$set(l.lastData, 'start', `${params.data[`sh`]}:${params.data[`sm`]}`);
        this.$set(l.lastData, 'po', params.data[`po`]);
        this.$set(l.lastData, 'lo', params.data[`lo`]);
        this.$set(l.lastData, 'rssi', params.data[`rssi`]);
        this.$set(l.lastData, 'u', params.data[`u`]);
        this.$set(l.lastData, 'op1', params.data[`op1`]);
        this.$set(l.lastData, 'op2', params.data[`op2`]);
        this.$set(l.lastData, 'op3', params.data[`op3`]);
        this.$set(l.lastData, 'op4', params.data[`op4`]);
        this.$set(l.lastData, 'oc1', params.data[`oc1`]);
        this.$set(l.lastData, 'oc2', params.data[`oc2`]);
        this.$set(l.lastData, 'oc3', params.data[`oc3`]);
        this.$set(l.lastData, 'oc4', params.data[`oc4`]);
        this.$set(l.lastData, 'w1', params.data[`w1`]);
        this.$set(l.lastData, 'w2', params.data[`w2`]);
        this.$set(l.lastData, 'w3', params.data[`w3`]);
        this.$set(l.lastData, 'w4', params.data[`w4`]);
        this.$set(l.lastData, 'p1', params.data[`p1`]);
        this.$set(l.lastData, 'p2', params.data[`p2`]);
        this.$set(l.lastData, 'p3', params.data[`p3`]);
        this.$set(l.lastData, 'p4', params.data[`p4`]);
        this.$set(l.lastData, 'q1', params.data[`q1`]);
        this.$set(l.lastData, 'q2', params.data[`q2`]);
        this.$set(l.lastData, 'q3', params.data[`q3`]);
        this.$set(l.lastData, 'q4', params.data[`q4`]);
        this.$set(l.lastData, 'dc1', params.data[`dc1`]);
        this.$set(l.lastData, 'dc2', params.data[`dc2`]);
        this.$set(l.lastData, 'dc3', params.data[`dc3`]);
        this.$set(l.lastData, 'dc4', params.data[`dc4`]);
        this.$set(l.lastData, 'dv1', params.data[`dv1`]);
        this.$set(l.lastData, 'dv2', params.data[`dv2`]);
        this.$set(l.lastData, 'dv3', params.data[`dv3`]);
        this.$set(l.lastData, 'dv4', params.data[`dv4`]);
        this.$set(l.lastData, 'sun', params.data[`sun`]);
        this.$set(l.lastData, 'c1', params.data[`c1`]);
        this.$set(l.lastData, 'c2', params.data[`c2`]);
        this.$set(l.lastData, 'c3', params.data[`c3`]);
        this.$set(l.lastData, 'c4', params.data[`c4`]);
        this.$set(l.lastData, 'cl1', params.data[`cl1`]);
        this.$set(l.lastData, 'cl2', params.data[`cl2`]);
        this.$set(l.lastData, 'cl3', params.data[`cl3`]);
        this.$set(l.lastData, 'cl4', params.data[`cl4`]);
        this.$set(l.lastData, 'gx', params.data[`gx`]);
        this.$set(l.lastData, 'gy', params.data[`gy`]);
        this.$set(l.lastData, 'gz', params.data[`gz`]);
        this.$set(l.lastData, 'lu', params.data[`lu`]);
        this.$set(l, 'fireTime', params.data.time);
      }
    });
  },
  destroyed: function () {
    window.eventBus.$off('paramStateComing');
    window.eventBus.$off('paramCommandComing');
    window.eventBus.$off('paramRealDataComing');
    clearInterval(this.timer);
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.initList();
    },
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let ds = this.list.filter(p => p._cmdId == cmdId);
        if (ds.length == 0) {
          if (new Date().getTime() - this.cmdResults[cmdId].time > 100000) {
            delete this.cmdResults[cmdId];
          }
          continue;
        } else {
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], '_status', item.status);
          this.$set(ds[0], '_message', item.message);
          this.$set(ds[0], '_content', item.content);
          this.$set(ds[0], '_message', this.getCmdStatus(ds[0]));
          if (this.$refs['name' + ds[0].id] != undefined) {
            this.$refs['name' + ds[0].id].innerHTML = ds[0]._message
          }
          delete this.cmdResults[cmdId]
        }
      }
      this.calcSuccessRate();
    },
    calcSuccessRate: function () {
      let lightIds = [];
      this.list.map(p => {
        if (lightIds.filter(q => q.id == p.lid).length == 0) {
          lightIds.push({ id: p.lid, status: p._status });
        }
      });
      let total = lightIds.length;
      let success = lightIds.filter(p => p.status == 9).length;
      let rate = 0;
      if (total <= 0) {
        rate = 0;
      } else {
        rate = (success * 100 / total).toFixed(2);
      }
      window.eventBus.$emit('commandSuccessRateChanged', { total, success, rate });
    },
    isLightOn: function (params) {
      for (let i = 1; i <= 4; i++) {
        if (params[`current${i}`] && params[`current${i}`] > 0) return true;
      }
      return false;
    },
    getStateImage: function (params) {
      if (!params.row.online) {
        return this.img.tree.station.offliner
      } else {
        if (params.row.alarm) {
          if (params.row.running) {
            return this.img.tree.station.alarmOn
          } else {
            return this.img.tree.station.alarmOff
          }
        } else {
          if (params.row.running) {
            return this.img.tree.station.onlineOn
          } else {
            return this.img.tree.station.onlineOff
          }
        }
      }
    },
    queryAreas: function () {
      this.$axios.post(`//${this.domains.trans}/station/config/QueryArea`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'areas', res.data);
        }
      });
    },
    dealCmd: function (params) {
      if (params.code == 'showExtra') {
        this.showExtra = params.args.show;
        return;
      }
      if (params.code == 'sendFailed') {
        this.sendFailed();
        return;
      }
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.lamp.select'));
        return;
      }
      let cmdTemp = params.args.item;
      let enArr = [];
      if (cmdTemp.code == 'getDayPlan1' || cmdTemp.code == 'getDayPlan2' || cmdTemp.code == 'setRatedPower') {
        cmdTemp.needArgs = true;
      }
      if (cmdTemp.code == 'handSingle' || cmdTemp.code == 'closeSingle' || cmdTemp.code == 'openSingle') {
        chks.forEach(el => {
          if (el.content.en1 && !enArr.includes('1')) {
            enArr.push('1')
          }
          if (el.content.en2 && !enArr.includes('2')) {
            enArr.push('2')
          }
          if (el.content.en3 && !enArr.includes('3')) {
            enArr.push('3')
          }
          if (el.content.en4 && !enArr.includes('4')) {
            enArr.push('4')
          }
        });
      }
      if (cmdTemp.needPswd || cmdTemp.needArgs) {
        this.cmdItem = { paramsCode: cmdTemp.code, code: cmdTemp.code, name: cmdTemp.name, pswd: cmdTemp.needPswd, args: {} };
        if (cmdTemp.code == 'handSingle' || cmdTemp.code == 'closeSingle' || cmdTemp.code == 'openSingle') {
          this.cmdItem.enArr = enArr;
        }
        this.showArgModal = true;
      } else {
        this.checkCmdAuth(this.cmdType, cmdTemp.code, (checkResult) => {
          if (this.clearOldLog) {
            for (let item of this.list) {
              item._message = '';
              item._status = 0;
            }
          }
          let cmds = []
          for (let chk of chks) {
            chk._message = '';
            chk._status = 0;
            if (!cmds.includes(chk.lid)) cmds.push(chk.lid);
          }
          let cmd = {
            code: cmdTemp.code,
            list: cmds,
            checkUserId: checkResult.userId,
            args: {}
          }
          this.sendCommand(cmd);
        });
      }
    },
    getCheckedItems: function () {//查询所有被选中的单灯记录
      return this.$refs.chkTable.getCheckboxRecords();
    },
    timeTableCanSelected: function (item) {
      if (this.filter.timeType < 0) return true;
      if (this.filter.timeType == 0) return item.type == this.showLang('com.but.timing');
      if (this.filter.timeType == 1) return item.type == this.showLang('com.but.ontime');
      return false;
    },
    getUseablePlan: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    getLightGroup: function () {
      if (!this.filter.stationId || this.filter.stationId <= 0) {
        this.$set(this, 'groups', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryArea`, { stationId: this.filter.stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'groups', res.data);
          setTimeout(() => {
            this.$set(this.filter, 'group', 0);
          }, 100);
        }
      });
    },
    selectChangeEventAll(checked) {
      let arr = [];
      checked.records.forEach((el) => {
        arr = this.list.filter(p => p.lid == el.lid);
        arr.forEach((item, index) => {
          if (index != 0) {
            item.checked = false;
          }
        });
      });
    },
    selectChangeEvent({ rowIndex }) {
      // this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
      this.list.forEach((el, index) => {
        if (el.lid == this.list[rowIndex].lid && index != rowIndex) {
          el.checked = false
        }
      });
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    setDefaultStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        this.filter.stationId = this.selectedNode.id;
        this.filter.groupId = 0;
      } else {
        this.filter.stationId = 0;
        this.filter.groupId = this.selectedNode.id;
      }
    },
    refreshLightData: function () {
      for (let l of this.list) {
        let lls = this.lights.filter(p => p.id == l.lid);
        if (lls.length == 0) continue;
        if (l.fireTime >= lls[0].fireTime || lls[0].fireTime <= 0) continue;
        l = lls[0];
      }
    },
    getCmdStatus: function (cmd) {
      switch (cmd._status) {
        case 0: return `${cmd._message}`;
        case 2: return this.showLang('cmd.has.sent') + this.showLang('com.ins.dev.reply');
        case 3: return cmd._message;
        case 4: return this.showLang('com.ins.execution') + `：${cmd._content.pkg} / ${cmd._content.total}`;
        case 5: return this.showLang('com.ins.fail.retry') + `：${cmd._message}`;
        case 7: return this.showLang('cmd.has.timeout');
        case 8: return this.showLang('com.ins.fail') + `：${cmd._message}`;
        case 9:
          // console.log('cmd result', cmd);
          switch (cmd._cmdCode) {
            case 'getftp': return `IP：${cmd._content.ip}，端口：${cmd._content.port}，用户名：${cmd._content.user}，密码：${cmd._content.pswd}，文件名：${cmd._content.fname}`;
            case 'getPWMOutputArgs': return `极性：${this.pwmPolar[cmd._content.polar]}，频率：${this.pwmFrequence[cmd._content.frq]}`;
            case 'getOutputVoltageCompensation': return `通道号：${cmd._content.ch}，补偿值：${cmd._content.v}`;
            case 'getOutputPowerLimit': return `通道号：${cmd._content.ch}，最小值：${cmd._content.min}，最大值：${cmd._content.max}`;
            case 'getOutputMode': {
              switch (cmd._content.mode) {
                case 0: return '0 - 10V 模拟量控制';
                case 1: return 'PWM 调光控制';
                default: return `未知值：${cmd._content.mode}`;
              }
            }
            case 'getChipVoltage': return `通道号：${cmd._content.ch}；电压系数：${cmd._content.v}`;
            case 'getChipCurrent': {
              if (this.list.filter(el => el.lid == cmd.lid && (el.deviceType > 199 && el.deviceType < 206)).length > 0) {
                let str = '';
                for (const key in cmd._content) {
                  if (key != 'time') {
                    str = str + key + '：' + cmd._content[key] + "\n"
                  }
                }
                return str
              } else {
                return `通道号：${cmd._content.ch}；电流系数：${cmd._content.v}`
              }

            }
            case 'getChipPower': return `通道号：${cmd._content.ch}；电流系数：${cmd._content.v}`;
            case 'getSigFlag': {
              if (cmd.deviceType == 85) {
                return `通道号：${cmd._content.ch}，ON时间：${cmd._content.on}毫秒，ON亮度：${cmd._content.brightOn}，OFF时间：${cmd._content.off}毫秒，OFF亮度：${cmd._content.brightOff}`;
              } else {
                return `${this.showLang('com.ins.success')}:${this.showLang('com.op.open')}：${cmd._content.on}，${this.showLang('com.op.shut')}：${cmd._content.off}`;
              }
            }
            case 'getAPN': return `APN:${cmd._content.apn}, user: ${cmd._content.user}, pswd: ${cmd._content.pswd}`;
            case 'getclock': return this.showLang('com.ins.success') + `：${cmd._content.nowTime}`;
            case 'getGroup': return this.showLang('com.ins.success') + `：${this.showLang('com.group.number')} ${cmd._content.no}`;
            case 'getInfo': {
              let result = '';
              if (cmd._content.appcode) {
                result += `应用编号：${cmd._content.appcode}；`
              }
              if (cmd._content.appversion) {
                result += `应用版本：${cmd._content.appversion}；`
              }
              if (cmd._content.hardware) {
                result += `硬件版本：${cmd._content.hardware}；`
              }
              if (cmd._content.software) {
                result += `软件版本：${cmd._content.software}；`
              }
              if (cmd._content.manufacture) {
                result += `生产厂商：${cmd._content.manufacture}；`
              }
              return result;
              // this.showLang('com.site.hw')+`：${cmd._content.hardware}，${this.showLang('com.site.sw')}：${cmd._content.software}，${this.showLang('com.user.manufacturer')}：${cmd._content.manufacture || '-'}`;
            }
            case 'getSimInfo': return `ICCID：${cmd._content.iccid}，IMEI：${cmd._content.imei}，RSSI：${cmd._content.rssi}`;
            case 'getChipVolCurArgs': return this.showLang('cmd.result.chip.vol.cur', cmd._content.vol1, cmd._content.vol2, cmd._content.leakVol, cmd._content.cur1, cmd._content.cur2, cmd._content.leakCur);
            case 'getDayPlan1': {
              let str = '';
              if (this.list.filter(el => el.lid == cmd.lid && (this.smallTypeArr.indexOf(el.deviceType) > -1)).length > 0) {
                for (let n = 0; n < 6; n++) {
                  let item = cmd._content[n];
                  if (!item) continue;
                  str += this.showLang('com.date.period') + `${n + 1}：`;
                  let bright = item[`ch${this.cmdData.args.ch}`];
                  let color = item[`c${this.cmdData.args.ch}`];
                  let min = item[`min${this.cmdData.args.ch}`];
                  if (min != undefined) {
                    str += `${this.cmdData.args.ch}${this.showLang('com.ins.ch')}-${this.showLang('com.date.time')}：${min}，`
                  }
                  if (bright != undefined) {
                    str += `${this.showLang('com.lighting.op')}：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${this.showLang('com.lighting.oc')}：${color}，`;
                  }
                  str += "\n";
                }
              } else {
                for (let n = 0; n < 6; n++) {
                  let item = cmd._content[n];
                  if (!item) continue;
                  str += this.showLang('com.date.period') + `${n + 1}：`;
                  let bright = item[`ch${this.cmdData.args.ch}`];
                  let color = item[`c${this.cmdData.args.ch}`];
                  let min = item[`min${this.cmdData.args.ch}`];
                  if (min != undefined) {
                    str += `${this.cmdData.args.ch}${this.showLang('com.ins.ch')}-${this.showLang('com.date.time')}：${min}，`
                  }
                  if (bright != undefined) {
                    str += `${this.showLang('com.lighting.op')}：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${this.showLang('com.lighting.oc')}：${color}，`;
                  }
                  str += "\n";
                }
              }

              return str;
            }
            case 'getDayPlan2': {
              let str = '';
              if (this.list.filter(el => el.lid == cmd.lid && (this.smallTypeArr.indexOf(el.deviceType) > -1)).length > 0) {
                for (let n = 0; n < 6; n++) {
                  let item = cmd._content.list[n];
                  if (!item) continue;
                  str += this.showLang('com.date.period') + `${n + 1}`;
                  let modeName = ""
                  switch (item.mode) {
                    case 0:
                      modeName = '不启动'
                      break;
                    case 1:
                      modeName = `经纬度-${item.on == 0 ? '日落时间' : '日出时间'}-${item.off == 0 ? "无偏移" : item.off == 1 ? '延迟' : '提早'}`
                      break;
                    case 2:
                      modeName = '时钟:' + Math.floor(item.interval / 60) + ':' + Math.floor(item.interval % 60)
                      break;
                  }
                  str += '模式：' + modeName + '，亮度：' + item.bright + '，联动：' + item.union + "\n"
                }
              } else {
                for (let n = 0; n < 6; n++) {
                  let item = cmd._content[n];
                  if (!item) continue;
                  str += this.showLang('com.date.period') + `${n + 1}：`;
                  let bright = item[`ch${this.cmdData.args.ch}`];
                  let color = item[`c${this.cmdData.args.ch}`];
                  let min = item[`min${this.cmdData.args.ch}`];
                  if (min != undefined) {
                    str += `${this.cmdData.args.ch}${this.showLang('com.ins.ch')}-${this.showLang('com.date.time')}：${min}，`;
                  }
                  if (bright != undefined) {
                    str += `${this.showLang('com.lighting.op')}：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${this.showLang('com.lighting.oc')}：${color}，`;
                  }
                  str += "\n";
                }
              }
              return str;
            }
            case 'getPlanType': {
              let str = ''
              if (this.list.filter(el => el.lid == cmd.lid && (this.smallTypeArr.indexOf(el.deviceType) > -1)).length > 0) {
                str = "1路：" + this.getPlanTypeName(cmd._content.type1) + "，2路：" + this.getPlanTypeName(cmd._content.type2) + "，3路：" + this.getPlanTypeName(cmd._content.type3) + "，4路：" + this.getPlanTypeName(cmd._content.type4)
              } else {
                str = this.getPlanTypeName(cmd._content.type)
              }
              return str
            }
            case 'getDefaultNetwork':
            case 'getNetwork': {
              //"IP:47.119.185.167, 端口:49996, 心跳间隔:undefineds"
              let str = '';
              if (cmd._content.heart != undefined) {
                str += `${this.showLang('com.ins.heartbeat')}:${cmd._content.heart}s，`
              }
              if (cmd._content.apn != undefined) {
                str += `APN:${cmd._content.apn}，`
              }
              if (cmd._content.user != undefined) {
                str += `用户:${cmd._content.user == '' ? '--' : cmd._content.user}`
              }
              return `IP:${cmd._content.host}， ${this.showLang('com.ins.port')}:${cmd._content.port}，` + str
            }
            case 'getLuxOpenValue': return `${this.showLang('com.ins.light.val')}:${cmd._content.lux}`
            case 'getAlarmStatus': {
              return `${this.showLang('com.lighting.V.alarm')}：${cmd._content.voltage ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；\n${this.showLang('com.lighting.I.alarm')}：${cmd._content.current ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；\n${this.showLang('com.lighting.T.alarm')}：${cmd._content.temp ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；\n${this.showLang('com.state.is.gyro.police')}：${cmd._content.gyro ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；\n${this.showLang('com.state.is.leac.police')}：${cmd._content.leac ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；\n${this.showLang('com.state.is.leav.police')}：${cmd._content.leav ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}`
            }
            case 'getLeakageAlarmLevel': {
              return `${this.showLang('com.stat.level1')}：${cmd._content.l1}; ${this.showLang('com.stat.level2')}：${cmd._content.l2}; ${this.showLang('com.stat.level3')}：${cmd._content.l3}`;
            }
            case 'getLeakageAlarmEnable': {
              return `${this.showLang('com.state')}：${cmd._content.enable ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}; ${this.showLang('com.date.check')}：${cmd._content.check}; ${this.showLang('com.ins.record.num')}：${cmd._content.count}`;
            }
            case 'getLeakageAlarmStatus': {
              return `${this.showLang('com.state')}：${this.leakagAlarmeStatus[cmd._content.level]}; ${this.showLang('com.ins.cl.val')}：${cmd._content.val};`;
            }
            case 'getUnionParam': {
              return `
              <p>${this.showLang('com.lighting.delay.lamp1')}：${cmd._content.keep1}s</p>
              <p>${this.showLang('com.lighting.delay.lamp2')}：${cmd._content.keep2}s</p>
              <p>${this.showLang('com.lighting.is.sensing')}：${cmd._content.sensor == 1 ? this.showLang('com.state.effective') : this.showLang('com.state.invalid')}；</p>
              <p>${this.showLang('com.lighting.is.signal')}：${cmd._content.txen > 10 ? this.showLang('com.state.effective') : this.showLang('com.state.invalid')}；</p>
              <p>${this.showLang('com.lighting.send.cycle')}：${cmd._content.period}s</p>
              <p>${this.showLang('com.lighting.linkage.number')}：${cmd._content.rfsn}；</p>
              <p>433${this.showLang('com.lighting.send.power')}：${cmd._content.txpower}；</p>
              <p>433${this.showLang('com.lighting.channel')}：${cmd._content.channel}；</p>
              <p>${this.showLang('com.lighting.response.range')}：${cmd._content.range}；</p>
              <p>${this.showLang('com.lighting.is.put.signal')}：${cmd._content.rxen == 1 ? this.showLang('com.state.receive') : this.showLang('com.state.no.receive')}；</p>
              <p>${this.showLang('com.lighting.linkage.mode')}：${cmd._content.mode == 1 ? this.showLang('com.lighting.Linkage.control') : this.showLang('com.lighting.lamp.contact')}；</p>
              <p>${this.showLang('com.lighting.repeat.num')}：${cmd._content.repeat}；</p>
              `
            }
            case 'get433Args': {
              return `
              1路亮灯延时：${cmd._content.delay1}s
              2路亮灯延时：${cmd._content.delay2}s
              是否允许传感器检测：${cmd._content.senseEn == 1 ? this.showLang('com.state.effective') : this.showLang('com.state.invalid')}；
              检测灵敏度：${cmd._content.senseLevel}s
              检测到后是否允许发送：${cmd._content.txReqEn == 1 ? this.showLang('com.state.effective') : this.showLang('com.state.invalid')};
              检测到后发送数量：${cmd._content.txReqCnt}；
              发送周期：${cmd._content.txDuty}；
              发送不响应期：${cmd._content.txSleep}；
              是否允许接收信号：${cmd._content.rxReqEn == 1 ? this.showLang('com.state.receive') : this.showLang('com.state.no.receive')}；
              发送功率：${cmd._content.txPower}；
              信道：${cmd._content.channel}；
              控制的范围：${cmd._content.range}；
              `
            }
            case 'get433Plan': {
              return `
              一路亮度1：${cmd._content.bright11};
              一路色温1：${cmd._content.color11};
              一路亮度2：${cmd._content.bright12};
              一路色温2：${cmd._content.color12};
              一路亮度3：${cmd._content.bright13};
              一路色温3：${cmd._content.color13};
              一路亮度4：${cmd._content.bright14};
              一路色温4：${cmd._content.color14};
              一路亮度5：${cmd._content.bright15};
              一路色温5：${cmd._content.color15};
              一路亮度6：${cmd._content.bright16};
              一路色温6：${cmd._content.color16};
              二路亮度1：${cmd._content.bright21};
              二路色温1：${cmd._content.color21};
              二路亮度2：${cmd._content.bright22};
              二路色温2：${cmd._content.color22};
              二路亮度3：${cmd._content.bright23};
              二路色温3：${cmd._content.color23};
              二路亮度4：${cmd._content.bright24};
              二路色温4：${cmd._content.color24};
              二路亮度5：${cmd._content.bright25};
              二路色温5：${cmd._content.color25};
              二路亮度6：${cmd._content.bright26};
              二路色温6：${cmd._content.color26};
              `
            }
            case 'get433Id': {
              return `ID: ${cmd._content.id}`;
            }
            case 'getSNID': {
              return `SNID: ${cmd._content.snid}`;
            }
            case 'getRelayLevel': {
              return `${cmd._content.level}`;
            }
            case 'getStationInfo': {
              return `curr_rat：${cmd._content.currRat}，dlBler：${cmd._content.dlBler}，UpBler：${cmd._content.UpBler}，RSRP：${cmd._content.rsrp}，RSRQ：${cmd._content.rsrq}`;
            }
            case 'getAlarmRange': {
              if (cmd.deviceType == 85) {
                return `电压范围：${cmd._content.vLow + '-' + cmd._content.vHigh}V；\n一路电流范围：${cmd._content.cLow1} - ${cmd._content.cHigh1}A；\n一路漏电电压：${cmd._content.leav1}V；\n一路漏电电流：${cmd._content.leac1}mA；\n`
                  + `二路电流范围：${cmd._content.cLow2} - ${cmd._content.cHigh2}A；\n二路漏电电压：${cmd._content.leav2}V；\n二路漏电电流：${cmd._content.leac2}mA；\n`
                  + `温度阈值：${cmd._content.tLow}℃；\n降功率温度阈值：${cmd._content.tHigh}℃`
              } else {
                let str = '';
                if (this.list.filter(el => el.lid == cmd.lid && (this.smallTypeArr.indexOf(el.deviceType) > -1)).length > 0) {
                  let arr = cmd._content;
                  str = `电压范围：${arr.vLow + '-' + arr.vHigh}V,降功率电压：下限：${arr.vLowRdc}V ，${arr.vLowBright != 255 ? "启动-联动值：" + arr.vLowBright + '%' : '不启动'}，上限：${arr.vHighRdc}V ，${arr.vHighBright != 255 ? "启动-联动值：" + arr.vHighBright + '%' : '不启动'}` + "\n"
                  str += `1通道功率：${arr.pLow1 + '-' + arr.pHigh1}%，2通道功率：${arr.pLow2 + '-' + arr.pHigh2}%,3通道功率：${arr.pLow3 + '-' + arr.pHigh3}%,4通道功率：${arr.pLow4 + '-' + arr.pHigh4}%，温度范围：${arr.tLow + '-' + arr.tHigh} ℃，${arr.tlowBright == 255 ? '降功率联动：不启动' : '降功率联动值：' + arr.tlowBright + '%'}` + "\n"
                  if (arr.leakLow != undefined) {
                    str += `漏电电流：${arr.leakLow + '-' + arr.leakHigh}mA,${arr.leakBright != 255 ? "启动-联动值：" + arr.leakBright + '%' : '不启动'}`
                  }
                } else {
                  str = this.showLang('cmd.msg.alarm.range', cmd._content.interval, cmd._content.vLow, cmd._content.vHigh, cmd._content.cLow, cmd._content.cHigh, cmd._content.tLow, cmd._content.tHigh, cmd._content.leac, cmd._content.leav);
                }
                return str
              }
              // return `间隔时间：${cmd.content.interval}分，电压范围：${cmd.content.vLow} - ${cmd.content.vHigh} V，电流范围：${cmd.content.cLow} - ${cmd.content.cHigh} A，温度范围：${cmd.content.tLow} - ${cmd.content.tHigh} ℃`
            }
            case 'getAdjustParams': return `${this.showLang('com.lighting.V.coefficient')}:${cmd._content.u}, ${this.showLang('com.lighting.I.coefficient')}:${cmd._content.i}, ${this.showLang('com.lighting.P.coefficient')}:${cmd._content.p}`;
            case 'getLatLng': return `${this.showLang('com.but.gis.get.result', cmd._content.lng, cmd._content.lat)}`;
            case 'getLatLngArgs': {
              let result = this.showLang('com.ins.ch') + cmd._content.channel + ',';
              result += (cmd._content.enable ? this.showLang('com.state.enable') : this.showLang('com.state.disable')) + ',\n';
              let i = 1;
              for (let item of cmd._content.list) {
                result += this.showLang('com.date.time') + i + ', '
                  + this.showLang('com.lighting.op') + ': ' + item.bright + ', '
                  + this.showLang('com.lighting.oc') + ': ' + item.color + ','
                  + this.showLang('com.date.delayed') + ': ' + item.delay + this.showLang('unit.time.min') + '; \n';
                i++;
              }
              return `${this.showLang('com.but.gis.args.get.result', result)}`;
            }
            case 'getVoltageOutputArgs': {
              let str = '';
              if (this.list.filter(el => el.lid == cmd.lid && (this.smallTypeArr.indexOf(el.deviceType) > -1)).length > 0) {
                str = `调光线:0V输出基准值:${cmd._content.v00}，10V输出基准值：${cmd._content.v10}`;
              } else {
                str = this.getEnumName('voltageOutputArgs', cmd._content.flag)
              }
              return str
            }
            case 'getLoraWork': {
              return `信道:${cmd._content.ch}，速率：${cmd._content.frq}`;
            }
            case 'getRatedPower': {
              let str = ''
              if (cmd._content.power1 != undefined) {
                str = '，1通道额定功率:' + cmd._content.power1
              }
              if (cmd._content.power2 != undefined) {
                str += '，2通道额定功率:' + cmd._content.power2
              }
              if (cmd._content.power3 != undefined) {
                str += '，3通道额定功率:' + cmd._content.power3
              }
              if (cmd._content.power4 != undefined) {
                str += '，4通道额定功率:' + cmd._content.power4
              }
              return this.showLang('com.ins.success') + str;
            }
            case 'getUploadMode': {
              let str = '';
              if (this.list.filter(el => el.lid == cmd.lid && (this.smallTypeArr.indexOf(el.deviceType) > -1)).length > 0) {
                str = `通讯上线上报: ${cmd._content.online ? '开启' : '关闭'},状态变化上报:${cmd._content.state ? '开启' : '关闭'},定时上报:${cmd._content.time}分钟,最小上报间隔:${cmd._content.interval}秒`
              } else {
                str = this.showLang('com.ins.success')
              }
              return str;
            }
            case 'getNetArgs': {
              let str = '';
              if (this.list.filter(el => el.lid == cmd.lid && (this.smallTypeArr.indexOf(el.deviceType) > -1)).length > 0) {
                str = `TCP超时: ${cmd._content.init}分钟,模块复位超时:${cmd._content.reset}秒,发送心跳间隔:${cmd._content.heart}秒`
              } else {
                str = this.showLang('com.ins.success')
              }
              return str;
            }
            case 'getOutputArgs': {
              let str = '';
              if (this.list.filter(el => el.lid == cmd.lid && (this.smallTypeArr.indexOf(el.deviceType) > -1)).length > 0) {
                str = `调光步长: ${cmd._content.step},最小值:${cmd._content.min}`
              } else {
                str = this.showLang('com.ins.success')
              }
              return str;
            }
            case 'getClockArgs': {
              let str = '';
              if (this.list.filter(el => el.lid == cmd.lid && (this.smallTypeArr.indexOf(el.deviceType) > -1)).length > 0) {
                str = `自动校时: ${cmd._content.auto}秒`
              } else {
                str = this.showLang('com.ins.success')
              }
              return str;
            }
            case 'getDCVoltageArgs': {
              return `DC电压参数: ${cmd._content.vol}V`;
            }
            case 'getDCCurrentArgs': {
              return `DC电流参数: ${cmd._content.cur}A`;
            }
            default: return this.showLang('com.ins.success');
          }
      }
    },
    getPlanTypeName(type) {
      switch (type) {
        case 0: return this.showLang('com.but.timing');
        case 1: return this.showLang('com.but.ontime');
        case 2: return this.showLang('com.but.mode.light');
        case 3: return this.showLang('com.but.mode.sigle');
        case 255: return this.showLang('com.but.day.table.no');
        default: return this.showLang('com.but.timing');
      }
    },
    exportStateData: function () {
      // let chks = this.$refs.chkTable.getCheckboxRecords();
      this.$refs.chkTable.openExport()
      // if (chks.length == 0) {
      //   this.$Message.warning(this.showLang('com.ins.ch.select'));
      //   return;
      // }
      // let lids = [];
      // chks.map(p => {
      //   if (lids.indexOf(p.lid) == -1) lids.push(p.lid);
      // });
      // this.exportLoading = true;
      // this.$store.dispatch('auth/reqFile', {
      //   title: '单灯状态数据.xlsx',
      //   url: `//${this.domains.trans}/station/config/ExportSelectedLightState`,
      //   args: { ids: lids },
      // }).then(() => {
      //   // this.exportLoading = false;
      // });
    },
    initList: function () {
      // let url = 'station/config/QueryAllLight';

      let url = 'station/config/QueryAllLightByFilter';
      if (this.appType == 'light') {
        url = 'station/light/QueryLight';
      }
      this.$axios.post(`//${this.domains.trans}/${url}`, this.filter).then(res => {
        if (res.code == 0) {
          this.total = res.data.count;
          this.$set(this, 'lights', res.data.list);

          let maxNum = 1;
          res.data.list.forEach(el => {
            el.lid = el.id;
            el._cmdCode = '';
            el._cmdId = '';
            el._status = 0;
            el._message = '';
            el._content = {};
            let num = 0
            if (el.content.en1 == true) { num++ }
            if (el.content.en2 == true) { num++ }
            if (el.content.en3 == true) { num++ }
            if (el.content.en4 == true) { num++ }
            if (num > 1 && maxNum < num) {
              maxNum = num;
            }
          });
          switch (maxNum) {
            case 1:
              this.rowHeight = 48
              break;
            case 2:
              this.rowHeight = 60
              break;
            case 3:
              this.rowHeight = 90
              break;
            case 4:
              this.rowHeight = 120
              break;
          }
          // this.rowHeight = muti ? 60 : 48;
          this.$set(this, 'list', res.data.list);
          setTimeout(() => {
            this.list.forEach(el => {
              if (this.$refs['name' + el.id] != undefined) {
                this.$refs['name' + el.id].innerHTML = ''
              }
            });
          }, 200);
          // this.spanData();
          this.calcSuccessRate();
          // this.$refs.chkTable.setAllCheckboxRow()
        }
      });
    },
    spanData() {
      if (this.list.length <= 1) return;
      let mergeData = [];
      let cols = this.$refs.chkTable.getColumns();
      let mergeCount = 1, row = 0;
      for (let i = 0; i < this.list.length - 1; i++) {
        if (this.list[i].lid == this.list[i + 1].lid) {
          mergeCount++;
        } else {
          for (let c = 0; c < cols.length; c++) {
            let col = cols[c];
            if (this.spanFields.indexOf(col.field) != -1) {
              mergeData.push({ row: row, col: c, rowspan: mergeCount, colspan: 1 })
            }
          }
          row += mergeCount;
          mergeCount = 1;
        }
      }
      if (mergeCount > 1) {
        for (let c = 0; c < cols.length; c++) {
          let col = cols[c];
          if (this.spanFields.indexOf(col.field) != -1) {
            mergeData.push({ row: row, col: c, rowspan: mergeCount, colspan: 1 })
          }
        }
      }
      this.$set(this, 'mergeData', mergeData);
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 5;
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let sids = [];
      let cmdCode = '';
      for (let d of this.list) {
        if ((d._status == 3 || d._status == 7 || d._status == 8) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, '_status', 0)
          this.$set(d, '_message', this.showLang('com.lighting.repeat.preparation'))
          // sids.push(d.id);
          // this.$set(d, 'cmd', c.code)
          // this.$set(d, 'content', c.content)
          sids.push(d.lid);
          cmdCode = d._cmdCode;
          // sids.push({ id: d.id, code: d.cmd, name: d.name, deviceId: d.devId, args: d.args || {}, checkUserId: d.checkUserId });
        } else if (this.clearOldLog) {
          this.$set(d, '_status', 0)
          this.$set(d, '_message', '')
        }
      }
      let cmd = {
        code: cmdCode,
        list: sids,
        checkUserId: 0,
        args: {}
      }
      setTimeout(() => {
        this.sendCommand(cmd);
      }, 500)
    },
    getCmdCount: async function (item) {
      item.status = 0;
      item.result = this.showLang('com.ins.get.queues.num');
      this.$axios.post(`//${this.domains.trans}/station/command/device`, { type: 'count', deviceId: item.devId }).then(res => {
        if (res.code !== 0) {
          item.status = 3;
          item.result = `${res.data}`;
        } else {
          item.status = 9;
          item.result = this.showLang('com.ins.queues.num') + ":" + res.data;
        }
      })
    },
    clearCmds: async function (item) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.ins.clean.ins'),
        onOk: async () => {
          item.status = 0;
          item.result = this.showLang('com.ins.start.clean');
          this.$axios.post(`//${this.domains.trans}/station/command/device`, { type: 'clear', deviceId: item.devId }).then(res => {
            if (res.code !== 0) {
              item.status = 3;
              item.result = this.showLang('com.ins.fail') + `： ${res.data}`;
            } else {
              item.status = 9;
              item.result = this.showLang('com.ins.clean.success');
            }
          })
        },
      });
    },
    cmdAuthUserChecked: function (params) {
      if (this.checkAuthModalSuccessFunction) {
        this.checkAuthModalSuccessFunction(params);
      }
    },
    checkCmdAuth: function (type, cmdCode, checkOkFun) {
      this.$axios.post(`//${this.domains.trans}/sys/setting/CheckCmdAuth`, { type, code: cmdCode }).then(res => {
        if (res.code != 0) return;
        if (!res.data.need) {
          checkOkFun({ userId: 0 });
          return;
        }
        this.checkAuthModalSuccessFunction = checkOkFun;
        this.checkAuthModalUsers = res.data.users;
        this.showCheckAuthModal = true;
      });
    },
    argsSaved: function (params) {
      this.checkCmdAuth(this.cmdType, params.paramsCode, (checkResult) => {
        this.showArgModal = false;
        if (this.clearOldLog) {
          for (let item of this.list) {
            item._message = '';
            item._status = 0;
          }
        }
        let chks = this.$refs.chkTable.getCheckboxRecords();
        let cmds = []
        let ns = [];
        let code = params.code;
        if (params.code == 'openSingle' || params.code == 'closeSingle') {
          code = 'handSingle';
        }
        for (let chk of chks) {
          ns.push(chk.code);
          chk._message = '';
          chk._status = 0;
          if (!cmds.includes(chk.lid)) cmds.push(chk.lid);
        }
        var cmd = {
          code: code,
          list: cmds,
          args: params.args,
          checkUserId: checkResult.userId
        }
        if (params.code == 'update') {
          this.$Modal.confirm({
            title: this.showLang('com.op.prompt'),
            content: this.showLang('com.ins.upgrade.device', ns),
            onOk: async () => {
              this.sendCommand(cmd);
            },
          });
        } else {
          this.sendCommand(cmd);
        }
      });
    },
    sendCommand: function (cmd) {
      if (cmd.list.length == 0) return;
      this.$set(this, 'cmdResults', {});
      for (let d of this.list) {
        if (cmd.list.includes(d.id)) {
          this.$set(d, '_message', '')
        }
      }
      this.cmdData = cmd;
      this.$axios.post(`//${this.domains.trans}/station/command/SendLampOld`, cmd).then(res => {
        if (res.code == 0) {
          res.data.list.forEach(el => {
            let ds = this.list.filter(p => p.lid == el.id);
            if (ds.length != 0) {
              this.$set(ds[0], '_cmdCode', cmd.code);
              this.$set(ds[0], '_cmdId', el.success ? el.message : '');
              this.$set(ds[0], '_status', el.success ? 1 : 8);
              this.$set(ds[0], '_content', {});
              this.$set(ds[0], '_message', el.success ? '指令已进入发送队列' : el.message);
              if (this.$refs['name' + ds[0].id] != undefined) {
                this.$refs['name' + ds[0].id].innerHTML = ds[0]._message
              }
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.list-common-container {
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.list-common-query {
  /* border: solid 1px red; */
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.transfer-flex {
  width: 50%;
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.alarm-data-area >>> .plTableBox .el-table tr .umy-table-beyond {
  white-space: break-spaces !important;
  color: #324252;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
.split-row-area {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item {
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
  border-top: solid 1px #dcdee2;
}
.split-row-area > div:first-child {
  border-top: none;
}
</style>