import { render, staticRenderFns } from "./Leakage.vue?vue&type=template&id=6d1a9236&scoped=true"
import script from "./Leakage.vue?vue&type=script&lang=js"
export * from "./Leakage.vue?vue&type=script&lang=js"
import style0 from "./Leakage.vue?vue&type=style&index=0&id=6d1a9236&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d1a9236",
  null
  
)

export default component.exports