<template>
  <Modal v-model="showModal" :mask-closable="false" :width="1100">
    <div slot="header">{{item.name}}</div>
    <p style="margin: 5px 20px">{{showLang('com.tab.title')}}：{{item.name}}<span style="margin-left:20px">{{showLang('gis.road')}}：{{selected.pathName}}</span></p>
    <p style="margin: 5px 20px">{{showLang('com.import.cn.lng')}}：{{item.lng}}<span style="margin-left:20px">{{showLang('com.import.cn.lat')}}：{{item.lat}}</span></p>
    <div class="gisButtom">
      <div>
        <template v-for="item in items">
          <a href="javascript:void(0);" v-if="funCodes(item.opcode)" class="control-item" :key="item.code" @click="doPowerCmd(item)">
            <img :src="item.img" />
            {{showLang(item.lang)}}
          </a>
        </template>
      </div>
      <!-- <butListPage :item="item" :refresh="refresh" :selected="selected" @savedShow="savedShow"></butListPage> -->
    </div>
    <div :style="{height: `530px`,overflow:'auto',border:'solid 0 red'}">
      <div style="width: 1000px">
        <TempHistoryData v-if="selected && selected.main" :source="selected" :lat="item.lat" :lng="item.lng" />
      </div>
    </div>
    <ModalControlOutput v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import TempHistoryData from '../search/PowerHistoryItem'
import ModalControlOutput from '@/views/light/road/monitor/station/base/control/ModalControlOutput'
// import butListPage from './butList'
export default {
  name: 'ModalMakerInfo',
  components: {
    // butListPage,
    TempHistoryData,
    ModalControlOutput,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      productId: '',
      selected: {},
      showInfoType: '',
      refresh: 0,
      showArgModal: false,
      cmdItem: {},
      cmdIds: [],
      powerTableActions: {
        0: '关闭',
        1: '开启',
        2: '无效'
      },
      cmdResults: {},
      items: [
        { needArgs: true, needPswd: true, code: 'openControl', opcode: 'dco', lang: 'com.but.switch.control', name: '开关控制', img: this.img.light.map.button.switch },
        { needArgs: false, needPswd: false, code: 'forceRead', opcode: 'dcs', lang: 'com.but.survey', name: '召测数据', img: this.img.light.map.button.survey },
        { needArgs: false, needPswd: false, code: 'getclock', opcode: 'dcs', lang: 'com.but.get.read.clock', name: '读取时钟', img: this.img.light.map.button.read },
        { needArgs: false, needPswd: true, code: 'setclock', opcode: 'dcc', lang: 'com.but.get.Calibrate', name: '校准时钟', img: this.img.light.map.button.correct },
        { needArgs: true, needPswd: false, code: 'getYearPlan', opcode: 'dcs', lang: 'com.but.get.time.tab', name: '读取时间表', img: this.img.light.map.button.time },
        { needArgs: true, needPswd: true, code: 'setYearPlan', opcode: 'dcc', lang: 'com.but.set.time.tab', name: '下发时间表', img: this.img.light.map.button.Issued },
        { needArgs: true, needPswd: true, code: 'pointToStation', opcode: 'dcc', lang: 'com.but.point.to.tree', name: '定位到左树', img: this.img.light.map.button.Issued },
      ],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.getItemInfo();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    cmdResultRefresh() {
      this.cmdResultChanged();
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('common', ['weeks', 'productCodes', 'deviceTypes', 'monitorActionTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['showSpin', 'stationTabName']),
    currentStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      }
      return null;
    },
  },
  mounted: function () {
    this.powerTableActions = {
      0: this.showLang('com.op.close'),// '关闭',
      1: this.showLang('com.state.open'),// '开启',
      2: this.showLang('com.state.invalid'),// '无效'
    }
    window.eventBus.$on('paramCommandComing', params => {
      if (this.cmdResults.cmdId != params.commandId) return;
      this.cmdResults.status = params.status;
      this.cmdResults.message = params.message;
      this.cmdResults.content = params.content;

      let contents = [];
      if (this.stationTabName != 'light') {
        contents.push(this.getCmdStatus(this.cmdResults));
      } else {
        this.$refs.lightList1.setCmdContent(this.cmdResults.cmdId, this.cmdResults, this.getCmdStatus(this.cmdResults));
      }
      if (this.stationTabName != 'light') {
        let content = contents.join(' ');
        this.$store.commit('auth/hideSpin');
        setTimeout(() => {
          this.$Modal.success({
            title: this.showLang('com.ins.result'),
            content: content
          });
        }, 500);
      }
    })
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
  },
  methods: {
    doPowerCmd: function (cmd) {
      if (cmd.code == 'pointToStation') {
        this.$store.commit('group/selectNodeById', { nodeId: this.item.id, type: this.productCodes.station });
        this.$Message.info('已定位');
        return;
      }
      let devs = [];
      devs.push(this.selected.main.id)
      if (devs.length == 0) {
        this.$Modal.error({
          title: this.showLang('com.ins.arrange.wrong'),
          content: this.showLang('com.ins.no.attribute.device')
        });
        return;
      }
      if (cmd.needArgs || cmd.needPswd) {
        cmd.type = 'power';
        cmd.devs = devs;
        this.cmdItem = cmd;
        this.showArgModal = true;
        this.savedShow({ cmd: cmd, type: false });
        // this.$emit('savedShow', { cmd: cmd, type: false });
      } else {
        // let cmds = [{ id: devs[0], code: cmd.code, name: cmd.name, deviceId: devs[0], args: {}, source: "gis" }];
        let cmds = {
          "code": cmd.code,
          "list": [devs[0]],
          "checkUserId": 0,
          "args": {}
        }
        this.$set(this, 'cmdIds', [devs[0]]);
        // this.$emit('savedShow', { cmd: this.cmdIds, type: true });
        this.savedShow({ cmd: this.cmdIds, type: true });
        this.$store.commit('auth/showSpin', { content: this.showLang('com.ins.send.wait.reply'), timeout: 20 });
        this.sendPowerboxOld(cmds)
        // this.$store.dispatch('cmd/sendCommands', cmds, "/gis/bugtton").then(res => {
        //   if (res.code != 0) {
        //     this.$Modal.error({
        //       title: this.showLang('com.ins.sending.error'),
        //       content: res.data
        //     });
        //   }
        // });
      }
    },
    isDateForm() {
      if (this.selected.light.lastData && this.selected.light.lastData.time) {
        return new Date(this.selected.light.lastData.time).format('yyyy-MM-dd HH:mm:ss');
      } else {
        return "--"
      }

    },
    savedShow(cmd) {
      if (cmd.type) {
        this.$set(this, 'cmdIds', cmd.cmd);
      } else {
        this.$set(this, "cmdItem", cmd.cmd);
        this.showArgModal = true;
      }

    },
    getCmdStatus: function (cmd) {
      let p = '<p>', p1 = '</p>';
      if (this.stationTabName == 'light') {
        p = '';
        p1 = ' ';
      }
      let content = p + this.showLang('com.ins.create') + p1;
      ////status: 0、等待发送，1、等待回复，2、指令超时，3、执行失败，4、执行中，5、失败重发，9、执行成功
      if (cmd.status == 1) {
        if (cmd.args.channel) {
          content = p + this.showLang('com.ins.ch.reply', cmd.args.channel) + p1;
        } else {
          content = p + this.showLang('com.ins.dev.reply') + p1;
        }
      }
      else if (cmd.status == 3) {
        if (cmd.args.channel) {
          content = p + this.showLang('com.ins.ch') + 'K' + cmd.args.channel + this.showLang('com.ins.fail') + ':' + cmd.result + p1;
        } else {
          content = `${p}${this.showLang('com.ins.fail')}：${cmd.result}${p1}`
        }
      } else if (cmd.status == 4) {
        if (cmd.args.channel) {
          content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.execution')}：${cmd.content.pkg} / ${cmd.content.total}${p1}`;
        } else {
          content = `${p}${this.showLang('com.ins.execution')}：${cmd.content.pkg} / ${cmd.content.total}${p1}`
        }
      } else if (cmd.status == 5) {
        if (cmd.args.channel) {
          content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.fail.retry')}：${cmd.result}${p1}`;
        } else {
          content = `${p}${this.showLang('com.ins.fail.retry')}：${cmd.result}${p1}`;
        }
      } else if (cmd.status == 9) {
        if (cmd.code == "getTimeTable") {
          let day = "day" + cmd.args.day;
          let con = cmd.content[day];
          //{0: '关闭', 1: '开启', 2: '无效'},
          content = `${p} ${this.showLang('com.ins.timeTable')}：${cmd.args.month}${this.showLang('com.date.month')}${cmd.args.day}${this.showLang('com.date.sun')}<br> ${con.t1} - ${this.powerTableActions[con.a1]}<br>${con.t2} - ${this.powerTableActions[con.a2]}<br> ${con.t3} - ${this.powerTableActions[con.a3]}<br> ${con.t4} - ${this.powerTableActions[con.a4]}${p1}`;
        } else if (cmd.code == 'forceRead') {
          this.getItemInfo();
          content = `${p}${this.showLang('com.ins.success')}${p1}`;
        } else if (cmd.code == "getclock") {
          content = `${p}${this.showLang('com.ins.dev.currentTime')}: ${cmd.content.nowTime}${p1}`;
        } else if (cmd.code == "setclock") {
          let myDate = new Date();
          content = `${p}${this.showLang('com.but.get.Calibrate')}: ${myDate.format('yyyy-MM-dd HH:mm:ss')}${p1}`;
        } else if (cmd.code == 'getYearPlan') {
          // console.log('get year plan', cmd)
          if (cmd.args.month != cmd.content.month) {
            content = `${p}${this.showLang('com.ins.success.WrongMonth', cmd.args.month)}:${cmd.content.month}${p1}`;
          } else {
            let strs = [];
            let obj = cmd.content[`day${cmd.args.day}`];
            if (cmd.content.mode == 4) { //北京模式
              for (let i = 1; i <= 4; i++) {
                let ao = this.powerTableActions[obj[`a${i}`]];
                let to = obj[`o${i}`];
                // let ac = this.powerTableActions[obj[`ac${i}`]];
                let tc = obj[`c${i}`];
                strs.push(`${p}${i}、${to}-${tc}:${ao}${p1}`);
              }
            } else if (cmd.content.mode == 5) { //集中器模式
              for (let i = 1; i <= 6; i++) {
                let a = obj[`a${i}`]; //this.monitorActionTypes[];
                let t = obj[`t${i}`];
                let v = this.monitorActionTypes[a]; //a == 1 ? '' : obj[`v${i}`];
                if (a != 1) {
                  v += ` - ${obj[`v${i}`]}`
                }
                strs.push(`${p}${i}、${t} - ${v}${p1}`);
              }
            } else {
              for (let i = 1; i <= 4; i++) {
                let a = this.powerTableActions[obj[`a${i}`]];
                let t = obj[`t${i}`];
                strs.push(`${p}${i}、${t} - ${a}${p1}`);
              }
            }
            content = `${p}${this.showLang('com.ins.ch')}K${cmd.content.channel}${this.showLang('com.ins.success')}：${cmd.args.month}${this.showLang('com.date.month')}${cmd.args.day}${this.showLang('com.date.sun')}${this.showLang('com.date.plan')}：${strs.join(' ')}${p1}`
          }
        } else if (cmd.code == 'getInfo') {
          content = `${p}${this.showLang('com.site.hw')}：${cmd.content.hardware}${p1}${p}${this.showLang('com.site.sw')}：${cmd.content.software}${p1}${p}${this.showLang('com.user.manufacturer')}：${cmd.content.manufacture}${p1}`
        } else if (cmd.code == 'getSimInfo') {
          content = `${p}ICCID: ${cmd.content.iccid}${p1}${p}IMEI: ${cmd.content.imei}${p1}${p}RSSI: ${cmd.content.rssi}${p1}`
        } else if (cmd.code == 'getNetwork') {
          content = `${p}IP:${cmd.content.host}${p1}${p}${this.showLang('com.ins.port')}:${cmd.content.port}${p1}${p}${this.showLang('com.ins.heartbeat')}:${cmd.content.heart}s${p1}`
        } else if (cmd.code == 'getDayPlan1') {
          content = '';
          for (let n = 1; n <= 6; n++) {
            content += `${p}${this.showLang('com.date.period')}${n}：${cmd.content[`enable1${n}`] ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；${p1}${p}${this.showLang('com.date.time')}：${cmd.content[`expire1${n}`]}min,1${this.showLang('com.ins.ch.light')}：${cmd.content[`bright1${n}`]}，1${this.showLang('com.ins.ch.color')}：${cmd.content[`color1${n}`]},2${this.showLang('com.ins.ch.light')}：${cmd.content[`bright2${n}`]}，2${this.showLang('com.ins.ch.color')}：${cmd.content[`color2${n}`]};${p1}`
          }
        } else if (cmd.code == 'getDayPlan2') {
          content = '';
          for (let n = 1; n <= 6; n++) {
            content += `${p}${this.showLang('com.date.period')}${n}：${cmd.content[`enable1${n}`] ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；${p1}${p}${this.showLang('com.date.time')}：${cmd.content[`start1${n}`]},1${this.showLang('com.ins.ch.light')}：${cmd.content[`bright1${n}`]}，1${this.showLang('com.ins.ch.color')}：${cmd.content[`color1${n}`]},2${this.showLang('com.ins.ch.light')}：${cmd.content[`bright2${n}`]}，2${this.showLang('com.ins.ch.color')}：${cmd.content[`color2${n}`]};${p1}`
          }
        } else if (cmd.code == 'getGroup') {
          content = `${p}${this.showLang('com.group.number')}: ${cmd.content.no}${p1}`
        } else if (cmd.code == 'getPlanType') {
          content = `${p}${this.showLang('com.but.tableType')}: ${cmd.content.type == 0 ? this.showLang('com.but.timing') : this.showLang('com.but.ontime')}`
        } else if (cmd.code == 'getAlarmRange') {
          content = `${this.showLang('com.lighting.V.min')}：${cmd.content.vLow}；
                ${this.showLang('com.lighting.V.max')}：${cmd.content.vHigh}；
                ${this.showLang('com.lighting.I.min')}：${cmd.content.cLow}；
                ${this.showLang('com.lighting.I.max')}：${cmd.content.cHigh}；
                ${this.showLang('com.lighting.T.min')}：${cmd.content.tLow}；
                ${this.showLang('com.lighting.T.max')}：${cmd.content.tHigh}；
                ${this.showLang('com.ins.escalation.heartbeat')}：${cmd.content.interval}分；`
        } else if (cmd.code == 'getUnionParam') {
          content = `${this.showLang('com.lighting.linkage.number')}：${cmd.content.rfsn}；
                433${this.showLang('com.lighting.channel')}：${cmd.content.channel}；
                433${this.showLang('com.lighting.send.power')}：${cmd.content.txpower}；
                ${this.showLang('com.lighting.is.signal')}：${cmd.content.txen}；
                ${this.showLang('com.lighting.repeat.num')}：${cmd.content.repeat}；
                ${this.showLang('com.lighting.send.cycle')}：${cmd.content.period}；
                ${this.showLang('com.lighting.response.range')}：${cmd.content.range}；
                ${this.showLang('com.lighting.is.put.signal')}：${cmd.content.rxen}；
                ${this.showLang('com.lighting.linkage.mode')}：${cmd.content.mode}；
                ${this.showLang('com.lighting.delay.lamp1')}：${cmd.content.keep1}；
                ${this.showLang('com.lighting.delay.lamp2')}：${cmd.content.keep2}；
                ${this.showLang('com.lighting.is.sensing')}：${cmd.content.sensor ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；`
        } else if (cmd.code == 'getAlarmStatus') {
          content = `${this.showLang('com.lighting.V.alarm')}：${cmd.content.voltage ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；
                ${this.showLang('com.lighting.I.alarm')}：${cmd.content.current ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；
                ${this.showLang('com.lighting.T.alarm')}：${cmd.content.temp ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}；`
        } else if (cmd.code == 'getGyroLevel') {
          content = `${this.showLang('com.but.tiltThreshold')}：${cmd.content.level}；`
        } else if (cmd.args.channel) {
          content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.success')}${p1}`;

        } else {
          content = `${p}${this.showLang('com.ins.success')}${p1}`;
        }
      }
      return content;
    },
    cmdResultChanged: function () {
      let cmds = this.commands.filter(p => this.cmdIds.indexOf(p.id) != -1);
      if (cmds.length == 0) return;
      let contents = [];
      for (let cmd of cmds) {
        contents.push(this.getCmdStatus(cmd));

      }
      let content = contents.join(' ');
      this.$store.commit('auth/hideSpin');
      setTimeout(() => {
        this.$Modal.success({
          title: this.showLang('com.ins.result'),
          content: content
        });
      }, 500);
    },
    getBoxDeviceIds: function () {
      if (!this.currentStation) return [];
      return this.currentStation.deviceIds;
    },
    argsSaved: function (params) {
      // let devs = this.getBoxDeviceIds();
      let devId = params.cmd.devs[0];
      let cmds = {}, cmdids = [];
      if (params.cmd.type == 'power') {
        if (params.cmd.code == 'openControl') {
          cmds = {
            code: 'handControl',
            list: [],
            args: {
              enable: true,
              open: params.args.open,
              point: false,
              expire: params.args.expire,
            },
            checkUserId: 0,
          };
          for (let ch of params.args.params) {
            cmds.list.push(ch);
          }
          // for (let ch of params.args.channels) {
          //   cmds = {
          //     "code": "handControl", "list": [params.args.params[0]], "checkUserId": 0, "args": {
          //       channel: ch,
          //       open: params.args.open,
          //       point: params.args.locks.indexOf(ch) != -1,
          //       expire: params.args.expire,
          //     }
          //   }
          // }
        } else if (params.cmd.code == 'getYearPlan') {
          // for (let ch of params.args.channels) {
          //   cmds = {
          //     "code": "getYearPlan",
          //     "list": [params.args.channels[0]],
          //     "checkUserId": 0,
          //     "args": {
          //       channel: ch,
          //       month: params.args.month,
          //       day: params.args.day,
          //     }
          //   }
          // }
          cmds = {
            code: 'getYearPlan',
            list: [],
            args: {
              month: params.args.month,
              day: params.args.day,
            },
            checkUserId: 0,
          };
          cmds.list.push(params.args.channels);
        } else if (params.cmd.code == 'setYearPlan') {
          let idx = 1;
          let cmdid = `setYearPlan${idx++}`;
          cmdids.push(cmdid);
          cmds = {
            code: 'setYearPlan',
            list: [],
            args: {
            },
            checkUserId: 0,
          };
          cmds.args.channel = params.args.channel;
          cmds.list.push(params.args.channels);
        } else if (params.cmd.code == 'update') {
          cmds = { "code": "update", "list": [devId], "checkUserId": 0, "args": params.args }
        } else if (params.cmd.code == 'setNetwork') {
          cmds = { "code": "setNetwork", "list": [devId], "checkUserId": 0, "args": params.args }
        } else {
          cmds = { "code": params.cmd.code, "list": [devId], "checkUserId": 0, "args": params.args }
        }
        if (cmds.length == 0) {
          this.$Modal.error({
            title: this.showLang('com.ins.generate'),
            content: this.showLang('com.ins.no.generate')
          });
          return;
        }
        if (params.cmd.code == 'update') {
          this.$Modal.confirm({
            title: this.showLang('com.op.prompt'),
            content: this.showLang('com.ins.upgrade.device', this.currentStation.station.name),
            onOk: async () => {
              this.$set(this, 'cmdIds', cmdids);
              this.$store.commit('auth/showSpin', { content: this.showLang('com.ins.send.wait.reply'), timeout: 20 });
              this.sendPowerboxOld(cmds)
            },
          });
        } else {
          this.$set(this, 'cmdIds', cmdids);
          this.$store.commit('auth/showSpin', { content: this.showLang('com.ins.send.wait.reply'), timeout: 20 });
          this.sendPowerboxOld(cmds)
        }

      } else if (params.cmd.type == 'light') {
        let chks = [this.selected];
        let cmds = {}
        let ns = [];
        for (let chk of chks) {
          ns.push(chk.name);
          chk.result = '';
          chk.status = 0;
          let args = this.clone(params.args);// {channel: chk.channel}
          let code = params.cmd.code;
          if (params.cmd.code == 'openSingle' || params.cmd.code == 'closeSingle') {
            code = 'handSingle';
          } else if (params.cmd.code == 'handControl') {
            args.point = false;
          } else if (params.cmd.code == 'setYearPlan') {
            args.timeId = chk.timeId;
          } else if (params.cmd.code == 'update') {
            args.flag = 1;
          }
          cmdids.push(chk.id);
          cmds = {
            "code": code,
            "list": [chk.id],
            "checkUserId": 0,
            "args": args
          }
        }
        if (params.cmd.code == 'update') {
          this.$Modal.confirm({
            title: this.showLang('com.op.prompt'),
            content: this.showLang('com.ins.upgrade.device', ns),
            onOk: async () => {
              this.$set(this, 'cmdIds', cmdids);
              this.sendPowerboxOld(cmds)
            },
          });
        } else {
          this.$set(this, 'cmdIds', cmdids);
          this.sendPowerboxOld(cmds)
        }
      }
    },
    sendPowerboxOld(cmd) {
      //SendOutputOld 读取时间表 下发时间表 开关控制
      //CheckCmdAuth  校准时钟
      //SendPowerboxOld  读取时钟 召测数据
      let ajaxUrl = '';
      switch (cmd.code) {
        case 'handControl':
          ajaxUrl = `//${this.domains.trans}/station/command/SendOutputOld`
          break;
        case 'getYearPlan':
          ajaxUrl = `//${this.domains.trans}/station/command/SendOutputOld`
          break;
        case 'setYearPlan':
          ajaxUrl = `//${this.domains.trans}/station/command/SendOutputOld`
          break;
        case 'setclock':
          ajaxUrl = `//${this.domains.trans}/station/command/SendPowerboxOld`
          break;
        case 'getclock':
          ajaxUrl = `//${this.domains.trans}/station/command/SendPowerboxOld`
          break;
        case 'forceRead':
          ajaxUrl = `//${this.domains.trans}/station/command/SendPowerboxOld`
          break;
      }
      this.$axios.post(ajaxUrl, cmd).then(res => {
        if (res.code == 0) {
          for (let item of res.data.list) {
            this.$set(this.cmdResults, 'code', cmd.code);
            this.$set(this.cmdResults, 'args', cmd.args);
            this.$set(this.cmdResults, 'cmdId', item.success ? item.message : '');
            this.$set(this.cmdResults, 'status', item.success ? 1 : 8);
            this.$set(this.cmdResults, 'content', {});
            this.$set(this.cmdResults, 'message', item.success ? '指令已进入发送队列' : item.message);
            if (!item.success) {
              this.$store.commit('auth/hideSpin');
              setTimeout(() => {
                this.$Modal.success({
                  title: this.showLang('com.ins.result'),
                  content: item.message
                });
              }, 500);
            }
          }
        }
      });
    },
    getItemInfo: function () {
      this.$axios.post(`//${this.domains.trans}/station/gis/MakerInfo`, { type: this.item.type, id: this.item.id }).then(res => {
        if (res.code == 0) {
          if (this.item.type == 0) {
            for (let m of res.data.mains) {
              m.cfg = m.content;
              m.content = m.lastData;
            }
            for (let m of res.data.switchs) {
              m.cfg = m.content;
              m.content = m.lastData;
            }
            for (let m of res.data.outputs) {
              m.cfg = m.content;
              m.content = m.lastData;
            }
            for (let m of res.data.contacts) {
              m.cfg = m.content;
              m.content = m.lastData;
            }
            for (let m of res.data.branchs) {
              m.cfg = m.content;
              m.content = m.lastData;
            }
            for (let m of res.data.doors) {
              m.cfg = m.content;
              m.content = m.lastData;
            }
            for (let m of res.data.locks) {
              m.cfg = m.content;
              m.content = m.lastData;
            }
            for (let m of res.data.smokes) {
              m.cfg = m.content;
              m.content = m.lastData;
            }
            for (let m of res.data.waters) {
              m.cfg = m.content;
              m.content = m.lastData;
            }
            if (res.data.mains.length > 0) {
              res.data.main = res.data.mains[0];
            }
          }
          this.$set(this, 'selected', res.data);
          this.refresh = Math.ceil(Math.random() * 10000);
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.control-item {
  height: 36px;
  background: #3880fc;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  margin: 10px 20px 10px 0;
  padding: 0 18px;
}
.control-item img {
  display: inline-block;
  vertical-align: middle;
}
.light-table td {
  padding: 5px 0;
  height: 40px;
  white-space: nowrap;
  border-collapse: collapse;
  text-align: center;
}
.light-table tr {
  background: #f7f9fd;
}
.light-table tr:nth-child(odd) {
  background: #e5edf9;
}
.light-table td.blue {
  color: #3880fc;
}
.light-table td.bold {
  color: #021629;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.light-table {
  width: 100%;
}
</style>