<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        <!-- {{showLang('com.device.box.own')}}：
        <Select :placeholder="showLang('save.select')" v-model="form.stationId" style="width: 150px; margin-right: 10px;">
          <template v-for="(item, idx) in selectedStations">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        {{showLang('com.device.lamp')}}：
        <Select :placeholder="showLang('save.select')" v-model="form.lightId" style="width: 250px; margin: 0 10px">
          <Option :value="l.id" v-for="l in lights" :key="l.id">{{l.name}}</Option>
        </Select> -->
        {{showLang('com.export.cn.connid')}}：
        <Input v-model="form.code" style="width: 200px; margin: 0 10px;"></Input>
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes('slhv')" type="info" :loading="loading" @click="getList" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
        <Button type="success" @click="exportData" style="margin-right: 10px;">{{showLang('com.op.export')}}</Button>
        <Button v-if="funCodes('slhd')" type="error" :loading="loading" @click="deleteLogs" style="margin-right: 10px;">{{showLang('com.op.del.time')}}</Button>
        <Button v-if="funCodes('slhd')" type="error" :loading="loading" @click="deleteLog" style="margin-right: 10px;">{{showLang('com.op.del.select')}}</Button>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border align="center" resizable show-overflow show-header-overflow keep-source ref="chkTable" :export-config="{}" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="history" height="600" :row-config="{isHover: true,height: rowHeight}" :checkbox-config="{checkField: 'checked',highlight: true}" :merge-cells="mergeData">
        <vxe-column field="id" title="id" width="80">
          <template #default="{ row }">
            {{row.id}}
          </template>
        </vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="fireTime" :title="showLang('com.date.time')" width="200">
          <template #default="{ row }">
            {{row.fireTime}}
          </template>
        </vxe-column>
        <vxe-column field="deviceCode" :title="showLang('com.export.cn.connid')" width="120">
          <template #default="{ row }">
            {{row.deviceCode}}
          </template>
        </vxe-column>
        <vxe-column field="stationName" :title="showLang('com.device.box.own')" width="120">
          <template #default="{ row }">
            {{row.stationName}}
          </template>
        </vxe-column>
        <vxe-column field="paramName" :title="showLang('com.device.lamp')+showLang('com.tab.title')" width="120">
          <template #default="{ row }">
            {{row.paramName}}
          </template>
        </vxe-column>
        <vxe-column field="en" :title="showLang('com.ins.ch')" width="120">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.en1" :class="['split-row-item', row.en1 && row.en2 ? 'split-row-flag' : '']">1{{showLang('op.road')}}</div>
              <div v-if="row.en2" class="split-row-item ">2{{showLang('op.road')}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="u" :title="showLang('com.lighting.V')+'(V)'" width="90">
          <template #default="{ row }">
            {{img.val2(row.u,'u')}}
          </template>
        </vxe-column>
        <vxe-column field="c" :title="showLang('com.lighting.I')+'(A)'" width="90">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.en1" :class="['split-row-item', row.en1 && row.en2 ? 'split-row-flag' : '']"> {{img.val2(row.c1,'c')}}</div>
              <div v-if="row.en2" class="split-row-item ">{{img.val2(row.c2,'c')}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="p" :title="showLang('com.lighting.P')+'(W)'" width="90">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.en1" :class="['split-row-item', row.en1 && row.en2 ? 'split-row-flag' : '']">{{row.p1}}</div>
              <div v-if="row.en2" class="split-row-item ">{{row.p2}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="q" :title="showLang('com.stat.q')+'(KWh)'" width="120">
          <template #default="{ row }">
            {{row.q1}}
          </template>
        </vxe-column>
        <vxe-column field="op" :title="showLang('com.lighting.op')+'(%)'" width="90">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.en1" :class="['split-row-item', row.en1 && row.en2 ? 'split-row-flag' : '']">{{row.op1}}</div>
              <div v-if="row.en2" class="split-row-item ">{{row.op2}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="cl" :title="showLang('com.lighting.cl')+'(mA)'" width="120">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.en1" :class="['split-row-item', row.en1 && row.en2 ? 'split-row-flag' : '']">{{img.val2(row.cl1,'cl')}}</div>
              <div v-if="row.en2" class="split-row-item ">{{img.val2(row.cl2,'cl')}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="oc" :title="showLang('com.lighting.oc')+'(%)'" width="90">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.en1" :class="['split-row-item', row.en1 && row.en2 ? 'split-row-flag' : '']">{{img.val2(row.oc1,'t')}}</div>
              <div v-if="row.en2" class="split-row-item ">{{img.val2(row.oc2,'t')}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="dc" :title="showLang('com.lighting.dc')+'(A)'" width="120">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.en1" :class="['split-row-item', row.en1 && row.en2 ? 'split-row-flag' : '']">{{img.val2(row.dc1,'c')}}</div>
              <div v-if="row.en2" class="split-row-item ">{{img.val2(row.dc1,'c')}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="dv" :title="showLang('com.lighting.dv')+'(V)'" width="120">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-if="row.en1" :class="['split-row-item', row.en1 && row.en2 ? 'split-row-flag' : '']">{{img.val2(row.dv1,'u')}}</div>
              <div v-if="row.en2" class="split-row-item ">{{img.val2(row.dv2,'u')}}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="tc" :title="showLang('com.lighting.T')+'(℃)'" width="80">
          <template #default="{ row }">
            {{img.val2(row.tc,'t')}}
          </template>
        </vxe-column>
        <vxe-column field="sun" :title="showLang('com.lighting.sun')+'(V)'" width="120">
          <template #default="{ row }">
            {{row.sun==-1?'-':row.sun}}
          </template>
        </vxe-column>
        <vxe-column field="rssi" :title="showLang('com.lighting.rssi')+'(dBm)'" width="80">
          <template #default="{ row }">
            {{img.val(row.rssi)}}
          </template>
        </vxe-column>
        <vxe-column field="gx" :title="showLang('com.data.x')" width="80">
          <template #default="{ row }">
            {{img.val(row.gx)}}
          </template>
        </vxe-column>
        <vxe-column field="gy" :title="showLang('com.data.y')" width="80">
          <template #default="{ row }">
            {{img.val(row.gy)}}
          </template>
        </vxe-column>
        <vxe-column field="gz" :title="showLang('com.data.z')" width="80">
          <template #default="{ row }">
            {{img.val(row.gz)}}
          </template>
        </vxe-column>

      </vxe-table>
      <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']" :current-page.sync="form.index" :page-size.sync="form.size" :total="total" @page-change="handlePageChange" :page-sizes='pageSizes'> </vxe-pager>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'SearchPowerboxIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      // tempHistoryData: TempHistoryData,
      pageSizes: [20, 100, 500, 1000, 5000],
      pos: {},
      tabHead: { //头部标题
        name: "",
        sex: "",
        age: "",
      },
      rowHeight: 48,
      form: {
        stationId: 0,
        lightId: 0,
        code: '',
        start: "",
        end: "",
        "size": 20,
        "index": 1,
      },
      total: 0,
      viewCode: '',
      showTable: true,
      history: [],
      searchLight: null,
      dataRefresh: 0,
      tabData: [],
      mergeData: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    selectedNode() {
      this.setDefaultStation();
      this.getList()
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 1);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.setDefaultStation();
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.form.index = currentPage
      this.form.size = pageSize
      this.getList()
    },
    deleteLog: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      // let list = chks.map(p => p.id);
      let ids = [];
      for (let item of chks) {
        if (ids.filter(p => p.id == item.id).length > 0) continue;
        if (chks.filter(p => p.id == item.id).length == 2) {
          ids.push({ id: item.id, time: item.fireTime });
        }
      }
      let that = this;
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/param/DeleteHistory`, { paramId: this.form.lightId, ids: ids }).then(res => {
            if (res.code == 0) {
              that.getList();
              setTimeout(() => {
                that.$Modal.info({
                  title: this.showLang('com.tips.del.success'),
                  content: this.showLang('com.tips.del.num.record', res.data),
                })
              }, 800);
            }
          });
        }
      });
    },
    deleteLogs: function () {
      let start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      let end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let that = this;
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.num.record', start, end),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/param/RemoveHistory`, { paramId: this.form.lightId, start: start, end: end }).then(res => {
            if (res.code == 0) {
              setTimeout(() => {
                that.$Modal.info({
                  title: this.showLang('com.tips.del.success'),
                  content: this.showLang('com.tips.del.num.record', res.data),
                })
              }, 800);
            }
          });
        }
      });
    },

    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 25;
    },
    setDefaultStation: function () {
      if (this.selectedStations.length == 0) {
        this.form.stationId = 0;
        return;
      }
      if (this.form.stationId == 0) {
        this.form.stationId = this.selectedStations[0].id;
        return;
      }
      let ss = this.selectedStations.filter(p => p.id == this.form.stationId)
      if (ss.length == 0) {
        this.form.stationId = this.selectedStations[0].id;
      }
    },
    exportData() {
      this.$refs.chkTable.openExport()
    },
    getList() {
      if(this.form.code == ''){
        return this.$Message.warning(this.showLang('save.light.code.need'));
      }
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { code: this.form.code, start: this.form.start, end: this.form.end, size: this.form.size, index: this.form.index };
      this.$axios.post(`//${this.domains.trans}/station/search/QueryLightHistory`, params).then(res => {
        this.loading = false;
        if (res.code == 0) {
          let muti = false;
          res.data.list.forEach(el => {
            if (el.en1 && el.en2) {
              muti = true;
            }
          });
          this.rowHeight = muti ? 60 : 48;
          this.$set(this, 'history', res.data.list);
          this.total = res.data.count;
          // this.makeData(res.data.list)
        }
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.tabData.forEach((el, index) => {
        if (el.lid == this.tabData[rowIndex].lid && index != rowIndex) {
          el.checked = this.tabData[rowIndex].checked
        }
      });
      this.$refs.chkTable.reloadRow(this.tabData, null, rowIndex)
    },
    spanData() {
      this.mergeData = [];
      let spanCols = [{
        name: this.showLang('com.export.cn.seq'),
        col: 0
      }, {
        name: this.showLang('com.op.select'),
        col: 1
      }, {
        name: this.showLang('com.date.time'),
        col: 2
      }, {
        name: this.showLang('com.device.pole.name'),
        col: 3
      }, {
        name: this.showLang('com.lighting.V') + '(V)',
        col: 5
      }, {
        name: this.showLang('com.stat.q') + '(KWh)',
        col: 9
      }];
      if (this.tabData.length < 2) {
        return false;
      }
      let data = "";
      let index = { row: 1, col: 1, rowspan: 1, colspan: 1 }
      for (let i = 0; i < this.tabData.length; i++) {
        if (this.tabData[i + 1] && this.tabData[i].lid == this.tabData[i + 1].lid) {
          if (index.rowspan == 1) {
            index.row = i;
          }
          index.rowspan++;
        } else {
          if (index.rowspan > 1) {
            for (let p = 0; p < spanCols.length; p++) {
              index.col = spanCols[p].col;
              data = JSON.parse(JSON.stringify(index));
              this.mergeData.push(data)
            }
          }
          index.rowspan = 1;
        }
      }
      this.dataRefresh++;
    },
    makeData(data) {
      let arr = [];
      data.forEach((el, index) => {
        let oneOb = {
          id: el.id,
          lid: index,
          // name: this.searchLight.name,
          // en: this.searchLight.content.en1 ? this.searchLight.content.nm1 : '--',
          fireTime: new Date(el.fireTime).format('yyyy-MM-dd HH:mm:ss'),
          u: el.content.u,
          c: this.img.val(el.content.c1, 2),
          p: el.content.p1,
          op: el.content.op1,
          q: el.content.q1,
        }
        let towOb = {
          id: el.id,
          lid: index,
          // name: this.searchLight.name,
          // en: this.searchLight.content.en2 ? this.searchLight.content.nm2 : '--',
          fireTime: new Date(el.fireTime).format('yyyy-MM-dd HH:mm:ss'),
          u: el.content.u,
          c: el.content.c2,
          p: el.content.p2,
          op: el.content.op2,
          q: el.content.q1,
        }
        arr.push(oneOb)
        arr.push(towOb)
      });
      this.$set(this, 'tabData', arr);
      this.spanData();
    },
  }
}
</script>
<style scoped>
.active {
  background-color: #f2f6fa;
}
td {
  height: 40px;
  white-space: nowrap;
  width: 120px;
  border: solid 1px #cfe3f7;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  overflow-y: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.table-data {
  overflow: auto;
}
.split-row-area {
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item {
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
}
.split-row-flag {
  border-bottom: solid 1px #dcdee2;
}
</style>