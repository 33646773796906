<template>
  <div class="alarm-offline-container">
    <div class="alarm-data-query mt10 ml10">
      <Button type="primary" @click="exportData()">{{ showLang('com.op.export') }}</Button>
    </div>
    <div class="offline-data-area mt10" ref="table1">
      <vxe-table :data="list" style="width: 100%;" border stripe :height="tabHeight" default-expand-all :empty-text="showLang('com.data.no')">
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column field="stationId" :title="showLang('com.site.id')" header-align="center" sortable width="160">
          <template #default="{ row }">
            {{ row.stationId }}
          </template>
        </vxe-column>
        <vxe-column field="stationName" :title="showLang('com.site.name')" header-align="center" sortable >
          <template #default="{ row }">
            {{ row.stationName }}
          </template>
        </vxe-column>
        <vxe-column field="offlineTime" :title="showLang('com.date.offline')" header-align="center" sortable >
          <template #default="{ row }">
            {{ row.offlineTime }}
          </template>
        </vxe-column>
        <vxe-column field="offlineTime2" :title="showLang('com.date.offline.diff')" header-align="center" sortable >
          <template #default="{ row }">
            {{getTimeDiff(row.offlineTime) }}
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'AlarmOfflineIndex',
  components: {
  },
  props: {
    args: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      list: [],
      tabHeight: 200,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      curTab: 'hour3',
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels', 'dealAlarmTypes']),
    ...mapGetters('common', ['getDealAlarmMethod']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getList();
    }
  },
  mounted: function () {
    // console.log('get args', this.args);
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    exportData() {
      let stationId = 0, groupId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$store.dispatch('auth/reqFile', {
        title: '导出离线站点列表数据.xlsx',
        url: `//${this.domains.trans}/station/alarm/ExportOffline`,
        args: { stationId, groupId },
      }).then(() => {
        // this.exportLoading = false;
      });
    },
    getTimeDiff: function (time) {
      if (time == '-') return '-';
      var t = new Date(time);
      var diff = parseInt((new Date().getTime() - t.getTime()) / 60000);
      // console.log('get diff mins', new Date().getTime(), t.getTime(), diff);
      var days = parseInt(diff / 1440);
      diff -= days * 1440;
      var hours = parseInt(diff / 60);
      diff -= hours * 60;
      return this.showLang('com.date.offline.format', days, hours, diff);
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    getList: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == 'root' || this.selectedNode.type == 'group') {
        groupId = this.selectedNode.id;
      } else {
        stationId = this.selectedNode.id;
      }
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let params = { groupId, stationId };
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryOffline`, params).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-offline-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.offline-data-query {
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.offline-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>