<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('com.device.box.own')}}：
        <Select :placeholder="showLang('save.select')" v-model="form.stationId" style="width: 150px; margin-right: 10px;">
          <template v-for="(item, idx) in selectedStations">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="datetime" :placeholder="showLang('com.date.start')" style="width: 170px; margin-right: 10px;"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="datetime" :placeholder="showLang('com.date.end')" style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes('spav')" type="info" :loading="loading" @click="getList">{{showLang('com.op.query')}}</Button>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <div v-if="history.length == 0" style="height: 400px; background-color: white;text-align:center;line-height: 200px">{{showLang('com.data.no')}}</div>
      <virtual-list v-else class="table-data" :style="{height: `${tabHeight}px`}" :data-key="'fireTime'" :data-sources="history" :data-component="tempHistoryData" :extra-props="pos" />
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import TempHistoryData from './PowerHistoryItem'
import VirtualList from 'vue-virtual-scroll-list'
export default {
  name: 'SearchPowerboxIndex',
  components: {
    VirtualList,
    'virtual-list': VirtualList
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      tempHistoryData: TempHistoryData,
      pos: {},
      form: {
        stationId: 0,
        start: "",
        end: "",
      },
      showTable: true,
      history: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    selectedNode() {
      this.setDefaultStation();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 1);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.setDefaultStation();
    // this.getList();
  },
  destroyed: function () {
  },
  methods: {
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    setDefaultStation: function () {
      if (this.selectedStations.length == 0){
        this.form.stationId = 0;
        return;
      }
      if (this.form.stationId == 0) {
        this.form.stationId = this.selectedStations[0].id;
        return;
      }
      let ss = this.selectedStations.filter(p => p.id == this.form.stationId)
      if (ss.length == 0) {
        this.form.stationId = this.selectedStations[0].id;
      }
    },
    exportData() {
      var ele = document.querySelector('.table-data').childNodes[0];
      this.getPdf(ele, this.showLang('history.data.ggd.all'))
    },
    getList() {
      if (this.selectedStations.length == 0) {
        this.$Message.warning(this.showLang('com.tree.station'));
        return false;
      }
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { sid: this.form.stationId, start: this.form.start, end: this.form.end };
      let station = this.selectedStations.filter(p => p.id == this.form.stationId)[0];
      this.pos = { title: station.name, lat: station.lat, lng: station.lng };
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/history/PowerBox`, params).then(res => {
        this.loading = false;
        if (res.code == 0) {
          let empty = [], isExplain = 1;
          let ms = res.data.filter(p => p.type == 1);
          if(ms.length == 0){
            this.$set(this, 'history', []);
            return;
          }
          empty = ms[0];
          if(empty.cfg.enc || empty.cfg.enp){
            isExplain = 1;
          }else{
            isExplain = 12;
          }
          let his = [];
          for (let d of empty.list) {
            let item = {
              isExplain: isExplain,
              fireTime: d.ts,
              time: d.ts,
              main: {
                cfg: empty.cfg,
                extra: empty.extra,
                content: d.content
              },
              switchs: [],
              outputs: [],
              contacts: [],
              branchs: [],
              doors: [],
              locks: [],
              smokes: [],
              waters: [],
              leakage: [],
            };
            res.data.filter(p => p.type == 2).map(b => {
              // let bcfg = station.params.filter(p => p.id == b.id)[0];
              let datas = b.list.filter(p => p.ts == d.ts);
              if (datas.length == 0) return;
              item.switchs.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
            });
            res.data.filter(p => p.type == 3).map(b => {
              // let bcfg = station.params.filter(p => p.id == b.id)[0];
              let datas = b.list.filter(p => p.ts == d.ts);
              if (datas.length == 0) return;
              item.outputs.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
            });
            res.data.filter(p => p.type == 4).map(b => {
              // let bcfg = station.params.filter(p => p.id == b.id)[0];
              let datas = b.list.filter(p => p.ts == d.ts);
              if (datas.length == 0) return;
              item.contacts.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
            });
            res.data.filter(p => p.type == 5).map(b => {
              // let bcfg = station.params.filter(p => p.id == b.id)[0];
              let datas = b.list.filter(p => p.ts == d.ts);
              if (datas.length == 0) return;
              item.branchs.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
            });
            res.data.filter(p => p.type == 6).map(b => {
              // let bcfg = station.params.filter(p => p.id == b.id)[0];
              let datas = b.list.filter(p => p.ts == d.ts);
              if (datas.length == 0) return;
              item.doors.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
            });
            res.data.filter(p => p.type == 7).map(b => {
              // let bcfg = station.params.filter(p => p.id == b.id)[0];
              let datas = b.list.filter(p => p.ts == d.ts);
              if (datas.length == 0) return;
              item.locks.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
            });
            res.data.filter(p => p.type == 8).map(b => {
              // let bcfg = station.params.filter(p => p.id == b.id)[0];
              let datas = b.list.filter(p => p.ts == d.ts);
              if (datas.length == 0) return;
              item.smokes.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
            });
            res.data.filter(p => p.type == 9).map(b => {
              // let bcfg = station.params.filter(p => p.id == b.id)[0];
              let datas = b.list.filter(p => p.ts == d.ts);
              if (datas.length == 0) return;
              item.waters.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
            });
            res.data.filter(p => p.type == 11).map(b => {
              // let bcfg = station.params.filter(p => p.id == b.id)[0];
              let datas = b.list.filter(p => p.ts == d.ts);
              if (datas.length == 0) return;
              item.leakage.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
            });
            his.push(item);
          }
          this.$set(this, 'history', his);
        }
      })
    },
  }
}
</script>
<style scoped>
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.table-data {
  overflow: auto;
}
</style>